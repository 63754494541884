import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mainConfig from '../../../../configs/mainConfig';
import labelsUtils from '../../../../appUtils/labelsUtils';
import { checkFindingsFilter, getFindingCategory } from '../../../resolver/utils/findings-filter';
import { isSequentialModeEnabledForVisit, isShapeIsPresent } from '../../../resolver/utils';
import { LABEL_VALIDATION_ERROR__NUMBER_CHANGED } from '../../../labels/constants';

import { trackEvent } from '../../../../integrations/mixpanel';

import { IMAGE_TYPE__PAN } from '../../../../constants/imageConstants';

import editorActions from '../../../../actions/editorActions';
import { shiftAndSwapTeeth } from '../../../resolver/actions';

import imagesSelectors from '../../../../selectors/imagesSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import userSelectors from '../../../../selectors/userSelectors';

import imagesLabelsSelectors from '../../../../modules/labels/selectors/imagesLabelsSelectors';
import labelTagsSelectors from '../../../../modules/label-tags/selectors/labelTagsSelectors';
import labelsSelectors from '../../../../modules/labels/selectors/labelsSelectors';
import labelChildrenSelectors from '../../../../modules/labels/selectors/labelChildrenSelectors';
import currentCollectionSelectors from '../../../../selectors/currentCollectionSelectors';

import labelGetters from '../../../../modules/labels/selectors/labelGetters';
import labelTagGetter from '../../../../modules/label-tags/selectors/labelTagGetter';

import { DentalNotationDiagram } from '../../../../components/DentalNotationDiagram';


export default withRouter(connect((state, { history }) => {
	const editorData = editorSelectors.selectEditor(state);
	const findingsFilter = editorData.treatmentPlanFilters;

	const selectedLabel = editorData.selectedLabel;
	const selectedLabelId = editorData.selectedLabel ? labelGetters.getLabelId(editorData.selectedLabel) : null;
	const teethAvailableToShift = {};
	const currentCollectionId = editorData.currentCollectionHashName;
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const canOutlineTeeth = currentImage.image_type !== IMAGE_TYPE__PAN;
	const user = userSelectors.selectUserData(state);
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;

	const teethNotification = [];
	const teethOutlined = [];
	const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
	let teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId: currentImageId,
	})
		.reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelGetters.getLabelId(label) });
				if ( tags && tags.length > 0 ) {
					let isMissingTooth = false;
					const toothKey = labelTagGetter.getTagKey(tags[0]);

					if ( labelChildren[labelId] ) {
						labelChildren[labelId].forEach((childLabelId) => {
							const childLabel = labelsSelectors.selectLabelById(state, {
								labelId: childLabelId,
							});

							if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
								isMissingTooth = true;
							}
						});
					}

					if ( !isMissingTooth ) {
						teethAvailableToShift[toothKey] = true;
						result[toothKey] = {
							...label,
							children: [],
						};
					}
				}
			}

			return result;
		}, {});

	Object.keys(teeth).forEach((toothKey) => {
		const label = teeth[toothKey];

		const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
		if ( children.length > 0 ) {
			children.forEach((childLabelId) => {
				const childLabel = labelsSelectors.selectLabelById(state, {
					labelId: childLabelId,
				});

				const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);
				const classId = labelGetters.getLabelClassId(childLabel);
				const labelCategory = getFindingCategory({ label: childLabel });

				if ( isSequentialMode === true && (
					classId === 'caries' ||
					classId === 'tooth_bone_loss' ||
					classId === 'periodontitis'
				)) {
					return;
				}

				const shape = childLabel.shapes[currentImage.hashname];

				if (
					(
						selectedLabel && labelGetters.getLabelId(selectedLabel) === childLabelId ||
						checkFindingsFilter({
							findingsFilter,
							label: childLabel,
							showBoneLossStages: editorData.showBoneLossStages,
							allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
						})
					) && (
						typeof measureOfConfidence !== 'number' ||
						( classId !== 'caries' && classId !== 'periodontitis' && classId !== 'impacted tooth' ) ||
						editorData.filteredConfidencePercent <= measureOfConfidence
					) &&
					isShapeIsPresent(shape) &&
					labelCategory !== 'tissue'
				) {
					label.children.push({
						...childLabel,
						parentId: labelGetters.getLabelId(label),
					});
				}
			});
		}
	});

	return {
		selectedToothKey: editorData.selectedToothKey,
		teethAvailableToShift,
		teeth,
		editorMode: editorData.editorMode,
		currentImage: imagesSelectors.selectImageById(state, {
			id: currentImageId,
		}),
		user: userSelectors.selectUserData(state),
		teethNotification,
		teethOutlined,
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
			const labelCategory = getFindingCategory({ label });

			return (
				(
					isSequentialMode === false ||
					labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological')
				&& (
					typeof measureOfConfidence !== 'number' ||
					( classId !== 'caries' && classId !== 'periodontitis' && classId !== 'impacted tooth' ) ||
					editorData.filteredConfidencePercent <= measureOfConfidence
				) &&
				labelCategory !== 'tissue'
			);
		},
		tooltipOptions: {
			enabled: true,
			footerEnabled: false,
		},
		onSelectLabel: (data) => {
			history.push(`/collections/${currentCollectionId}/visit/${editorData.currentExamination}/tfv/${data.toothKey}`);
		},
	};
}, (dispatch) => ({
	onShiftTeeth: (data) => {
		trackEvent('TFV Dental Chart Shift', { keys: data.toothKeysToShift, direction: data.direction });
		dispatch(shiftAndSwapTeeth(data));
	},
	onSelectToothKey: (data) => {
		trackEvent('TFV Dental Chart Select Tooth', { toothKey: data.toothKey });
		dispatch(editorActions.selectToothKey(data));
	},
}))(DentalNotationDiagram));
