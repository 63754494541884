import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRuntimeConfig, setRuntimeConfig } from '../../../../appUtils/runtimeConfig';
import { setAppImageMode } from '../../../../services/app';
import urlCache from '../../../../services/url-cache';

import lodashMerge from 'lodash/merge';

import { APP_IMAGE_MODE } from '../../../../constants/imageConstants';
import { enable as enableTeethConflictsMiddleware } from '../../../../modules/labels/middlewares/teethConflicts';

import imagesActions from '../../../../actions/imagesActions';
import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';

import { ResolverCollectionHoc } from '../../../resolver/components/collection';
import ResolverUploadImageHoc from '../../../resolver/components/upload-image-hoc';
import ResolverStorage from '../../../resolver/components/controller/ResolverStorageService';
import TfvParamsChecker from './TfvParamsChecker';
import TfvImageLoader from './TfvImageLoader';
import TfvLayoutWrapper from './TfvLayoutWrapper';
import TfvMainLayout from '../main-layout';


class TfvEntry extends Component {
	static propTypes = {
		collectionHashName: PropTypes.string,

		onSetFilters: PropTypes.func.isRequired,
		onLoadImage: PropTypes.func.isRequired,
		onSetCurrentImage: PropTypes.func.isRequired,
		onSetCurrentExamination: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);

		const config = getRuntimeConfig();
		config.api = lodashMerge(config.api, config.treatment_plan.api);
		setRuntimeConfig(lodashMerge(config));
		setAppImageMode(APP_IMAGE_MODE.TREATMENT_PLAN);

		enableTeethConflictsMiddleware();
	}

	_handleLoadImage = (image) => {
		return this.props.onLoadImage({
			collectionHashName: this.props.collectionHashName,
			imageHashName: image.hashname,
		})
			.then((_image) => {
				urlCache.cleanAll();

				this.props.onSetCurrentImage(_image.id);
				this.props.onSetCurrentExamination(image.examination);
			});
	};

	render () {
		return (
			<ResolverUploadImageHoc>
				<ResolverCollectionHoc>
					<TfvParamsChecker>
						{({ image, toothKey }) => (
							<TfvImageLoader image={image} onLoadImage={this._handleLoadImage}>
								{({ status }) => {
									switch (status) {
										case TfvImageLoader.LOADING_STATUS.DONE:
											return (
												<>
													<ResolverStorage />
													<TfvLayoutWrapper key={toothKey} toothKey={toothKey}>
														<TfvMainLayout />
													</TfvLayoutWrapper>
												</>
											);

										default:
											return status;
									}
								}}
							</TfvImageLoader>
						)}
					</TfvParamsChecker>
				</ResolverCollectionHoc>
			</ResolverUploadImageHoc>
		);
	}
}

export default connect(
	(state) => {
		return {
			collectionHashName: editorSelectors.selectEditor(state).currentCollectionHashName,
		};
	},
	(dispatch) => ({
		onSetCurrentImage: (id) => {
			if ( id !== null ) {
				return dispatch(editorActions.setCurrentImage({ id }));
			}

			return dispatch(editorActions.unsetCurrentImage());
		},
		onSetCurrentExamination: (currentExamination) => dispatch(editorActions.updateData({ data: { currentExamination } })),
		onLoadImage: ({ collectionHashName, imageHashName }) =>
			dispatch(imagesActions.loadImage({
				collectionHashName,
				imageHashName,
			})),
	})
)(TfvEntry);
