import teethUtils from '../../../../appUtils/teeth/teethUtils';
import labelsUtils from '../../../../appUtils/labelsUtils';
import reportTeethConfig from '../../../../components/Report/reportTeethConfig';
import { isMissingTooth } from '../../utils/';


export function getToothSvg (options = {}) {
	const {
		number,
		toothData,
		labelColorFilterFn,
	} = options;

	let toothStyle;

	if ( !toothData ) {
		toothStyle = reportTeethConfig.getToothStyle({
			type: 'absent',
		});
	}
	else {
		if ( isMissingTooth(number) === true ) {
			toothStyle = reportTeethConfig.getToothStyle({
				type: 'missing',
			});
		}
		else {
			toothStyle = reportTeethConfig.getToothStyle({
				type: 'finding',
				color: labelsUtils.getLabelColor(toothData, labelColorFilterFn),
			});
		}
	}

	return teethUtils.getToothSvg({
		number,
		params: toothStyle,
	});
}
