import mainConfig from '../configs/mainConfig';

import apiActionTypes from '../actions/apiActionTypes';
import editorActionTypes from '../actions/editorActionTypes';
import userActionTypes from '../actions/userActionTypes';


// Sets the main part of the state.
// Resets state the all time when sets or unsets a new image.
const mainState = {
	currentImageIsLoaded: false,
	hasUnsavedChanges: false,

	editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,

	zoom: 1,
	imageOriginalWidth: null,
	imageOriginalHeight: null,

	highlightedLabels: [],
	selectedBox: null,
	selectedToothKey: null,
	shouldShowFindingsViewerForDentalNotation: false,

	selectedLabel: null,
	classIdForNewLabel: null,
	dataForNewLabel: null,
	newShape: null,
	parentForNewLabel: null,

	currentImageHistoryIndex: null,

	imageFlipping: null,
	isCanvasImageMoving: false,

	isSavingChanges: false,

	filterSharpen: false,
	showCanvasGrid: false,
	showBoneLossStages: true,
	showFindingsOnImage: true,

	shouldShowFeedback: true,

	teethToShift: [],
	teethConflicts: [],
	isAutoChartShortFilter: true,
	shouldShowAiNotificationBefore: true,
	shouldShowAiNotificationAfter: false,
};

const initialState = {
	currentImageId: null,
	currentImageIsAnalyzing: false,
	currentImageHasAnalyzedError: false,

	currentCollectionHashName: null,

	canvasWidth: null,
	canvasHeight: null,

	isUserMenuOpened: false,

	imageBrightness: 1,
	imageContrast: 1,
	imageInvert: 0,

	filteredClasses: {},
	filteredConfidencePercent: mainConfig.FINDINGS_MIN_MEASURE_OF_CONFIDENCE_VALUE,

	showAllFindings: true,
	aiPathologiesEnabled: false,
	shouldShowTissueFindings: false,
	
	collectionSearch: '',
	
	originalLabels: null,

	isFmxModeEnabled: false,
	
	treatmentPlanHideBadImageNotification: false,
	treatmentPlanUnsupportedImageTypeNotification: false,
	treatmentPlanIsEnabledExportButton: false,
	treatmentPlanFilters: {},
	treatmentPlanToothImageHashNameMap: {},

	showThumbnailsGallery: true,

	currentExamination: null,
	magnifyingGlassEnabled: false,
	areFindingsMasksEnabled: false,
	areHeatMapsEnabled: false,

	imagePlanFormat: null,

	enhancedImageFilter: false,

	notAnalyzedImages: [],
	...mainState,
};


export default function (state = initialState, action) {
	switch (action.type) {
		case apiActionTypes.ACTION_API__SET_DATA:
			return {
				...state,
				...action.payload.editor,
				labelsStage: action.payload.labelsStage || state.labelsStage,
			};

		case editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_SET_ID:
			return {
				...state,
				...mainState,
				currentImageId: action.payload,
			};

		case editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_UNSET_ID:
			return {
				...state,
				...mainState,
				currentImageId: null,
			};

		case editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_IS_LOADED:
			return {
				...state,
				currentImageIsLoaded: action.payload,
			};

		case editorActionTypes.ACTION_EDITOR__FINDINGS_TOGGLE_SHOW_ALL:
			return {
				...state,
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				selectedToothKey: null,
				selectedLabel: null,
				highlightedLabels: [],
				showAllFindings: !state.showAllFindings,
			};

		case editorActionTypes.ACTION_EDITOR__UPDATE_DATA:
			return {
				...state,
				...action.payload.data,
			};

		case editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE:
			return {
				...state,
				currentImageIsAnalyzing: action.payload,
			};

		case editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE_ERROR:
			return {
				...state,
				currentImageIsAnalyzing: false,
				currentImageHasAnalyzedError: action.payload,
			};

		case editorActionTypes.ACTION_EDITOR__SET_USER_MENU_VISIBILITY:
			return {
				...state,
				isUserMenuOpened: action.payload,
			};

		case userActionTypes.ACTION_USER__SIGN_IN: {
			return {
				...state,
				enhancedImageFilter: action.payload.data.is_image_enhancement_enabled,
			};
		}
		
		default:
			return state;
	}
}
