import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__CEPHALOMETRIC,
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__EXTRAORAL_BITEWING,
	IMAGE_TYPE__EXTRAORAL_BITEWING_CHILD,
	IMAGE_TYPE__UNKNOWN,
} from '../constants/imageConstants';

import {
	GENDER_WOMAN,
	GENDER_MAN,
	GENDER_UNKNOWN
} from '../constants/patientConstants';


const AVAILABLE_UPLOADING_IMAGE_EXTENSIONS = [ 'jpeg', 'jpg', 'tiff', 'tif', 'png', 'bmp', 'dicom', 'dcm', 'pano', 'stv' ];
const AVAILABLE_UPLOADING_IMAGE_TYPES = [
	'image/jpeg',
	'image/png',
	'image/tiff',
	'image/bmp',
	'image/x-ms-bmp',
	'application/dicom',
	'application/vnd.ms-pano',
];

const EDITOR_MODE__SELECT_MODE = 'select-mode';
const EDITOR_MODE__EDIT_MODE = 'edit-mode';
const EDITOR_MODE__DRAW_MODE__PRIMARY_BOX = 'draw-mode__primary-box';
const EDITOR_MODE__DRAW_MODE__SECONDARY_BOX = 'draw-mode__secondary-box';
const EDITOR_MODE__DRAW_MODE__SHAPE = 'draw-mode__shape';
const EDITOR_MODE__SELECT_TOOTH = 'select-tooth';

const AVAILABLE_EDITOR_MODES = [
	EDITOR_MODE__SELECT_MODE,
	EDITOR_MODE__DRAW_MODE__PRIMARY_BOX,
	EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
	EDITOR_MODE__DRAW_MODE__SHAPE,
	EDITOR_MODE__SELECT_TOOTH,
];

const IMAGE_BOX_MIN_WIDTH = 10;
const IMAGE_BOX_MIN_HEIGHT = 10;

// In percents
const FINDINGS_MIN_MEASURE_OF_CONFIDENCE_VALUE = 0;

const URL_BASE_PATH = '/';

const MAX_ZOOM = 9;
const MIN_ZOOM = 0.2;
const ZOOM_STEP = 0.2;

const TOOLBAR_ITEM__SELECT_MODE = 'select-mode';
const TOOLBAR_ITEM__ZOOM = 'zoom';
const TOOLBAR_ITEM__ZOOM_IN = 'zoom-in';
const TOOLBAR_ITEM__ZOOM_OUT = 'zoom-out';
const TOOLBAR_ITEM__ZOOM_DEFAULT = 'zoom-default';
const TOOLBAR_ITEM__DRAW_MODE = 'draw-mode';
const TOOLBAR_ITEM__BRIGHTNESS = 'brightness';
const TOOLBAR_ITEM__CONTRAST = 'contrast';
const TOOLBAR_ITEM__FLIP_HORIZONTAL = 'flip-horizontal';
const TOOLBAR_ITEM__FLIP_VERTICAL = 'flip-vertical';
const TOOLBAR_ITEM__ROTATE_LEFT = 'rotate-left';
const TOOLBAR_ITEM__ROTATE_RIGHT = 'rotate-right';
const TOOLBAR_ITEM__INVERT = 'invert';
const TOOLBAR_ITEM__IMAGE_SHARPEN = 'image-sharpen';
const TOOLBAR_ITEM__GRID = 'grid';
const TOOLBAR_ITEM__BONE_LEVELS = 'bone-levels';
const TOOLBAR_ITEM__HIDE_FINDINGS = 'hide-findings';
const TOOLBAR_ITEM__MAGNIFYING_GLASS = 'magnifying-glass';
const TOOLBAR_ITEM__FINDINGS_MASKS = 'findings-masks';
const TOOLBAR_ITEM__HEAT_MAP = 'heat-map';
const TOOLBAR_ITEM__ENHANCED_IMAGE_FILTER = 'enhanced-image-filter';
const TOOLBAR_ITEM__SHOW_TISSUE_FINDINGS = 'show-tissue-findings';

const TOOLBAR_ORDER = [
	// TOOLBAR_ITEM__SELECT_MODE,
	TOOLBAR_ITEM__DRAW_MODE,
	TOOLBAR_ITEM__ZOOM_IN,
	TOOLBAR_ITEM__ZOOM_OUT,
	TOOLBAR_ITEM__ZOOM_DEFAULT,
	TOOLBAR_ITEM__FLIP_HORIZONTAL,
	TOOLBAR_ITEM__FLIP_VERTICAL,
	TOOLBAR_ITEM__ROTATE_LEFT,
	TOOLBAR_ITEM__ROTATE_RIGHT,
	TOOLBAR_ITEM__BRIGHTNESS,
	TOOLBAR_ITEM__CONTRAST,
	TOOLBAR_ITEM__INVERT,
	// TOOLBAR_ITEM__IMAGE_SHARPEN,
	TOOLBAR_ITEM__ENHANCED_IMAGE_FILTER,
	TOOLBAR_ITEM__GRID,
	TOOLBAR_ITEM__BONE_LEVELS,
	TOOLBAR_ITEM__HIDE_FINDINGS,
	TOOLBAR_ITEM__FINDINGS_MASKS,
	TOOLBAR_ITEM__HEAT_MAP,
	TOOLBAR_ITEM__MAGNIFYING_GLASS,
	TOOLBAR_ITEM__SHOW_TISSUE_FINDINGS,
	// TOOLBAR_ITEM__COLORS,
];

const MAIN_SCROLL_CSS_CLASS_NAME = 'main-customs-scroll';

const DEFAULT_LOCALE = 'en';
// Don't change if you do not have a clear understanding of why.
const FALLBACK_LOCALE = 'en';

const AVAILABLE_LOCALES = [
	{
		key: 'en',
		name: 'English',
	}, {
		key: 'ru',
		name: 'Русский',
	},
	{
		key: 'cn',
		name: '中文',
	}
];

const AVAILABLE_IMAGE_TYPES = [
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__CEPHALOMETRIC,
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__EXTRAORAL_BITEWING,
	IMAGE_TYPE__EXTRAORAL_BITEWING_CHILD,
	IMAGE_TYPE__UNKNOWN,
];

const ALLOWED_IMAGE_TYPES = [
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
];

const GENDERS = [
	GENDER_UNKNOWN,
	GENDER_WOMAN,
	GENDER_MAN,
];

const FIELDS_PATTERNS = {
	patient_id: (value) => {
		if ( !value ) {
			return true;
		}

		return ( /^[a-zA-Z0-9_]+$/.test(value) === true );
	},
};

const BONE_LOSS_LINES_VISIBLE = [ 'stage_1_bone_loss_line', 'stage_2_bone_loss_line', 'stage_3_bone_loss_line', 'stage_4_bone_loss_line' ];
const BONE_LOSS_LINES_INVISIBLE = [ 'stage_0_bone_loss_line' ];
const BONE_LOSS_LINES = [ ...BONE_LOSS_LINES_INVISIBLE, ...BONE_LOSS_LINES_VISIBLE ];


export default {
	AVAILABLE_UPLOADING_IMAGE_EXTENSIONS,
	AVAILABLE_UPLOADING_IMAGE_TYPES,

	IMAGE_BOX_MIN_WIDTH,
	IMAGE_BOX_MIN_HEIGHT,
	
	EDITOR_MODE__SELECT_MODE,
	EDITOR_MODE__EDIT_MODE,
	EDITOR_MODE__DRAW_MODE__PRIMARY_BOX,
	EDITOR_MODE__DRAW_MODE__SECONDARY_BOX,
	EDITOR_MODE__DRAW_MODE__SHAPE,
	EDITOR_MODE__SELECT_TOOTH,
	AVAILABLE_EDITOR_MODES,

	FIELDS_PATTERNS,

	FINDINGS_MIN_MEASURE_OF_CONFIDENCE_VALUE,
	URL_BASE_PATH,

	MAX_ZOOM,
	MIN_ZOOM,
	ZOOM_STEP,

	TOOLBAR_ITEM__SELECT_MODE,
	TOOLBAR_ITEM__ZOOM,
	TOOLBAR_ITEM__ZOOM_IN,
	TOOLBAR_ITEM__ZOOM_OUT,
	TOOLBAR_ITEM__ZOOM_DEFAULT,
	TOOLBAR_ITEM__DRAW_MODE,
	TOOLBAR_ITEM__BRIGHTNESS,
	TOOLBAR_ITEM__CONTRAST,
	TOOLBAR_ITEM__INVERT,
	TOOLBAR_ITEM__FLIP_HORIZONTAL,
	TOOLBAR_ITEM__FLIP_VERTICAL,
	TOOLBAR_ITEM__ROTATE_LEFT,
	TOOLBAR_ITEM__ROTATE_RIGHT,
	TOOLBAR_ITEM__IMAGE_SHARPEN,
	TOOLBAR_ITEM__ENHANCED_IMAGE_FILTER,
	TOOLBAR_ITEM__GRID,
	TOOLBAR_ITEM__BONE_LEVELS,
	TOOLBAR_ITEM__HIDE_FINDINGS,
	TOOLBAR_ITEM__MAGNIFYING_GLASS,
	TOOLBAR_ITEM__FINDINGS_MASKS,
	TOOLBAR_ITEM__HEAT_MAP,
	TOOLBAR_ITEM__SHOW_TISSUE_FINDINGS,

	TOOLBAR_ORDER,

	MAIN_SCROLL_CSS_CLASS_NAME,

	DEFAULT_LOCALE,
	FALLBACK_LOCALE,

	AVAILABLE_LOCALES,
	GENDERS,

	AVAILABLE_IMAGE_TYPES,
	ALLOWED_IMAGE_TYPES,
	BONE_LOSS_LINES_VISIBLE,
	BONE_LOSS_LINES_INVISIBLE,
	BONE_LOSS_LINES,
};
