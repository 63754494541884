export default {
	'analyzing.dialog.title': 'Info',
	'analyzing.progress': 'This app displays some regions of interest for a trained professional to examine possible pathologies',
	'analyzing.success': 'Analyzing of the current image',

	'login_message.dialog.title': 'CAUTION',
	'login_message.info': `Manufacturer: Denti.AI Technology Inc.<br />
		99 Yorkville Ave, Suite 200, Toronto, ON M5R 3K5, Canada<br />
		Tested for Chrome browser.<br />
		Please confirm agreement with the {terms_link_}Terms of use{_terms_link} and {privacy_link_}Privacy Policy{_privacy_link}
	`,

	'hint.select_tooth': 'Please select the tooth from dental chart',
	'hint.draw_secondary_box': 'Please mark the finding here',

	'overlay.cancel_action': 'Cancel action',

	'buttons.open_last_image': 'Open last image',
	'buttons.upload_image': 'Upload image',

	'select_tooth.placeholder': 'use another type',
	'select_tooth.no_tooth_key': 'Without tooth number',

	'image_flipping': 'Image is flipping',

	'unsaved_changes.confirm': 'You have unsaved changes, do you want to save them?',
	'unsaved_changes.saving': 'Changes are saving. Please wait',
	'unsaved_changes.title': 'Unsaved changes',
	'unsaved_changes.buttons.save': 'Save',
	'unsaved_changes.buttons.dont_save': "Don't save",
	'unsaved_changes.buttons.cancel': 'Cancel',

};
