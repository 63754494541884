import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import labelsUtils from '../../appUtils/labelsUtils';
import imageUtils from '../../appUtils/imageUtils';

import imagesActions from '../../actions/imagesActions';
import collectionsActions from '../../actions/collectionsActions';

import collectionsSelectors from '../../selectors/collectionsSelectors';
import editorSelectors from '../../selectors/editorSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';

import Registry from './Registry';


export default withRouter(connect((state, { history }) => {
	const currentCollection = collectionsSelectors.selectCollectionById(state, {
		id: editorSelectors.selectEditor(state).currentCollectionHashName,
	});

	return {
		currentImageId: editorSelectors.selectCurrentImageId(state),
		currentCollectionId: currentCollection.hashname,
		collectionName: currentCollection.name,
		collectionId: currentCollection.hashname,
		owner: currentCollection.owner || currentCollection.username,
		availableLabels: Object.keys(currentCollection.labels.reduce((result, label) => {
			result[label.class_id] = true;

			return result;
		}, {})).map((classId) => ({
			value: classId,
			label: labelsUtils.getLocalizedLabelNameByClassId(classId),
		})),
		onGetImages: (imageHashNames) => imageHashNames.map(imageHashName => imagesSelectors.selectImageByHashNameInCollection(state, {
			collectionHashName: currentCollection.hashname,
			imageHashName,
		})),
		onGoToImage: (imageHashName) => {
			imageUtils.checkHasUnsavedChanged(() => {
				history.push(`/collections/${currentCollection.hashname}/image/${imageHashName}`,
					{ imageId: imageHashName });
			});
		},
	}
}, (dispatch) => ({
	onRemoveImages: (options) => dispatch(imagesActions.removeImages(options))
		.then(() => dispatch(collectionsActions.getCollection())),
	onSaveImageData: (options) => dispatch(imagesActions.saveImageData(options)),
}))(Registry));
