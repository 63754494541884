import mainConfig from '../configs/mainConfig';

import storeUtils from '../appUtils/storeUtils';
import imageUtils from '../appUtils/imageUtils';
import { setFindingsMasks as setFindingsMasksCookie } from '../appUtils/findingsMasks';

import helpers from '../appUtils/helpers';

import editorActionTypes from './editorActionTypes';

import imagesActions from './imagesActions';
import apiActions from './apiActions';
import imagesHistoryActions from './imagesHistoryActions';
import analyseActions from './analyseActions';
import labelsActions from '../modules/labels/actions/labelsActions';

import editorSelectors from '../selectors/editorSelectors';

import imagesSelectors from '../selectors/imagesSelectors';
import collectionsSelectors from '../selectors/collectionsSelectors';
import imagesHistorySelectors from '../selectors/imagesHistorySelectors';
import analyseSelectors from '../selectors/analyseSelectors';
import labelsSelectors from '../modules/labels/selectors/labelsSelectors';
import labelGetters from '../modules/labels/selectors/labelGetters';
import labelsUtils from '../appUtils/labelsUtils';
import labelTagGetter from '../modules/label-tags/selectors/labelTagGetter';
import labelTagsSelectors from '../modules/label-tags/selectors/labelTagsSelectors';
import labelChildrenSelectors from '../modules/labels/selectors/labelChildrenSelectors';
import userSelectors from '../selectors/userSelectors';


const HISTORY_STATE = {
	WRITE: 'write',
	STASH: 'stash',
};

let stashedState = null;
let historyState = HISTORY_STATE.WRITE;


function setCurrentImage (options) {
	return (dispatch, getState) => {
		if ( typeof options.id !== 'string' ) {
			throw new Error(`setCurrentImage: Option "id" is required.`);
		}

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_SET_ID, options.id));

		const storeState = getState();

		if ( userSelectors.selectIsAuthenticated(storeState) ) {
			// @todo update opening date
			return dispatch(imagesActions.touchImage({
				id: options.id,
			}));
		}

		return Promise.resolve();
	};
}

function setCurrentImageByHash ({ collectionHashName, imageHashName }) {

}

function unsetCurrentImage () {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_UNSET_ID));
	};
}


function markCurrentImageAsLoaded (options) {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_IS_LOADED, options.value));
	};
}

/**
 * @param {boolean} value
 * @return {Function}
 */
function setShowAllFindings (value) {
	return (dispatch, getState) => {
		const storeState = getState();
		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});
		imageUtils.setShowAllFindingsToStorage({
			...imageUtils.getShowAllFindingsFromStorage(),
			[collection.hashname]: value,
		});
		
		dispatch(updateData({
			data: {
				showAllFindings: value,
			},
		}));
	};
}

function toggleShowAllFindings () {
	return (dispatch, getState) => {
		dispatch(setShowAllFindings(!editorSelectors.selectShowAllFindings(getState())));
	};
}

function updateData (options) {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: options.data,
		}));
	};
}

function highlightLabels (options) {
	return (dispatch, getState) => {
		const editorData = editorSelectors.selectEditor(getState());

		if ( editorData.editorMode === mainConfig.EDITOR_MODE__EDIT_MODE ) {
			return;
		}

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				highlightedLabels: options.data,
			},
		}));
	};
}

function analyzeCurrentImage (params) {
	return (dispatch, getState) => {
		const imageId = editorSelectors.selectEditor(getState()).currentImageId;

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE, true));

		return dispatch(imagesActions.analyzeImage({
			id: imageId,
			params,
		}))
			.then(() => {
				dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE, false));
				dispatch(imagesActions.unmarkImageAsNotAnalyzed({ imageId }));
			})
			.catch((error) => {
				dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE_ERROR, true));
				throw error;
			});
	};
}

function analyzeAndReload(params) {
	return (dispatch, getState) => {
		const imageId = editorSelectors.selectEditor(getState()).currentImageId;

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__CURRENT_IMAGE_ANALYZE, true));

		return dispatch(imagesActions.analyzeImage({
			id: imageId,
			params,
		})).then(() => {
			window.location.reload()
		})
	};
}

function openUserMenu () {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__SET_USER_MENU_VISIBILITY, true));
	};
}

function closeUserMenu () {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__SET_USER_MENU_VISIBILITY, false));
	};
}

function selectLabel (options) {
	return (dispatch, getState) => {
		const storeState = getState();

		let label = options.label;
		let labelId = options.labelId;

		if ( !label && labelId ) {
			label = labelsSelectors.selectLabelById(storeState, {
				labelId: options.labelId,
			});
		}

		let highlightedLabels = [];
		let selectedToothKey = null;
		if ( label ) {
			const labelId = labelGetters.getLabelId(label);
			// highlightedLabels.push(labelId);

			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId: labelGetters.getLabelId(label) });
				if ( tags && tags.length > 0 ) {
					selectedToothKey = labelTagGetter.getTagKey(tags[0]);
				}

				const labelChildren = labelChildrenSelectors.selectLabelChildren(storeState);
				if ( labelChildren[labelId] ) {
					labelChildren[labelId].forEach((childLabelId) => {
						highlightedLabels.push(childLabelId);
					});
				}
			}

			const parentLabelId = label && labelsUtils.findParentLabelId(storeState, labelGetters.getLabelId(label));
			if ( parentLabelId ) {
				highlightedLabels.push(parentLabelId);
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(storeState, { labelId: parentLabelId });
				if ( tags && tags.length > 0 ) {
					selectedToothKey = labelTagGetter.getTagKey(tags[0]);
				}
			}
		}

		const data = label
			? {
				selectedLabel: label,
				selectedToothKey,
				editorMode: options.editorMode || mainConfig.EDITOR_MODE__EDIT_MODE,
				highlightedLabels,
				shouldShowFindingsViewerForDentalNotation: false,
			}
			: {
				selectedLabel: null,
				selectedToothKey: null,
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				highlightedLabels,
				shouldShowFindingsViewerForDentalNotation: false,
			};

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data,
		}));
	};
}

function selectToothKey (options) {
	return (dispatch, getState) => {
		const newData = {
			selectedToothKey: options.toothKey,
		};

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: newData,
		}));
	};
}

function zoomIn () {
	return (dispatch, getState) => {
		const currentZoom = editorSelectors.selectEditor(getState()).zoom;
		const newZoom = Math.min(mainConfig.MAX_ZOOM, currentZoom + mainConfig.ZOOM_STEP);

		if ( newZoom !== currentZoom ) {
			dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
				data: {
					zoom: newZoom,
				},
			}));
		}
	};
}

function zoomOut () {
	return (dispatch, getState) => {
		const editorData = editorSelectors.selectEditor(getState());
		const currentZoom = editorData.zoom;
		const newZoom = Math.max(mainConfig.MIN_ZOOM, currentZoom - mainConfig.ZOOM_STEP);

		if ( currentZoom !== newZoom ) {
			dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
				data: {
					zoom: newZoom,
				},
			}));
		}
	};
}

function zoomDefault () {
	return (dispatch, getState) => {
		const editorData = editorSelectors.selectEditor(getState());

		const newZoom = imageUtils.getDefaultZoom({
			canvasWidth: editorData.canvasWidth,
			canvasHeight: editorData.canvasHeight,
			imageWidth: editorData.imageOriginalWidth,
			imageHeight: editorData.imageOriginalHeight,
		});

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				zoom: newZoom,
			},
		}));
	};
}

/**
 * @param {number} zoom
 * @return {(function(*): void)|*}
 */
function setZoom (zoom) {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				zoom,
			},
		}));
	};
}

function changeEditorMode (options) {
	return (dispatch, getState) => {
		if ( mainConfig.AVAILABLE_EDITOR_MODES.indexOf(options.mode) !== -1 ) {
			const storeState = getState();
			const editorData = editorSelectors.selectEditor(storeState);
			const currentImageId = editorData.currentImageId;

			if (
				(
					options.mode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ||
					options.mode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX
				) &&
				analyseSelectors.selectIsImageAnalyzed(storeState, { imageId: currentImageId })
			) {
				dispatch(labelsActions.removeAllLabels({ imageId: currentImageId }));
				dispatch(analyseActions.unsetImageAnalyzed({ imageId: currentImageId }));
			}

			dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
				data: {
					editorMode: options.mode,
				},
			}));
		}
	};
}

function setPrevImageHistoryState () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(storeState, {
			id: currentImageId,
		});
		const currentImageHistoryIndex = editorData.currentImageHistoryIndex;
		const imageHistory = ( imagesHistorySelectors.selectImageHistoryByImageId(storeState, {
			imageId: currentImageId,
		}) || [] );
		let newImageHistoryIndex;

		if ( currentImageHistoryIndex === null ) {
			newImageHistoryIndex = ( imageHistory.length - 1 );

			const newImageData = helpers.toApiStructure({
				storeState,
				imageId: currentImageId,
			});
			dispatch(imagesHistoryActions.setHistory({
				imageId: currentImageId,
				data: [
					...imageHistory,
					{
						labels: newImageData.labels,
						editor: {
							notAnalyzedImages: [
								...editorData.notAnalyzedImages,
							],
						},
					},
				],
			}));
		}
		else {
			newImageHistoryIndex = ( currentImageHistoryIndex - 1 );
		}

		if ( newImageHistoryIndex < 0 ) {
			return;
		}

		dispatch(apiActions.setData(helpers.clearLabels()));
		const historyState = imageHistory[newImageHistoryIndex];
		const newImageState = helpers.toStateStructure({
			storeState: getState(),
			image: currentImage,
			annotation: historyState,
		});

		newImageState.editor = {
			...editorSelectors.selectEditor(storeState),
			...historyState.editor,
		};

		dispatch(apiActions.setData(newImageState));

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				currentImageHistoryIndex: newImageHistoryIndex,
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				highlightedLabels: [],
				selectedLabel: null,
				selectedToothKey: null,
				hasUnsavedChanges: ( typeof newImageHistoryIndex === 'number' ),
			},
		}));
	};
}

function setNextImageHistoryState () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(storeState, {
			id: currentImageId,
		});
		const currentImageHistoryIndex = editorData.currentImageHistoryIndex;
		const imageHistory = imagesHistorySelectors.selectImageHistoryByImageId(storeState, {
			imageId: currentImageId,
		});
		let newImageHistoryIndex;

		if ( currentImageHistoryIndex >= 0 ) {
			newImageHistoryIndex = ( currentImageHistoryIndex + 1 );
		}
		else {
			return;
		}

		dispatch(apiActions.setData(helpers.clearLabels()));
		const historyState = imageHistory[newImageHistoryIndex];
		const newImageState = helpers.toStateStructure({
			storeState: getState(),
			image: currentImage,
			annotation: historyState,
		});
		newImageState.editor = {
			...editorSelectors.selectEditor(storeState),
			...historyState.editor,
		};

		dispatch(apiActions.setData(newImageState));

		if ( newImageHistoryIndex + 1 >= imageHistory.length ) {
			newImageHistoryIndex = null;

			dispatch(imagesHistoryActions.setHistory({
				imageId: currentImageId,
				data: imageHistory.slice(0, (imageHistory.length - 1)),
			}));
		}

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				currentImageHistoryIndex: newImageHistoryIndex,
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				highlightedLabels: [],
				selectedLabel: null,
				selectedToothKey: null,
				hasUnsavedChanges: ( typeof newImageHistoryIndex === 'number' ),
			},
		}));
	};
}

/**
 * @param {function():Promise} userAction
 * @param {Object} options
 * @param {boolean} options.preventSave
 * @return {function}
 */
function putInImageHistory (userAction, options = {}) {
	return async (dispatch, getState) => {
		try {
			return await (async() => {
				const storeState = getState();
				const editorData = editorSelectors.selectEditor(storeState);
				const currentImageId = editorData.currentImageId;
				const currentImageHistoryIndex = editorData.currentImageHistoryIndex;
				const imageHistory = ( imagesHistorySelectors.selectImageHistoryByImageId(storeState, {
					imageId: currentImageId,
				}) || [] );

				if ( currentImageHistoryIndex === null ) {
					const currentImageState = stashedState !== null
						? stashedState
						: helpers.toApiStructure({
							storeState,
							imageId: currentImageId,
						});

					try {
						await userAction();

						if ( historyState !== HISTORY_STATE.WRITE ) {
							return;
						}

						dispatch(imagesHistoryActions.setHistory({
							imageId: currentImageId,
							data: [
								...imageHistory,
								{
									labels: currentImageState.labels,
									editor: {
										notAnalyzedImages: [
											...editorData.notAnalyzedImages,
										],
									},
								},
							],
							preventSave: options.preventSave === true,
						}));

						stashedState = null;
					}
					catch (error) {
						throw new Error(error);
					}
				}
				else {
					try {
						await userAction();

						dispatch(imagesHistoryActions.setHistory({
							imageId: currentImageId,
							data: imageHistory.slice(0, currentImageHistoryIndex + 1),
							preventSave: options.preventSave === true,
						}));

						dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
							data: {
								currentImageHistoryIndex: null,
							},
						}));
					}
					catch (error) {
						throw new Error(error);
					}
				}
				dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
					data: {
						hasUnsavedChanges: true,
					},
				}));
			})();
		}
		catch (error) {
			throw error;
		}
	};
}

/**
 * @param {HISTORY_STATE} state
 * @return {(function(*, *): void)|*}
 */
function setHistoryState (state) {
	return (dispatch, getState) => {
		switch (state) {
			case HISTORY_STATE.STASH: {
				const storeState = getState();
				const editorData = editorSelectors.selectEditor(storeState);
				const currentImageId = editorData.currentImageId;
				historyState = HISTORY_STATE.STASH;
				stashedState = helpers.toApiStructure({
					storeState: storeState,
					imageId: currentImageId,
				});
				debugger;
				break;
			}

			case HISTORY_STATE.WRITE:
				historyState = HISTORY_STATE.WRITE;
				break;

			default:
				break;
		}

	};
}

function setNewBox (labelShape) {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const editorMode = editorData.editorMode;
		const currentImage = imagesSelectors.selectImageById(storeState, {
			id: editorSelectors.selectCurrentImageId(storeState),
		});

		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				newShape: labelShape,
			},
		}));
		
		if ( editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__PRIMARY_BOX ) {
			dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
				data: {
					editorMode: mainConfig.EDITOR_MODE__SELECT_TOOTH,
				},
			}));
		}
		else if ( editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX ) {
			dispatch(addNewLabel());
		}
		else if ( editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SHAPE ) {
			dispatch(labelsActions.updateLabelShape({
				labelId: labelGetters.getLabelId(editorData.selectedLabel),
				data: labelShape,
				imageHashName: currentImage.hashname,
			}));
			dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
				data: {
					editorMode: mainConfig.EDITOR_MODE__EDIT_MODE,
				},
			}));
		}
	};
}

function addNewLabel () {
	return (dispatch, getState) => {
		dispatch(putInImageHistory(async () => {
			const storeState = getState();
			const editorData = editorSelectors.selectEditor(storeState);
			const currentImageId = editorData.currentImageId;
			const newShape = editorData.newShape;

			let parentLabel = null;

			if ( editorData.parentForNewLabel ) {
				parentLabel = editorData.parentForNewLabel;
			}

			// const toothLabel = labelsSelectors.selectLabelByToothKey(storeState, {
			// 	toothKey: editorData.selectedToothKey,
			// });

			const labelId = helpers.generateLabelId();

			dispatch(labelsActions.addLabel({
				imageId: currentImageId,
				classId: editorData.classIdForNewLabel,
				labelId,
				shape: newShape,
				meta: {
					confidence_percent: 1,
					confirmed: true,
				},
				parentLabel,
				...editorData.dataForNewLabel,
			}));

			const nextData = {
				data: {
					parentForNewLabel: null,
					classIdForNewLabel: null,
					dataForNewLabel: null,
					newShape: null,
				},
			};

			dispatch(updateData(nextData));
			dispatch(selectLabel({ labelId }));
		}));
	};
}


function addNewBox (options = {}) {
	return (dispatch, getState) => {
		dispatch(putInImageHistory(async () => {
			const storeState = getState();
			const editorData = editorSelectors.selectEditor(storeState);
			const currentImageId = editorData.currentImageId;
			const newShape = editorData.newShape;

			const labelId = helpers.generateLabelId();

			let data = helpers.addLabel({
				storeState,
				imageId: currentImageId,
				classId: 'tooth',
				labelId,
				shape: newShape,
				meta: {
					confidence_percent: 1,
					confirmed: true,
				},
			});

			const tagKey = editorData.selectedToothKey !== '-1' ? editorData.selectedToothKey : null;
			let tag = null;
			if ( tagKey ) {
				tag = labelsUtils.getLabelTagByKey(tagKey, 'tooth');
			}
			
			data = {
				...data,
				...helpers.addLabelTag({
					storeState,
					labelId,
					tag: tag ? {
						key: tagKey,
						localizedName: tag.readable_name,
						hotKey: tag.hotkey,
					}: null,
				}),
			};

			dispatch(apiActions.setData(data));

			const label = labelsSelectors.selectLabelById(getState(), { labelId });

			dispatch(updateData({
				data: {
					selectedLabel: label,
					selectedToothKey: editorData.selectedToothKey === '-1' ? null : editorData.selectedToothKey,
					classIdForNewLabel: null,
					dataForNewLabel: null,
					newShape: null,
					editorMode: mainConfig.EDITOR_MODE__EDIT_MODE,
				},
			}));
		}));
	};
}

// @todo move to reducer and use initial state?
function resetMode () {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(editorActionTypes.ACTION_EDITOR__UPDATE_DATA, {
			data: {
				selectedToothKey: null,
				selectedLabel: null,
				classIdForNewLabel: null,
				dataForNewLabel: null,
				newShape: null,
				parentForNewLabel: null,
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				shouldShowFindingsViewerForDentalNotation: false,
			},
		}));
	};
}

function addLabel (options = {}) {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const classId = options.classId;
		const currentImageId = editorData.currentImageId;

		let parentForNewLabel = null;

		if ( analyseSelectors.selectIsImageAnalyzed(storeState, { imageId: currentImageId }) ) {
			dispatch(labelsActions.removeAllLabels({ imageId: currentImageId }));
			dispatch(analyseActions.unsetImageAnalyzed({ imageId: currentImageId }));
		}

		if (
			!options.noParent &&
			options.editorMode === mainConfig.EDITOR_MODE__DRAW_MODE__SECONDARY_BOX
		) {
			const toothLabel = labelsSelectors.selectLabelByToothKey(storeState, {
				toothKey: editorData.selectedToothKey,
			});

			parentForNewLabel = (options.parentLabel || toothLabel || null);
		}

		if ( options.requiredShape === true ) {
			dispatch(updateData({
				data: {
					parentForNewLabel,
					classIdForNewLabel: classId,
					dataForNewLabel: options.labelData,
					editorMode: options.editorMode,
				},
			}));
		}
		else {
			const labelId = options.id || helpers.generateLabelId();

			dispatch(labelsActions.addLabel({
				imageId: currentImageId,
				classId,
				labelId,
				shape: { type: 'none' },
				meta: {
					confidence_percent: 1,
					confirmed: true,
				},
				parentLabel: parentForNewLabel,
				...options.labelData,
			}));
			dispatch(selectLabel({ labelId }));
		}
	};
}

function setImageBrightness (options = {}) {
	return (dispatch) => {
		dispatch(updateData({
			data: {
				imageBrightness: options.brightness,
			},
		}));
	};
}

function setImageContrast (options = {}) {
	return (dispatch) => {
		dispatch(updateData({
			data: {
				imageContrast: options.contrast,
			},
		}));
	};
}

function setImageInvert (options = {}) {
	return (dispatch) => {
		dispatch(updateData({
			data: {
				imageInvert: options.invert,
			},
		}));
	};
}

function isImageFlipping(options = {}) {
	return dispatch => {
		dispatch(resetMode());
		return dispatch(
			updateData({
				data: {
					imageFlipping: options.id || null
				},
			})
		);
	};
}

function setCanvasImageMoving (options = {}) {
	return (dispatch) => {
		return dispatch(updateData({
			data: {
				isCanvasImageMoving: options.value,
			},
		}));
	};
}

function setFilteredClasses (options = {}) {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		const collectionId = editorData.currentCollectionHashName;
		const nextFilteredClasses = {
			...editorData.filteredClasses,
			[options.imageType]: options.classes,
		};

		dispatch(updateData({
			data: {
				filteredClasses: nextFilteredClasses,
			},
		}));

		const classes = imageUtils.getFilteredClassesFromStorage() || {};
		imageUtils.setFilteredClassesToStorage({
			...classes,
			[collectionId]: {
				...classes[collectionId],
				...nextFilteredClasses,
			},
		});
	};
}

function setFilteredConfidencePercent (value) {
	return (dispatch, getState) => {
		dispatch(updateData({
			data: {
				editorMode: mainConfig.EDITOR_MODE__SELECT_MODE,
				filteredConfidencePercent: value,
                selectedLabel: null,
                selectedToothKey: null,
				highlightedLabels: [],
			},
		}));
	};
}

function toggleFilterSharpen () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);

		return dispatch(updateData({
			data: {
				filterSharpen: !editorData.filterSharpen,
				selectedLabel: null,
				selectedToothKey: null,
			},
		}));
	};
}

function toggleShowCanvasGrid () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);
		
		return dispatch(updateData({
			data: {
				showCanvasGrid: !editorData.showCanvasGrid,
			},
		}));
	};
}

function toggleShowBoneLossStages () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);

		return dispatch(updateData({
			data: {
				showBoneLossStages: !editorData.showBoneLossStages,
			},
		}));
	};
}

function toggleShowFindings () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);

		return dispatch(updateData({
			data: {
				showFindingsOnImage: !editorData.showFindingsOnImage,
			},
		}));
	};
}

function toggleMagnifyingGlass () {
	return (dispatch, getState) => {
		const storeState = getState();
		const editorData = editorSelectors.selectEditor(storeState);

		return dispatch(updateData({
			data: {
				magnifyingGlassEnabled: !editorData.magnifyingGlassEnabled,
			},
		}));
	};
}

function setCollectionSearch (value) {
	return (dispatch, getState) => {
		return dispatch(updateData({
			data: {
				collectionSearch: value,
			},
		}));
	};
}

/**
 * @param {boolean} value
 *
 * @returns {(function(*, *): void)|*}
 */
const setFindingsMasks = (value) => {
	return (dispatch) => {
		dispatch(updateData({
			data: {
				areFindingsMasksEnabled: value,
			},
		}));
		setFindingsMasksCookie(value);
	};
};


export default {
	HISTORY_STATE,
	setCurrentImage,
	unsetCurrentImage,
	markCurrentImageAsLoaded,
	setShowAllFindings,
	toggleShowAllFindings,
	updateData,
	highlightLabels,
	analyzeCurrentImage,
	analyzeAndReload,
	openUserMenu,
	closeUserMenu,
	selectLabel,
	selectToothKey,
	zoomIn,
	zoomOut,
	zoomDefault,
	setZoom,
	changeEditorMode,
	setPrevImageHistoryState,
	setNextImageHistoryState,
	putInImageHistory,
	setHistoryState,
	setNewBox,
	addNewBox,
	resetMode,
	addLabel,
	setImageBrightness,
	setImageContrast,
	setImageInvert,
	isImageFlipping,
	setCanvasImageMoving,
	setFilteredClasses,
	setFilteredConfidencePercent,
	toggleFilterSharpen,
	toggleShowCanvasGrid,
	toggleShowBoneLossStages,
	setCollectionSearch,
	toggleShowFindings,
	toggleMagnifyingGlass,
	setFindingsMasks,
};
