import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import imageUtils from '../../../../appUtils/imageUtils';
import aclService from '../../../../services/acl';
import { copyTextToClipboard } from '../../../../appUtils/clipboard';
import { trackEvent } from '../../../../integrations/mixpanel';

import { USER_PERMISSION__UPLOAD_IMAGE, USER_PERMISSION__IS_FMX_VIEW_ENABLED, USER_PERMISSION__LIST_COLLECTIONS } from '../../../../constants/userPermissionsConstants';

import imagesSelectors from '../../../../selectors/imagesSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';

import UserInfo from '../../../../components/UserInfo';
import Messenger from '../../../../components/Messenger';
import { DrawSecondaryBoxMode } from '../../../../components/DisableLayout';
import UploadButton from '../../../../components/ImageControls/UploadButtonConnector';
import Logo from '../../../../components/Logo';

import { getDictionary } from '../../../../appUtils/locale';

import './styles/ResolverHeader.css';
import '../../../../components/ImageControls/styles/ImageControls.css';


const i18nImageControls = getDictionary('image-controls');

const baseCssClassName = 'resolver-header';
const userInfoCssClassName = `${baseCssClassName}__user-info`;
const helpCssClassName = `${baseCssClassName}__help`;
const versionCssClassName = `${baseCssClassName}__version`;
const buttonCssClassName = `image-controls__button`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const growCssClassName = `${baseCssClassName}__grow`;


class Header extends PureComponent {
	static propTypes = {
		isUploadImageAvailable: PropTypes.bool,
		isFmxModeEnabled: PropTypes.bool,
		isFmxModeAvailable: PropTypes.bool,
		isTfvModeActive: PropTypes.bool,
		isTfvModeEnabled: PropTypes.bool,
		isTfvModeAvailable: PropTypes.bool,
		isRegistryAvailable: PropTypes.bool,
		registryLinkPath: PropTypes.string,
		onFmxButtonClick: PropTypes.func,
		onTfvButtonClick: PropTypes.func,
	};

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	static defaultProps = {
		isUploadImageAvailable: true,
		isFmxModeAvailable: false,
		isTfvModeEnabled: false,
		isTfvModeActive: false,
		isTfvModeAvailable: false,
		isRegistryAvailable: false,
	};

	_handleCollectionsButtonClick = () => {
		imageUtils.checkHasUnsavedChanged(() => {
			trackEvent('Collections button click');
			this.context.router.history.push('/collection-list');
		}, true);
	};

	_handleCopyButtonClick = () => {
		copyTextToClipboard(window.location.href);
	};

	_handleFmxButtonClick = () => {
		if ( typeof this.props.onFmxButtonClick === 'function' ) {
			this.props.onFmxButtonClick();
		}
	};

	_handleTfvButtonClick = () => {
		if ( typeof this.props.onTfvButtonClick === 'function' ) {
			this.props.onTfvButtonClick();
		}
	};

	render () {
		return (
			<div className={baseCssClassName}>
				<div className='header__smth'>
					{aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
						<Link to={'/'} >
							<Logo />
						</Link>
					)}
					{!aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
						<Logo />
					)}
				</div>
				<div className={buttonsCssClassName}>
					{aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
						<button
							className={`${buttonCssClassName} ${buttonCssClassName}__m-collections`}
							title={i18nImageControls('buttons.collections')}
							onClick={this._handleCollectionsButtonClick}
						/>
					)}
					{aclService.checkPermission(USER_PERMISSION__UPLOAD_IMAGE) && this.props.isUploadImageAvailable === true && <UploadButton />}
					{this.props.isRegistryAvailable === true && (
						<Link to={{ pathname: this.props.registryLinkPath, state: { modal: true } }}>
							<button
								className={`${buttonCssClassName} ${buttonCssClassName}__m-open`}
								title={i18nImageControls('buttons.open_registry')}
							/>
						</Link>
					)}
					<button
						className={`${buttonCssClassName} ${buttonCssClassName}__m-copy`}
						title={'Copy link'}
						onClick={this._handleCopyButtonClick}
					/>
					{(aclService.checkPermission(USER_PERMISSION__IS_FMX_VIEW_ENABLED) && this.props.isFmxModeAvailable === true) &&
						(
						<button
							className={classnames([
								buttonCssClassName,
								`${buttonCssClassName}__m-text`,
								this.props.isFmxModeEnabled === true && `${buttonCssClassName}__m-active`,
							])}
							title={'FMX'}
							onClick={this._handleFmxButtonClick}
						>FMX</button>
					)}
					{this.props.isTfvModeAvailable === true &&
						(
						<button
							className={classnames([
								buttonCssClassName,
								`${buttonCssClassName}__m-text`,
								this.props.isTfvModeActive === true && `${buttonCssClassName}__m-active`,
							])}
							title={'TFV'}
							disabled={this.props.isTfvModeEnabled === false}
							onClick={this._handleTfvButtonClick}
						>TFV</button>
					)}
				</div>
				<div className={growCssClassName} />
				<div className={helpCssClassName}>
					<Messenger />
				</div>
				<div className={userInfoCssClassName}>
					<UserInfo />
				</div>
				<DrawSecondaryBoxMode />
			</div>
		);
	}
}

export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);
		const currentCollection = collectionsSelectors.selectCollectionById(state, {
			id: editorData.currentCollectionHashName,
		});
		const currentImage = imagesSelectors.selectImageById(state, {
			id: editorData.currentImageId,
		});

		return {
			registryLinkPath: currentImage
				? `/collections/${currentCollection.hashname}/image/${currentImage.hashname}/treatment_plan/registry`
				: `/collections/${currentCollection.hashname}/treatment_plan/registry`
		};
	},
	() => ({})
)(Header);
