import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { trackEvent } from '../../../../integrations/mixpanel';

import { getDictionary } from '../../../../appUtils/locale';

import { USER_PERMISSION__IS_SHOW_CONFIDENCE_SLIDER } from '../../../../constants/userPermissionsConstants';

import aclService from '../../../../services/acl';

import labelsActions from '../../../labels/actions/labelsActions';
import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import { changeConfidencePercent } from '../../actions';

import { confirm } from '../../../../services/popup';

import Slider from '../../../../components/Slider/RcSlider';

import './styles/ResolveFindingsFilter.css';


const i18n = getDictionary('findings');

const baseCssClassName = 'resolve-findings-filter';
const removeControlCssClassName = `${baseCssClassName}__remove-control`;
const filterCssClassName = `${baseCssClassName}__filter`;
const filterLabelCssClassName = `${baseCssClassName}__filter-label`;
const filterValueCssClassName = `${baseCssClassName}__filter-value`;
const filterControlCssClassName = `${baseCssClassName}__filter-control`;


class ResolveFindingsFilter extends Component {
	static propTypes = {
		currentImage: PropTypes.object.isRequired,
		filteredConfidencePercent: PropTypes.number.isRequired,
		shouldShowConfidenceFilter: PropTypes.bool,
		onRemoveAllLabels: PropTypes.func.isRequired,
	};

	static defaultProps = {
		shouldShowConfidenceFilter: false,
	};

	_handleRemoveAllButtonClick = () => {
		confirm({
			title: 'Remove all findings',
			message: 'Do you want to remove all findings?',
			yesHandler: () => {
				trackEvent('Remove All Findings');
				this.props.onRemoveAllLabels();
			},
		});
	};

	_handleConfidenceFilterChange = (value) => {
		this.props.onFilteredConfidencePercentChange(value / 100);
	};

	render () {
		const isOwnImage = this.props.currentImage.isOwn;

		return (
			<div className={baseCssClassName}>
				{this.props.shouldShowConfidenceFilter && (
					<div className={`${filterCssClassName} ${filterCssClassName}__m-confidence`}>
						<div className={filterLabelCssClassName}>{i18n('confidence')}</div>
						<div className={filterValueCssClassName}>{Math.trunc(this.props.filteredConfidencePercent * 100)}%</div>
						<div className={filterControlCssClassName}>
							<Slider
								value={Math.trunc(this.props.filteredConfidencePercent * 100)}
								min={0}
								max={100}
								wrapperStyle={{
									width: 150,
									height: 17,
								}}
								railStyle={{
									background: '#989898',
									borderRadius: 1,
									height: 2,
								}}
								handleStyle={{
									background: '#989898',
									border: '2px solid #45464E',
								}}
								trackStyle={{
									background: '#989898',
									height: 2,
								}}
								onChange={this._handleConfidenceFilterChange}
							/>
						</div>
					</div>
				)}
				<div style={{ flex: '1 1 auto'}} />
				{ isOwnImage && (
					<div
						title={i18n('remove_all.alt')}
						className={removeControlCssClassName}
						onClick={this._handleRemoveAllButtonClick}
					>{i18n('remove_all.text')}</div>
				) }
			</div>
		);
	}
}

export default connect((state) => ({
	filteredConfidencePercent: editorSelectors.selectEditor(state).filteredConfidencePercent,
	currentImage: imagesSelectors.selectImageById(state, {
		id: editorSelectors.selectCurrentImageId(state),
	}),
	shouldShowConfidenceFilter: Boolean(aclService.checkPermission(USER_PERMISSION__IS_SHOW_CONFIDENCE_SLIDER)),
}), (dispatch) => ({
	onRemoveAllLabels: () => dispatch(labelsActions.removeAllLabels({ withTeeth: false }))
		.then(() => {
			dispatch((dispatch, getState) => {
				const storeState = getState();
				const editorData = editorSelectors.selectEditor(storeState);
				const collection = collectionsSelectors.selectCollectionById(storeState, {
					id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
				});
				const nextNotAnalyzedImages = [ ...editorData.notAnalyzedImages ];

				collection.images.forEach((image) => {
					if ( image.examination !== editorData.currentExamination ) {
						return;
					}

					if ( nextNotAnalyzedImages.includes(image.id) === false ) {
						nextNotAnalyzedImages.push(image.id);
					}
				});

				dispatch(editorActions.updateData({
					data: {
						notAnalyzedImages: nextNotAnalyzedImages,
					},
				}));
			});
		}),
	onFilteredConfidencePercentChange: (data) => dispatch(changeConfidencePercent(data)),
}))(ResolveFindingsFilter);

