import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
} from '../../constants/imageConstants';

import mainConfig from '../../configs/mainConfig';
import momentUtils from '../../appUtils/momentUtils';
import { message } from '../../services/popup';
import { getDictionary } from '../../appUtils/locale';
import dateUtils from '../../appUtils/dateUtils';

import commonApi from '../../api/common';

import imagesSelectors from '../../selectors/imagesSelectors';
import userSelectors from '../../selectors/userSelectors';

import PopupDialog, { PopupDialogHeader } from '../PopupDialog';
import Button from '../Button';
import Alignment from '../Alignment';
import Loading from '../Loading';
import ImageUploadingContent from './ImageUploadingContent';

import './styles/ImageUploadingForm.css';


const i18n = getDictionary('image-uploading-form');
const i18nShared = getDictionary('shared');

const FIELDS_CHECKS = {
	...mainConfig.FIELDS_PATTERNS,
	image_type: (value) => value.trim().length > 0,
	// sensor: (value) => value.trim().length > 0,
	// custom_sensor: (value, { sensor }) => {
	// 	if ( sensor.value !== 'custom' ) {
	// 		return true;
	// 	}
	//
	// 	return value.trim().length > 0;
	// },
};

/**
 * @param {boolean} isCadUser
 * @return {Object<string, number>}
 */
function getAvailableSizes (isCadUser) {
	if ( isCadUser === true ) {
		return {
			[IMAGE_TYPE__PAN]: 2000,
			[IMAGE_TYPE__PERIAPICAL]: 600,
			[IMAGE_TYPE__BITEWING]: 600,
		};
	}

	return {
		[IMAGE_TYPE__PAN]: 1000,
		[IMAGE_TYPE__PERIAPICAL]: 400,
		[IMAGE_TYPE__BITEWING]: 400,
	};
}


class ImageUploadingForm extends Component {
	static propTypes = {
		image: PropTypes.object,
		isCadUser: PropTypes.bool.isRequired,
		isAutoChartUser: PropTypes.bool.isRequired,
		onSaveImageData: PropTypes.func.isRequired,
		onRemoveImage: PropTypes.func.isRequired,
		onFlipImageHorizontal: PropTypes.func.isRequired,
		onFlipImageVertical: PropTypes.func.isRequired,
		onRotateImageLeft: PropTypes.func.isRequired,
		onRotateImageRight: PropTypes.func.isRequired,
		onSaved: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		onGetSensors: PropTypes.func.isRequired,
		onIsCorrectImageSize: PropTypes.func.isRequired,
		onGetImageSizeError: PropTypes.func.isRequired,
	};

	/**
	 * @type {HTMLImageElement|null}
	 * @private
	 */
	_imageEl = null;

	constructor (props, context) {
		super(props, context);

		this.state = {
			isInProgress: false,
			hasErrorSavingImageData: false,
			isValid: true,

			fields: {
				image_type: {
					value: (props.isCadUser === true || this.props.isAutoChartUser === true) ? '' : (props.image.image_type || ''),
					isInvalid: false
				},
				image_date: {
					value: '',
					isInvalid: false
				},
				patient_id: {
					value: '',
					isInvalid: false
				},
				birthday: {
					value: '',
					isInvalid: false
				},
				gender: {
					value: '',
					isInvalid: false
				},
				sensor: {
					value: '',
					isInvalid: false,
				},
				custom_sensor: {
					value: '',
					isInvalid: false,
				},
			},

			sensors: [],
		};
	}

	componentDidMount () {
		this._validateField('image_type');
		this.props.onGetSensors()
			.then((sensors) => {
				this.setState({
					sensors: [{ id: '', name: 'Unspecified' }, { id: 'custom', name: 'Other' }].concat(sensors),
				});
			})
			.catch(() => {
				message({
					title: i18nShared('error.title'),
					titleIcon: 'error',
					message: 'An error occurred while loading sensors.',
				});
			});
	}

	componentWillUnmount () {
		this.state = null;
	}

	_setDateChange (name, date) {
		this.setState({
			fields: {
				...this.state.fields,
				[name]: {
					...this.state.fields[name],
					value: momentUtils
						.getMomentForDate({
							date
						})
						.format('YYYY-MM-DD'),
					isInvalid: false
				}
			}
		});
	}

	async _saveImageData () {
		const {
			image_type,
			image_date,
			patient_id,
			birthday,
			gender,
			sensor,
			custom_sensor
		} = this.state.fields;

		this.setState({
			isInProgress: true,
			hasErrorSavingImageData: false,
		});

		try {
			await this.props.onSaveImageData({
				image_type: image_type.value || IMAGE_TYPE__PAN,
				patient_id: patient_id.value || null,
				image_date: image_date.value || null,
				birthday: birthday.value || null,
				image_sensor: sensor.value === 'custom'
					? null
					: sensor.value === '' ? null : parseInt(sensor.value, 10),
				custom_sensor_name: custom_sensor.value || null,
				gender: gender.value || 'unknown',
			});

			if ( this.state === null ) {
				return;
			}

			this.setState({
				isInProgress: false,
			});
		}
		catch (error) {
			if ( this.state === null ) {
				return;
			}

			this.setState({
				isInProgress: false,
				hasErrorSavingImageData: true,
			});
		}
	}

	async _removeImage () {
		this.setState({
			isInProgress: true,
			hasErrorSavingImageData: false,
		});

		try {
			await this.props.onRemoveImage({
				image: this.props.image,
			});

			if ( !this.state ) {
				return;
			}

			this.setState({
				isInProgress: false,
			});
		}
		catch (error) {
			if ( !this.state ) {
				return;
			}

			this.setState({
				isInProgress: false,
			});

			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: i18nShared('messages.error.with_retry'),
			});
		}
	}

	/**
	 * @return {number} -1|0|1
	 */
	getCorrectImageSizeStatus () {
		const selectedImageType = this.state.fields.image_type.value;
		if (  selectedImageType.length === 0 || this._imageEl === null ) {
			return 0;
		}
		
		return this.props.onIsCorrectImageSize(this._imageEl.naturalWidth, this._imageEl.naturalHeight, selectedImageType);
	}

	_validateField = (name) => {
		const check = FIELDS_CHECKS[name];
		const value = this.state.fields[name].value;

		let isValid;
		if ( typeof check === 'function' ) {
			isValid = check(value);
		}
		else {
			isValid = check.test(value);
		}

		if ( this.state.isValid !== isValid ) {
			this.setState({ isValid });
		}
		return isValid;
	};

	_handleValueChange = event => {
		const name = event.currentTarget.name;

		this.setState({
			fields: {
				...this.state.fields,
				[name]: {
					...this.state.fields[name],
					value: event.currentTarget.value,
					isInvalid: false
				}
			}
		});
	};

	_handleImageDateChange = date => {
		this._setDateChange('image_date', date);
	};

	_handleBirthdayChange = date => {
		this._setDateChange('birthday', date);
	};

	_handleSaveButtonClick = () => {
		const { fields } = this.state;

		const newState = {};
		let hasInvalidFields = false;

		newState.fields = Object.keys(fields).reduce((result, fieldName) => {
			const field = fields[fieldName];
			let isInvalid = false;

			const check = FIELDS_CHECKS[fieldName];

			if ( check && check(field.value, fields) === false ) {
				isInvalid = true;
			}

			if ( isInvalid ) {
				hasInvalidFields = true;
				result[fieldName] = {
					...field,
					isInvalid: true
				};

				return result;
			}

			result[fieldName] = field;
			return result;
		}, {});

		if ( hasInvalidFields ) {
			this.setState(newState);

			return;
		}

		this._saveImageData();
	};

	_handleTryAgainButtonClick = () => {
		this._saveImageData();
	};

	_handleRemoveImageButtonClick = () => {
		this._removeImage();
	};

	_handleFlipHorizontalClick = () => {
		this.props.onFlipImageHorizontal({
			imageId: this.props.image.id,
			skipAnnotationLoading: false,
		});
	};

	_handleFlipVerticalClick = () => {
		this.props.onFlipImageVertical({
			imageId: this.props.image.id,
			skipAnnotationLoading: false,
		});
	};

	_handleRotateLeftClick = () => {
		this.props.onRotateImageLeft({
			imageId: this.props.image.id,
			skipAnnotationLoading: false,
		});
	};

	_handleRotateRightClick = () => {
		this.props.onRotateImageRight({
			imageId: this.props.image.id,
			skipAnnotationLoading: false,
		});
	};

	/**
	 * @param {Event} event
	 * @private
	 */
	_handleLoad = (event) => {
		this._imageEl = event.target;
		this.forceUpdate();
	};

	_renderError () {
		return <div>{i18nShared('messages.error.with_retry')}</div>;
	}

	_renderProgress () {
		return (
			<Alignment
				horizontal={Alignment.horizontal.CENTER}
				vertical={Alignment.vertical.CENTER}
			>
				<Loading />
			</Alignment>
		);
	}

	_renderContent () {
		if ( this.state.hasErrorSavingImageData ) {
			return this._renderError();
		}

		if ( this.state.isInProgress ) {
			return this._renderProgress();
		}

		const { image_date, birthday, gender, image_type, patient_id, sensor, custom_sensor } = this.state.fields;
		const { classes, image } = this.props;
		const age = dateUtils.getAge(birthday.value);
		const imageSizeStatus = this.getCorrectImageSizeStatus();

		// @todo add support of image loading error
		return (
			<>
				<ImageUploadingContent
					classes={classes}
					image_id={image.id}
					image_type={image_type.value}
					image_date={image_date.value ? moment(image_date.value).toDate() : null}
					patient_id={patient_id}
					birthday={birthday.value ? moment(birthday.value).toDate() : null}
					gender={gender.value}
					image_url={image.image_url}
					predicted_type={image.predicted_type}
					sensor={sensor.value}
					custom_sensor={custom_sensor.value}
					sensors={this.state.sensors}
					handleFlipVerticalClick={this._handleFlipVerticalClick}
					handleFlipHorizontalClick={this._handleFlipHorizontalClick}
					handleRotateLeftClick={this._handleRotateLeftClick}
					handleRotateRightClick={this._handleRotateRightClick}
					handleValueChange={this._handleValueChange}
					handleImageDateChange={this._handleImageDateChange}
					handleBirthdayChange={this._handleBirthdayChange}
					handleLoad={this._handleLoad}
					validateField={this._validateField}
					isValid={this.state.isValid}
				/>
				<div
					style={{
						color: birthday.value.length > 0 && age < 21 ? '#ef5350' : '#ffef2a',
						textAlign: 'right',
						padding: 5,
						fontSize: 14,
						fontWeight: 500,
					}}
				>Denti.AI supports analysis of extraoral (panoramic) and intraoral (bitewings and periapicals) images<br />with correct image orientation and for patients older than 21 with no primary dentition.<br />We recommend specifying image sensor for more accurate measurements.</div>
				{imageSizeStatus === -1 && (
					<div
						style={{
							color: '#ef5350',
							textAlign: 'right',
							padding: 5,
							fontSize: 14,
							fontWeight: 500,
						}}
					>{this.props.onGetImageSizeError(image_type.value)}</div>
				)}
			</>
		);
	}

	_renderButtons () {
		const {
			isValid,
			isInProgress,
			hasErrorSavingImageData,
			fields,
		} = this.state;

		const age = dateUtils.getAge(fields.birthday.value);
		const isAllowedImageType = mainConfig.ALLOWED_IMAGE_TYPES.includes(fields.image_type.value);
		const imageSizeStatus = this.getCorrectImageSizeStatus();

		return hasErrorSavingImageData
		?  (
			<React.Fragment>
				<Button
					key={'popup_try_again_button'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					title={i18nShared('buttons.try_again')}
					disabled={isInProgress}
					onClick={this._handleTryAgainButtonClick}
				>
					{i18nShared('buttons.try_again')}
				</Button>
				<Button
					key={'popup_close_button'}
					theme={Button.AVAILABLE_THEMES.SECONDARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					title={i18nShared('buttons.close')}
					onClick={this._handleRemoveImageButtonClick}
				>
					{i18nShared('buttons.close')}
				</Button>
			</React.Fragment>)
		: (
			<React.Fragment>
				<Button
					key={'popup_save_button'}
					theme={Button.AVAILABLE_THEMES.PRIMARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					onClick={this._handleSaveButtonClick}
					title={i18nShared('buttons.save')}
					disabled={
						isInProgress ||
						isValid === false ||
						(
							fields.birthday.value.length > 0 &&
							age < 21
						) ||
						fields.image_type.value.length === 0 ||
						isAllowedImageType === false ||
						imageSizeStatus !== 1
					}
				>
					{i18nShared('buttons.save')}
				</Button>
				<Button
					key={'popup_close_button'}
					theme={Button.AVAILABLE_THEMES.SECONDARY}
					size={Button.AVAILABLE_SIZES.LARGE}
					title={i18nShared('buttons.close')}
					onClick={this._handleRemoveImageButtonClick}
				>
					{i18nShared('buttons.close')}
				</Button>
		</React.Fragment>)
	}

	render () {
		const { hasErrorSavingImageData } = this.state;

		return this.props.image
			?	<PopupDialog
					headerProps={{
						title: i18n('dialog.title'),
						icon: !hasErrorSavingImageData
							? PopupDialogHeader.icons.SUCCESS
							: PopupDialogHeader.icons.ERROR
					}}
					content={dialogInterface => {
						return dialogInterface ? this._renderContent(dialogInterface) : null;
					}}
					footerProps={{
						buttons: () => {
							return this._renderButtons();
						}
					}}
					popupProps={{
						overlay: {
							enabled: true,
							closeOnClick: false
						},
						onClose: () => this.props.onClose,
					}}
				/>
			: null
		;
	}
}

export default connect(
	(state, props) => {
		const isCadUser = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
		return {
			isCadUser,
			isAutoChartUser: userSelectors.selectIsAutoChartUser(state) === true,
			onIsCorrectImageSize: (width, height, imageType) => {
				const needSize = (isCadUser === true ? Math.max : Math.min)(width, height);
				const size = getAvailableSizes(isCadUser)[imageType];

				return typeof size === 'number'
					? needSize >= size ? 1 : -1
					: 1;
			},
			onGetImageSizeError: (imageType) => {
				if ( isCadUser === true ) {
					return `The biggest side of the image should be bigger than ${getAvailableSizes(isCadUser)[imageType]} pixels`;
				}

				return `The smallest side of the image should be bigger than ${getAvailableSizes(isCadUser)[imageType]} pixels`;
			},
		};
	},
	() => ({
		onGetSensors: () => commonApi.getSensors(),
	})
)(ImageUploadingForm);
