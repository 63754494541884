import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import urlCache from '../../../../services/url-cache';

import mainConfig from '../../../../configs/mainConfig';
import labelsUtils from '../../../../appUtils/labelsUtils';
import imageUtils from '../../../../appUtils/imageUtils';
import { checkFindingsFilter, getFindingCategory } from '../../utils/findings-filter';
import { isSequentialModeEnabledForVisit } from '../../utils';

import { trackEvent } from '../../../../integrations/mixpanel';

import editorActions from '../../../../actions/editorActions';
import labelsActions from '../../../labels/actions/labelsActions';
import { removeLabel } from '../../actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';

import { DRAGGING_STATUS } from '../../../../components/Canvas/CanvasConstants';
import ImageShapeBox from '../../../../components/ImageShapes/shapes/box';
import ImageShapePolygon from '../../../../components/ImageShapes/shapes/polygon';
import ImageShapeBoneloss from '../../../../components/ImageShapes/shapes/boneloss';
import ShapeTooltip from '../shape-tooltip';
import ShapeViewer from '../image-shapes/ResolverShapeViewer';


class ImageEditorShapes extends PureComponent {
	static propTypes = {
		canvasApi: PropTypes.object.isRequired,
		canvasObjectsApi: PropTypes.object.isRequired,
		labels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		visibleLabelsIds: PropTypes.object.isRequired,
		highlightedLabelsIds: PropTypes.object.isRequired,
		currentImage: PropTypes.object.isRequired,
		closestObjectToPoint: PropTypes.object,
		selectedObject: PropTypes.object,
		selectedLabel: PropTypes.object,
		editorMode: PropTypes.string.isRequired,
		isSelectedObjectInTransformation: PropTypes.bool,
		labelColorFilterFn: PropTypes.func.isRequired,
		imageWidth: PropTypes.number.isRequired,
		imageHeight: PropTypes.number.isRequired,
		areFindingsMasksEnabled: PropTypes.bool.isRequired,
		areHeatMapsEnabled: PropTypes.bool.isRequired,
		canEditShapes: PropTypes.bool,
		onSelectLabel: PropTypes.func.isRequired,
		onHighlightLabels: PropTypes.func.isRequired,
		onRemoveLabel: PropTypes.func.isRequired,
		onUpdateShape: PropTypes.func.isRequired,
	};

	static defaultProps = {
		closestObjectToPoint: null,
		selectedObject: null,
		selectedLabel: null,
		canEditShapes: true,
	};

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (
			nextProps.selectedLabel !== null &&
			this.props.selectedLabel === null &&
			lodashGet(nextProps, 'selectedObject.id') === null
		) {
			this.props.canvasObjectsApi.setSelectedObject(null);
		}

		if ( lodashGet(nextProps, 'selectedObject.id') !== lodashGet(this.props, 'selectedObject.id') ) {
			const labelId = nextProps.selectedObject !== null ? nextProps.selectedObject.id : null;
			if ( labelId !== null ) {
				trackEvent('TP Selected Label', { labelId });
			}
			else {
				trackEvent('TP Deselected Label');
			}
			this.props.onSelectLabel({
				labelId,
			});
		}

		if ( nextProps.closestObjectToPoint !== this.props.closestObjectToPoint ) {
			this.props.onHighlightLabels(nextProps.closestObjectToPoint !== null ? [ nextProps.closestObjectToPoint.id ] : []);
		}

		// Sync a selected label with the canvas objects.
		if ( nextProps.selectedLabel !== this.props.selectedLabel ) {
			if ( nextProps.selectedLabel === null ) {
				if ( this.props.selectedObject !== null ) {
					this.props.canvasObjectsApi.setSelectedObject(null);
				}
			}
			else {
				const labelId = labelGetters.getLabelId(nextProps.selectedLabel);

				if (
					nextProps.selectedObject === null ||
					nextProps.selectedObject.id !== labelId ||
					(
						nextProps.selectedLabel !== null &&
						this.props.selectedLabel === null
					)
				) {
					// @todo HACK
					this.props.canvasObjectsApi.setSelectedObject({
						__id: labelId,
					});
				}
			}
		}
	}

	/**
	 * @param {Object} options
	 * @param {Object} options.shapeApi
	 * @param {Object} options.canvasObjectsApi
	 * @return {JSX.Element|null}
	 * @private
	 */
	_renderShape ({ shapeApi, canvasObjectsApi }) {
		const props = {
			id: shapeApi.id,
			shape: shapeApi.shape,
			color: shapeApi.color,
			borderStyle: shapeApi.borderStyle,
			showControls: shapeApi.isSelectedLabel === true,
			isHighlighted: shapeApi.isHighlighted === true,
			allowEditing: (
				shapeApi.isSelectedLabel === true &&
				shapeApi.hasRightsToEdit === true
			),
			showConfirmation: (
				shapeApi.hasRightsToEdit === true && (
					shapeApi.isHoveredLabel === true ||
					shapeApi.isSelectedLabel === true
				)
			),
			allowDeleting: (
				shapeApi.allowDeleting === true &&
				shapeApi.hasRightsToEdit === true && (
					shapeApi.isHoveredLabel === true ||
					shapeApi.isSelectedLabel === true
				)
			),
			zoom: canvasObjectsApi.zoom,
			imageWidth: this.props.imageWidth,
			imageHeight: this.props.imageHeight,
			isVisible: shapeApi.isVisible === true,
			canvasObjectsApi,
			showMask: this.props.areFindingsMasksEnabled === true,
			showHeatMap: this.props.areHeatMapsEnabled === true,
			onSetEditing: (value) => {
				this.props.canvasApi.canDragCanvas(!value);
				this.props.canvasObjectsApi.setSelectedObjectTransformation(value);
			},
			onLabelChange: (shape) => {
				this.props.onUpdateShape({
					labelId: shapeApi.id,
					data: {
						...shapeApi.shape,
						...shape,
					},
					imageHashName: this.props.currentImage.hashname,
				});
			},
			onLabelRemove: () => this.props.onRemoveLabel({ labelId: shapeApi.id }),
			onGetHeatmapUrl: (shape) => {
				if ( urlCache.hasCache(shapeApi.id) ) {
					return urlCache.getCache(shapeApi.id);
				}

				const url = shapeApi.onGetHeatmapUrl(shape, shapeApi.onGetColor);
				urlCache.setCache(shapeApi.id, url);

				return url;
			},
		};

		let Component = null;

		switch (shapeApi.shape.type) {
			case 'box':
				Component = ImageShapeBox;
				break;

			case 'poly':
				Component = ImageShapePolygon;
				break;

			case 'named_poly':
				Component = ImageShapeBoneloss;
				break;

			default:
				return null;
		}

		if ( shapeApi.shouldShowFindingViewer === true ) {
			return (
				<ShapeViewer
					key={`shape_${shapeApi.id}`}
					label={shapeApi.label}
					showOnlyComponent={(
						this.props.canvasApi.draggingStatus !== DRAGGING_STATUS.IDLE ||
						this.props.isSelectedObjectInTransformation === true
					)}
					component={Component}
					componentProps={props}
				/>
			);
		}

		if ( shapeApi.shouldShowTooltip === true ) {
			return (
				<ShapeTooltip
					key={`shape_${shapeApi.id}`}
					label={shapeApi.label}
					viewport={this.props.canvasApi.viewport}
					component={Component}
					componentProps={props}
				/>
			);
		}

		return (<Component {...props} key={`shape_${shapeApi.id}`} />);
	}

	render () {
		const groups = [ { children: [] }, { children: [] } ];
		this.props.labels.forEach((label) => {
			const labelId = labelGetters.getLabelId(label);
			const hasSelectedLabel = this.props.selectedLabel !== null;
			const isSelectedLabel = hasSelectedLabel === true && labelGetters.getLabelId(this.props.selectedLabel) === labelId;
			const isHighlighted = this.props.highlightedLabelsIds[labelId] === true;
			const isHoveredLabel = this.props.closestObjectToPoint !== null && this.props.closestObjectToPoint.id === labelId;
			const isVisible = (
				this.props.visibleLabelsIds[labelId] === true ||
				isSelectedLabel === true ||
				isHighlighted === true
			);

			const shouldShowTooltip = (
				this.props.canvasApi.draggingStatus === DRAGGING_STATUS.IDLE &&
				this.props.isSelectedObjectInTransformation === false &&
				isHoveredLabel === true &&
				( this.props.selectedLabel !== null
						? labelId !== labelGetters.getLabelId(this.props.selectedLabel)
						: true
				)
			);

			const shouldShowFindingViewer = (
				this.props.editorMode === mainConfig.EDITOR_MODE__EDIT_MODE &&
				( this.props.selectedObject !== null
						? labelId === this.props.selectedObject.id
						: false
				)
			);

			const api = {
				label,
				hasSelectedLabel,
				isSelectedLabel,
				isVisible,
				isHighlighted,
				isHoveredLabel,
				id: labelGetters.getLabelId(label),
				shape: label.shapes[this.props.currentImage.hashname] || {},
				color: labelsUtils.getLabelColor(label, this.props.labelColorFilterFn),
				borderStyle: labelsUtils.getShapeBorderStyle(label),
				isConfirmed: labelGetters.getLabelIsConfirmed(label),
				shouldShowTooltip,
				shouldShowFindingViewer,
				hasRightsToEdit: this.props.canEditShapes,
				allowDeleting: labelGetters.getLabelClassId(label) !== 'tooth',
				onGetColor: ({ matrixValue }) => {
					return labelsUtils.getHeatMapColor(labelGetters.getLabelClassId(label), matrixValue, labelGetters.getLabelMeasureOfConfidence(label));
				},
				onGetHeatmapUrl: imageUtils.getHeatmapUrl,
			};

			const renderedShape = this._renderShape({ shapeApi: api, canvasObjectsApi: this.props.canvasObjectsApi });

			if ( isSelectedLabel === true ) {
				groups[2] = {
					children: [ renderedShape ],
					style: {
						pointerEvents: 'bounding-box',
					},
				};
			}
			else if ( isHighlighted === true ) {
				groups[1].children.push(renderedShape);
			}
			else {
				groups[0].children.push(renderedShape);
			}
		});

		return groups.map(({ children, style }, index) => (
			<g key={index} style={style}>{children}</g>
		));
	}
}


export default connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	const highlightedLabelsIds = Object.values( editorData.highlightedLabels || [])
		.reduce((result, labelId) => {
			result[labelId] = true;
			return result;
		}, {});
	const findingsFilter = editorData.treatmentPlanFilters;

	const visibleLabelsIds = {};
	const selectedLabel = editorData.selectedLabel;
	const currentImageId = editorSelectors.selectCurrentImageId(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const user = userSelectors.selectUserData(state);

	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;

	let labels = [];
	if ( editorData.showFindingsOnImage === true ) {
		const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		const labelParentMap = {};
		Object.keys(labelChildren)
			.forEach((labelId) => {
				labelChildren[labelId].forEach((childLabelId) => {
					labelParentMap[childLabelId] = labelId;
				});
			});

		const preLabels = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		}).reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});

			const isSelected = selectedLabel && labelGetters.getLabelId(selectedLabel) === labelId;

			if ( labelsUtils.labelIsTooth(label) ) {
				if ( highlightedLabelsIds[labelId] || isSelected ) {
					result[labelId] = label;
				}
				return result;
			}
			const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
			const classId = labelGetters.getLabelClassId(label);
			const parentLabelId = labelParentMap[labelId];
			const parentLabel = labelsSelectors.selectLabelById(state, { labelId: parentLabelId });
			const labelCategory = getFindingCategory({ label });

			if ( mainConfig.BONE_LOSS_LINES_INVISIBLE.includes(classId) === true ) {
				if (
					highlightedLabelsIds[labelId] === true ||
					isSelected ||
					(selectedLabel && labelGetters.getLabelId(selectedLabel) === labelGetters.getLabelId(parentLabel)) ||
					highlightedLabelsIds[parentLabelId] === true
				) {
					result[labelId] = label;
				}
			} else if ( mainConfig.BONE_LOSS_LINES_VISIBLE.includes(classId) === true ) {
				if (
					highlightedLabelsIds[labelId] === true ||
					isSelected ||
					(selectedLabel && labelGetters.getLabelId(selectedLabel) === labelGetters.getLabelId(parentLabel)) ||
					highlightedLabelsIds[parentLabelId] === true ||
					checkFindingsFilter({
						findingsFilter,
						label,
						showBoneLossStages: editorData.showBoneLossStages,
						allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
					})
				) {
					result[labelId] = label;
				}
			} else if (
				(
					highlightedLabelsIds[labelId] === true ||
					isSelected ||
					checkFindingsFilter({
						findingsFilter,
						label,
					})
				) && (
					typeof measureOfConfidence !== 'number' ||
					( classId !== 'caries' && classId !== 'periodontitis' && classId !== 'impacted tooth' ) ||
					editorData.filteredConfidencePercent <= measureOfConfidence
				) &&
				(
					user.is_simple_ui_enabled === true ? labelCategory === 'pathological' : true
				) &&
				labelCategory !== 'tissue' ||
				editorData.shouldShowTissueFindings
			) {
				result[labelId] = label;
			}

			return result;
		}, {});

		labels = Object.keys(preLabels).reduce((result, labelId) => {
			const label = preLabels[labelId];
			const classId = labelGetters.getLabelClassId(label);
			const isSelected = selectedLabel ? labelGetters.getLabelId(selectedLabel) === labelId : false;

			if (
				( editorData.showFindingsOnImage === false ) ||
				( isSequentialMode === true && labelsUtils.getLabelAvailabilityOptions(classId).findings_group === 'pathological')
			) {
				return result;
			}

			result.push(label);

			if (
				// @todo HACK
				(
					( labelsUtils.labelIsTooth(label) || labelGetters.getLabelClassId(label) === 'no_bone_loss_line' ) &&
					!(
						highlightedLabelsIds[labelId] === true ||
						isSelected === true
					)
				) ||
				(
					editorData.editorMode === mainConfig.EDITOR_MODE__EDIT_MODE &&
					isSelected === false &&
					highlightedLabelsIds[labelId] !== true
				)
			) {
				return result;
			}

			visibleLabelsIds[labelId] = true;

			return result;
		}, []);
	}

	return {
		labels,
		highlightedLabelsIds,
		visibleLabelsIds,
		selectedLabel,
		editorMode: editorData.editorMode,
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
		currentImage,
		areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
		areHeatMapsEnabled: editorData.areHeatMapsEnabled,
	};
}, (dispatch) => ({
	onHighlightLabels: (data) => dispatch(editorActions.highlightLabels({
		data,
	})),
	onSelectLabel: (data) => {
		trackEvent('TP Select Finding', data);
		return dispatch(editorActions.selectLabel(data))
	},
	onUpdateShape: (data) => {
		trackEvent('TP Update Shape', data);
		return dispatch(labelsActions.updateLabelShape(data))
	},
	onRemoveLabel: (data) => {
		trackEvent('TP Removed Finding', data);
		return dispatch(removeLabel(data));
	},
}))(ImageEditorShapes);
