import React from 'react';
import PropTypes from 'prop-types';

import mainConfig from '../../../../configs/mainConfig';
import SidebarButtons from './ResolverControllerButtonsConnector';
import { DrawSecondaryBoxMode } from "../../../../components/DisableLayout";
import CurrentImageInfo from '../../../../components/CurrentImageInfo';
import DentalNotationDiagram from '../dental-notation-diagram';
import { ResolverSidebarFindings } from '../findings';
import ResolverFindingsFilter from '../findings-filter';

import './styles/ResolverSidebar.css';


const baseCssClassName = 'resolver-sidebar';
const imageInfoCssClassName = `${baseCssClassName}__image-info`;
const dentalNotationCssClassName = `${baseCssClassName}__dental-notation`;
const findingsCssClassName = `${baseCssClassName}-findings`;
const findingsFiltersCssClassName = `${findingsCssClassName}-filters`;
const findingsListCssClassName = `${findingsCssClassName}__list`;
const findingsItemsCssClassName = `${findingsCssClassName}-items`;
const findingsFooterCssClassName = `${findingsCssClassName}__footer`;

const propTypes = {
	labels: PropTypes.array.isRequired,
	teeth: PropTypes.array.isRequired,
	teethLabelMap: PropTypes.object.isRequired,
	findingsFilter: PropTypes.object.isRequired,
	shouldShowImageInfo: PropTypes.bool,
	DentalNotationDiagram: PropTypes.func,
	SidebarButtons: PropTypes.func,
	Findings: PropTypes.func,
	isFmxEnabled: PropTypes.bool,
	onFindingsFilterChange: PropTypes.func.isRequired,
	onDentalNotationGoToTfv: PropTypes.func,
};

const defaultProps = {
	shouldShowImageInfo: true,
	DentalNotationDiagram,
	SidebarButtons,
	Findings: ResolverSidebarFindings,
	isFmxEnabled: false,
};

const contextTypes = {
	router: PropTypes.object.isRequired,
};

const ResolverSidebar = (props) => {
	const {
		labels,
		teeth,
		teethLabelMap,
		findingsFilter,

		shouldShowImageInfo,

		DentalNotationDiagram,
		SidebarButtons,
		Findings,
		isFmxEnabled,
		onFindingsFilterChange,
		onDentalNotationGoToTfv,
	} = props;

	return (
		<div className={`${baseCssClassName} ${mainConfig.MAIN_SCROLL_CSS_CLASS_NAME}`}>
			{shouldShowImageInfo === true && (
				<div className={imageInfoCssClassName}>
					<CurrentImageInfo
						panelFields={isFmxEnabled ? [ 'patient_id', 'gender', 'age' ]  : [ 'patient_id', 'image_type', 'image_date' ]}
						dropdownFields={ isFmxEnabled ? [ 'birthday' ] : [ 'image_hash', 'gender', 'birthday', 'age' ]}
						firstColPadding={false}
						shouldCheckImage
					/>
				</div>
			)}
			<div className={dentalNotationCssClassName}>
				<DentalNotationDiagram
					onToothDoubleClick={onDentalNotationGoToTfv}
				/>
			</div>
			<div className={findingsCssClassName}>
				<div className={findingsFiltersCssClassName}>
					<ResolverFindingsFilter
						findingsFilter={findingsFilter}
						onFindingsFilterChange={onFindingsFilterChange}
					/>
				</div>
				<div className={findingsListCssClassName}>
					<div className={findingsItemsCssClassName}>
						<Findings
							labels={labels}
							teeth={teeth}
							teethLabelMap={teethLabelMap}
							findingsFilter={findingsFilter}
						/>
					</div>
				</div>
				<div className={findingsFooterCssClassName}>
					<SidebarButtons
						findingsFilter={findingsFilter}
					/>
				</div>
			</div>
			<DrawSecondaryBoxMode />
		</div>
	);
};

ResolverSidebar.propTypes = propTypes;
ResolverSidebar.defaultProps = defaultProps;
ResolverSidebar.contextTypes = contextTypes;

export default ResolverSidebar;
