import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isSmallScreen } from '../../../../appUtils/mobileUtils';

import { confirm } from '../../../../services/popup';

import { trackEvent } from '../../../../integrations/mixpanel';

import RoundedButton from '../../../../components/RoundedButton';
import Button from '../../../../components/Button';

import ResolverAutoChart from '../auto-chart';
import ResolveAddLabel from '../add-label';
import ResolverUndo from './ResolverUndo';
import ResolverRedo from './ResolverRedo';

import './styles/ResolverControllerButtons.css';


const baseCssClassName = 'resolve-layout-buttons';
const rowCssClassName = `${baseCssClassName}__row`;
const primaryCssClassName = `${baseCssClassName}__primary`;
const secondaryCssClassName = `${baseCssClassName}__secondary`;
const buttonCssClassName = `${baseCssClassName}__button`;
const addIconCssClassName = `${baseCssClassName}__add-icon`;


export default class ResolverControllerButtons extends Component {
	static propTypes = {
		shouldDisplayAnalyzeButton: PropTypes.bool.isRequired,
		shouldDisplayReportButton: PropTypes.bool.isRequired,
		shouldDisplayExportButton: PropTypes.bool.isRequired,
		shouldDisplayAddButton: PropTypes.bool.isRequired,
		shouldDisplayRedoButton: PropTypes.bool.isRequired,
		shouldDisplayUndoButton: PropTypes.bool.isRequired,
		isExportButtonDisabled: PropTypes.bool,
		isImageInAnalysis: PropTypes.bool.isRequired,
		findingsFilter: PropTypes.object.isRequired,
		isSequentialModeEnabled: PropTypes.bool.isRequired,
		shouldHighlightAnalyzeButton: PropTypes.bool.isRequired,
		canReanalyze: PropTypes.bool.isRequired,
		isPatientTooYoung: PropTypes.bool.isRequired,
		onGetExportLink: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		onResetMode: PropTypes.func.isRequired,
	};
	
	static defaultProps = {
		shouldDisplayAddButton: true,
		shouldDisplayRedoButton: true,
		shouldDisplayUndoButton: true,
	};
	
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor (props, context) {
		super(props, context);
		
		this.state = {
			isAddLabelPopupOpened: false,
			isAutoChartOpened: false,
		};
	}
	
	_handleAddButtonClick = () => {
		trackEvent('Findings Add Button Click');
		this.setState({
			isAddLabelPopupOpened: true,
		});
	};
	
	_handleCancelAddLabel = () => {
		this.setState({
			isAddLabelPopupOpened: false,
		});
	};
	
	_handleExportButtonClick = () => {
		trackEvent('TP Auto-Chart Button Click');

		if ( this.props.isPatientTooYoung === true ) {
			confirm({
				message: 'The patient is younger than 21. Are you sure you want to proceed?',
				yesHandler: () => {
					this.setState({
						isAutoChartOpened: true,
					});
				},
			});

			return;
		}

		this.setState({
			isAutoChartOpened: true,
		});
	};

	_handleAnalyzeButtonClick = () => {
		trackEvent('TP Analyze Button Click');
		confirm({
			title: 'Warning',
			message: 'All manual changes will be deleted. Do you want to continue?',
			yesHandler: () => {
				this.props.onAnalyzeImage();
			},
		})
	};

	_handleReportButtonClick = () => {
		trackEvent('TP Report Button Click');
		window.open(this.props.reportImageUrl, '_blank');
	};

	_handleConfirmAutoChart = (params) => {
		trackEvent('TP Auto-Chart Confirm');

		const event = {
			func: 'handleDentiExport',
			message: this.props.onGetExportLink(params),
		};
		if ( window.parent ) {
			window.parent.postMessage(event, '*')
		}

		if ( window.CefSharp && typeof window.CefSharp.PostMessage === 'function' ) {
			window.CefSharp.PostMessage(event);
		}

		this.setState({
			isAutoChartOpened: false,
		});

		this.props.onResetMode();
	};

	_handleCloseAutoChart = () => {
		this.setState({
			isAutoChartOpened: false,
		});
		this.props.onResetMode();
	};

	_renderAddLabelPopup () {
		if ( !this.state.isAddLabelPopupOpened ) {
			return null;
		}
		
		return (
			<ResolveAddLabel
				onClose={this._handleCancelAddLabel}
			/>
		);
	}

	_renderAutoChartPopup () {
		if ( !this.state.isAutoChartOpened ) {
			return null;
		}

		return (
			<ResolverAutoChart
				findingsFilter={this.props.findingsFilter}
				onConfirm={this._handleConfirmAutoChart}
				onClose={this._handleCloseAutoChart}
			/>
		);
	}
	
	render () {
		return (
			<div className={baseCssClassName}>
				<div className={rowCssClassName}>
					<div className={primaryCssClassName}>
						{this.props.shouldDisplayAddButton === true && (
							<div className={buttonCssClassName}>
								<RoundedButton
									theme={RoundedButton.themes.primary}
									type={'add-thin'}
									size={RoundedButton.sizes.MEDIUM}
									onClick={this._handleAddButtonClick}
								/>
							</div>
						)}
						{this.props.shouldDisplayUndoButton === true && (
							<div className={buttonCssClassName}>
								<ResolverUndo />
							</div>
						)}
						{this.props.shouldDisplayRedoButton === true && (
							<div className={buttonCssClassName}>
								<ResolverRedo />
							</div>
						)}
					</div>
					<div className={secondaryCssClassName}>
						{this.props.shouldDisplayAnalyzeButton === true && (
							<div className={buttonCssClassName}>
								<Button
									size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.MEDIUM}
									theme={Button.AVAILABLE_THEMES.SECONDARY}
									title={'Analyze'}
									disabled={this.props.isImageInAnalysis === true}
									isPulsing={this.props.shouldHighlightAnalyzeButton}
									onClick={this._handleAnalyzeButtonClick}
								>{this.props.canReanalyze ? 'Re-analyze' : 'Analyze'}</Button>
							</div>
						)}
						{this.props.shouldDisplayReportButton === true && (
							<div className={buttonCssClassName}>
								<Button
									size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.MEDIUM}
									theme={Button.AVAILABLE_THEMES.SECONDARY}
									disabled={this.props.isSequentialModeEnabled === true}
									title={this.props.isSequentialModeEnabled === false ? 'Report' : null}
									onClick={this._handleReportButtonClick}
								>Report</Button>
							</div>
						)}
					</div>
				</div>
				{this.props.shouldDisplayExportButton === true && (
					<div className={rowCssClassName}>
						<div className={primaryCssClassName}>
							<Button
								size={isSmallScreen() ? Button.AVAILABLE_SIZES.SMALL : Button.AVAILABLE_SIZES.MEDIUM}
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								title={this.props.isSequentialModeEnabled === false ? 'Auto-Chart' : 'Please Review pathological findings before Auto-Charting'}
								disabled={this.props.isSequentialModeEnabled === true || this.props.isExportButtonDisabled === true}
								block
								onClick={this._handleExportButtonClick}
							>Auto-Chart to PMS</Button>
						</div>
					</div>
				)}
				{this._renderAddLabelPopup()}
				{this._renderAutoChartPopup()}
			</div>
		);
	}
}
