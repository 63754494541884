import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import mainConfig from '../../../../configs/mainConfig';

import { trackEvent } from '../../../../integrations/mixpanel';

import { isBonelossLine } from '../../../../appUtils/bonelossUtils';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { getNonEmptyPreviousToothKey, getNonEmptyNextToothKey } from './TfvUtils';
import { checkFindingsFilter, getFindingCategory, isExtraFinding } from '../../../resolver/utils/findings-filter';
import { isSequentialModeEnabledForVisit, isShapeIsPresent } from '../../../resolver/utils';

import { updateLabelShape, addLabel, removeLabelShape } from '../../../resolver/actions';
import labelsActions from '../../../labels/actions/labelsActions';
import editorActions from '../../../../actions/editorActions';

import editorSelectors from '../../../../selectors/editorSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';

import TfvList from './TfvList';


export default withRouter(connect(
	(state, { history }) => {
		const editorData = editorSelectors.selectEditor(state);
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const currentCollectionId = editorData.currentCollectionHashName;
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const selectedToothKey = editorData.selectedToothKey;
		const findingsFilter = editorData.treatmentPlanFilters;
		const images = {};
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
		const tooth = labelsSelectors.selectLabelByToothKey(state, { toothKey: selectedToothKey });
		if ( typeof tooth !== 'undefined' && tooth !== null ) {
			const labelId = labelGetters.getLabelId(tooth);

			if ( typeof tooth.shapes === 'object' && tooth.shapes !== null ) {
				Object.keys(tooth.shapes).forEach((imageHashName) => {
					const image = imagesSelectors.selectImageByHashName(state, { hashname: imageHashName });
					const shape = tooth.shapes[imageHashName];
					if (
						image === null ||shape === null ||
						typeof shape.type !== 'string' ||
						shape.type === 'none' ||
						editorData.notAnalyzedImages.includes(image.id) === true
					) {
						return;
					}

					images[imageHashName] = { shape, image, labels: [] };
					// if ( editorData.showFindingsOnImage === true ) {
					// 	images[imageHashName].labels.push({
					// 		...tooth,
					// 		shape,
					// 	});
					// }
				});
			}

			if ( editorData.showFindingsOnImage === true ) {
				const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId });
				children.forEach((labelId) => {
					const label = labelsSelectors.selectLabelById(state, {
						labelId,
					});

					const classId = labelGetters.getLabelClassId(label);
					const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(label);
					const labelCategory = getFindingCategory({ label });

					if ( isBonelossLine(classId) === true ) {
						if (
							editorData.showBoneLossStages === false ||
							mainConfig.BONE_LOSS_LINES_VISIBLE.includes(classId) === false
						) {
							return;
						}
					}
					else if (
						labelsUtils.labelIsTooth(label) === true ||
						checkFindingsFilter({
							findingsFilter,
							label,
						}) === false ||
						isExtraFinding({ label }) === true ||
						(
							isSequentialModeEnabled === true && (
								classId === 'caries' ||
								classId === 'tooth_bone_loss' ||
								classId === 'periodontitis'
							)
						) ||
						(
							typeof measureOfConfidence === 'number' &&
							editorData.filteredConfidencePercent > measureOfConfidence &&
							( classId === 'caries' || classId === 'periodontitis' || classId === 'impacted tooth' )
						) ||
						(
							user.is_simple_ui_enabled === true && labelCategory !== 'pathological'
						) ||
						(
							labelCategory === 'tissue' &&
							!editorData.shouldShowTissueFindings
						)
					) {
						return;
					}

					if ( typeof label.shapes === 'object' && label.shapes !== null ) {
						Object.keys(label.shapes).forEach((imageHashName) => {
							const shape = label.shapes[imageHashName];
							if ( typeof images[imageHashName] === 'undefined' || typeof shape.type !== 'string' ) {
								return;
							}
							images[imageHashName].labels.push({
								...label,
								shape,
							});
						});
					}
				});
			}
		}

		return {
			toothKey: selectedToothKey,
			localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey: selectedToothKey, notationType }),
			prevToothKey: getNonEmptyPreviousToothKey(selectedToothKey, (toothKey) => labelsSelectors.selectLabelByToothKey(state, { toothKey })),
			nextToothKey: getNonEmptyNextToothKey(selectedToothKey, (toothKey) => labelsSelectors.selectLabelByToothKey(state, { toothKey })),
			images,
			areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
			areHeatMapsEnabled: editorData.areHeatMapsEnabled,
			allowShifting: currentImage.isOwn === true,
			allowRemoving: currentImage.isOwn === true,
			selectedLabel: editorData.selectedLabel,
			showFindingsOnImage: editorData.showFindingsOnImage,
			onGoToImage: (image) => {
				history.push(`/collections/${currentCollectionId}/image/${image.hashname}/treatment_plan`);
			},
			onGoToTooth: (toothKey) => {
				history.push(`/collections/${currentCollectionId}/visit/${editorData.currentExamination}/tfv/${toothKey}`);
			},
			onGoToClosestTooth: (toothKey) => {
				const teeth = teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth());
				const index = teeth.indexOf(toothKey);
				let prevIndex = index - 1;
				let nextIndex = index + 1;

				const hasShapes = (shapes) => Object.keys((shapes))
					.some((imageHashName) => {
						const shape = shapes[imageHashName];
						const image = imagesSelectors.selectImageByHashName(state, { hashname: imageHashName });

						return (
							isShapeIsPresent(shape) === true &&
							editorData.notAnalyzedImages.includes(image.id) === false
						);
					});

				while (prevIndex >= 0 || nextIndex <= teeth.length) {
					const prevToothKey = teeth[prevIndex];
					const nextToothKey = teeth[nextIndex];
					const prevTooth = labelsSelectors.selectLabelByToothKey(state, { toothKey: prevToothKey });
					const nextTooth = labelsSelectors.selectLabelByToothKey(state, { toothKey: nextToothKey });

					if ( prevTooth && hasShapes(prevTooth.shapes) ) {
						history.push(`/collections/${currentCollectionId}/visit/${editorData.currentExamination}/tfv/${prevToothKey}`);
						return;
					}
					else if ( nextTooth && hasShapes(nextTooth.shapes) ) {
						history.push(`/collections/${currentCollectionId}/visit/${editorData.currentExamination}/tfv/${nextToothKey}`);
						return;
					}
					else {
						prevIndex--;
						nextIndex++;
					}
				}
			},
			labelColorFilterFn: (label) => {
				const classId = labelGetters.getLabelClassId(label);
				return (isSequentialModeEnabled === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
			},
			enhancedImageFilter: editorData.enhancedImageFilter,
		};
	},
	(dispatch) => ({
		onUpdateLabelShape: (data) => {
			trackEvent('TFV Update Shape', data);
			dispatch(updateLabelShape(data));
		},
		onLabelRemove: (data) => {
			if ( typeof data.toothKey === 'string' ) {
				trackEvent('TFV Remove Tooth', data);
			}
			else {
				trackEvent('TFV Remove Shape', data);
			}
			return dispatch(removeLabelShape(data));
		},
		onAddLabel: (data) => {
			trackEvent('TFV Add Finding', data);
			return dispatch(addLabel(data));
		},
		onShiftTooth: (data) => {
			trackEvent('TFV Teeth Shift', data);
			return dispatch(labelsActions.shiftTooth(data));
		},
		onSelectLabel: (data) => {
			if ( data.labelId !== null ) {
				trackEvent('TFV Selected Label', data);
			}
			else {
				trackEvent('TFV Deselected Label');
			}

			return dispatch(editorActions.selectLabel(data));
		},
	})
)(TfvList));
