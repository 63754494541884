import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SHIFT_DIRECTION__LEFT, SHIFT_DIRECTION__RIGHT } from '../../constants/teethConstants';

import mainConfig from '../../configs/mainConfig';

import teethUtils from '../../appUtils/teeth/teethUtils';
import labelsUtils from '../../appUtils/labelsUtils';
import { isSmallScreen } from '../../appUtils/mobileUtils';

import labelGetters from '../../modules/labels/selectors/labelGetters';

import { ShiftNumberingButton } from './ShiftNumberingButton';
import DentalNotationDiagramDefaultToothWrapper from './DentalNotationDiagramDefaultToothWrapper';
import Tooltip from '../Tooltip';

import { toothSvgMap } from './DentalNotationDiagramConstants';

import { getDictionary } from '../../appUtils/locale';

import './styles/DentalNotationDiagram.css';


const i18n = getDictionary('dental-notation-diagram');

const topTeethOrder = teethUtils.getOrderedTopTeeth();
const bottomTeethOrder = teethUtils.getOrderedBottomTeeth();

const baseCssClassName = 'dental-notation-diagram';
const imageCssClassName = `${baseCssClassName}__image`;
const topRowCssClassName = `${baseCssClassName}__top-row`;
const bottomRowCssClassName = `${baseCssClassName}__bottom-row`;
const toothKeyCssClassName = `${baseCssClassName}__tooth-key`;
const toothFindingsCssClassName = `${baseCssClassName}__tooth-findings`;
const toothFindingCssClassName = `${baseCssClassName}__tooth-finding`;
const tooltipBottomCssClassName = `${baseCssClassName}__tooltip-bottom`;
const shiftNumberingCssClassName = `${baseCssClassName}__shift-numbering`;
const shiftNumberingButtonsCssClassName = `${baseCssClassName}__shift-numbering-buttons`;
const shiftNumberingButtonWrapperCssClassName = `${baseCssClassName}__shift-numbering-button-wrapper`;


export default class DentalNotationDiagram extends Component {
	static propTypes = {
		selectedToothKey: PropTypes.string,
		teethAvailableToShift: PropTypes.object.isRequired,
		teeth: PropTypes.object.isRequired,
		editorMode: PropTypes.string.isRequired,
		currentImage: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		teethNotification: PropTypes.arrayOf(PropTypes.string.isRequired),
		teethOutlined: PropTypes.arrayOf(PropTypes.string.isRequired),
		labelColorFilterFn: PropTypes.func,
		toothWrapper: PropTypes.func,
		withMeasureOfConfidence: PropTypes.bool,
		tooltipOptions: PropTypes.shape({
			enabled: PropTypes.bool.isRequired,
			footerEnabled: PropTypes.bool.isRequired,
		}),
		onHighlightLabels: PropTypes.func.isRequired,
		onSelectLabel: PropTypes.func.isRequired,
		onSelectToothKey: PropTypes.func.isRequired,
		onToothDoubleClick: PropTypes.func,
	};
	
	static defaultProps = {
		teethNotification: [],
		labelColorFilterFn: () => true,
		toothWrapper: DentalNotationDiagramDefaultToothWrapper,
		withMeasureOfConfidence: true,
		tooltipOptions: {
			enabled: true,
			footerEnabled: true,
		},
		onToothDoubleClick: () => {},
	};

	constructor (props, context) {
		super(props, context);

		this.state = {
			hoveredLabel: null,
			hoveredLabelPosition: null,
		};
		this._selectedLabel = null;
		this._hoveredEl = null;
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if ( nextProps.selectedToothKey ) {
			const nextSelectedLabel = nextProps.teeth[nextProps.selectedToothKey];

			if( nextSelectedLabel !== this._selectedLabel ) {
				this._selectedLabel = nextSelectedLabel;
			}
		}
	}

	_selectTooth (toothKey) {
		const {
			editorMode,
			teeth,
			currentImage,
		} = this.props;

		this._selectedLabel = teeth[toothKey];

		if ( !currentImage.isOwn ) {
			return;
		}
		const isSelectOrEditMode = editorMode === mainConfig.EDITOR_MODE__SELECT_MODE ||
			editorMode === mainConfig.EDITOR_MODE__EDIT_MODE;

		if (
			( isSelectOrEditMode && this._selectedLabel ) ||
			( editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH && !this._selectedLabel )
		) {
			if ( this._selectedLabel ) {
				if ( typeof this.props.onSelectLabel === 'function' ) {
					this.props.onSelectLabel({
						label: this._selectedLabel,
						toothKey,
					});
				}
			}
			else {
				if ( typeof this.props.onSelectToothKey === 'function' ) {
					this.props.onSelectToothKey({
						toothKey,
					});
				}
			}
		}
	}

	_getToothColor (toothKey) {
		const {
			selectedToothKey,
			teeth,
		} = this.props;

		const {
			hoveredLabel,
		} = this.state;

		const label = teeth[toothKey];

		const isHighlighted = (
			( selectedToothKey === toothKey ) ||
			( hoveredLabel && label && labelGetters.getLabelId(hoveredLabel) === labelGetters.getLabelId(label) )
		);
		// Add opacity
		let color = labelsUtils.getLabelColor(label, this.props.labelColorFilterFn);
		if ( !isHighlighted ) {
			if ( color.startsWith('#') === true ) {
				color += 50;
			}
			else if ( color.startsWith('hsl') === true ) {
				color = color.replace('hsl', 'hsla')
					.replace(/\)$/, ',.5)');
			}
		}

		return color;
	}

	_renderToothKey (toothKey) {
		const {
			selectedToothKey,
			teethNotification,
		} = this.props;
		const label = this.props.teeth[toothKey];

		const isSelected = (selectedToothKey === toothKey);

		return (
			<span
				key={toothKey}
				className={classnames([
					toothKeyCssClassName,
					`${toothKeyCssClassName}__m-key-${toothKey}`,
					isSelected && `${toothKeyCssClassName}__m-selected`,
					teethNotification.includes(toothKey) && `${toothKeyCssClassName}__m-notified`,
				])}
				style={{
					borderColor: isSelected ? labelsUtils.getLabelColor(label, this.props.labelColorFilterFn) : null,
				}}
			>{teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type})}</span>
		);
	}

	_renderTooth (toothKey) {
		const label = this.props.teeth[toothKey];
		const color = this._getToothColor(toothKey);

		const additionalProps = {};

		if ( this.props.teethOutlined && this.props.teethOutlined.includes(toothKey) ) {
			additionalProps.stroke = '#c9cbd280';
			additionalProps.strokeWidth = 1;
		}

		const ToothWrapper = this.props.toothWrapper;

		return (
			<ToothWrapper
				{...this.props}
				key={toothKey}
				toothKey={toothKey}
			>
				<path
					fill={color}
					d={toothSvgMap[toothKey]}
					onClick={() => this._selectTooth(toothKey)}
					onDoubleClick={() => {
						if ( !label ) {
							return;
						}

						this.props.onToothDoubleClick(toothKey);
					}}
					onMouseOver={(event) => {
						if ( !label ) {
							return;
						}
						this._hoveredEl = event.target;
						const rect = this._hoveredEl.getBoundingClientRect();

						if ( rect ) {
							const scrollY = window.scrollY;
							this.setState({
								hoveredLabel: label,
								hoveredLabelPosition: {
									top: rect.top + scrollY,
									left: rect.left,
									right: rect.right,
									bottom: rect.bottom + scrollY,
								},
							});
						}

						if ( typeof this.props.onHighlightLabels === 'function' ) {
							this.props.onHighlightLabels([ labelGetters.getLabelId(label) ]);
						}
					}}
					onMouseOut={() => {
						this.setState({
							hoveredLabel: null,
							hoveredLabelPosition: null,
						});
						if ( typeof this.props.onHighlightLabels === 'function' ) {
							this.props.onHighlightLabels([]);
						}
						this._hoveredEl = null;
					}}
					{...additionalProps}
				/>
			</ToothWrapper>
		);
	}

	_renderToothTooltip = () => {
		const {
			editorMode,
			currentImage,
		} = this.props;

		const {
			hoveredLabel,
			hoveredLabelPosition,
		} = this.state;

		if (
			this.props.tooltipOptions.enabled === false ||
			!hoveredLabel ||
			!hoveredLabelPosition ||
			editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH ||
			( hoveredLabel.children.length === 0 && ( !currentImage.isOwn || this.props.tooltipOptions.footerEnabled === false ) ) ||
			isSmallScreen()
		) {
			return null;
		}


		return (
			<Tooltip
				target={this._hoveredEl}
				isGlobal
			>
				<div className={toothFindingsCssClassName}>
					{ hoveredLabel.children.map((label, i) => (
						<div
							key={i}
							className={toothFindingCssClassName}
						>
							{labelsUtils.getLocalizedLabelName(label)}
							{this.props.withMeasureOfConfidence === true && labelsUtils.shouldShowConfidenceForClass(labelGetters.getLabelClassId(label)) && typeof labelGetters.getLabelMeasureOfConfidence(label) === 'number'
								? ` (AI Confidence ${Math.floor(labelGetters.getLabelMeasureOfConfidence(label) * 100)}%)`
								: null
							}
						</div>)
					) }
					{ this.props.tooltipOptions.footerEnabled === true && currentImage.isOwn && (
						<div
							className={tooltipBottomCssClassName}
							dangerouslySetInnerHTML={{
								__html: i18n('tooltip-footer'),
							}}
						/>
					)}
				</div>
			</Tooltip>
		);
	};

	_renderShiftButton = (direction) => {
		const toothKeysToShift = this._getToothKeysToShifting(direction);
		return (
			<ShiftNumberingButton
				onClick={() => {
					const newSelectedToothKey = (direction === SHIFT_DIRECTION__RIGHT)
						? teethUtils.getNextToothKey(this.props.selectedToothKey)
						: teethUtils.getPreviousToothKey(this.props.selectedToothKey);

					this.props.onShiftTeeth({
						toothKeysToShift,
						direction,
					});

					this.props.onSelectToothKey({
						toothKey: newSelectedToothKey,
					});
				}}
				direction={direction}
				disabled={toothKeysToShift.length === 0 || this.props.editorMode === mainConfig.EDITOR_MODE__SELECT_TOOTH}
			/>
		);
	};

	_getToothKeysToShifting = (direction) => {
		const {
			selectedToothKey,
			teethAvailableToShift,
		} = this.props;

		if ( !selectedToothKey || selectedToothKey === 'md' || selectedToothKey === 'x' ) {
			return [];
		}

		const toothKeysToShift = [];
		let nextToothKey = null;
		let currentToothKey = selectedToothKey;

		const getNewToothKey = (direction === SHIFT_DIRECTION__LEFT)
			? teethUtils.getPreviousToothKey
			: teethUtils.getNextToothKey;

		do {
			toothKeysToShift.push(currentToothKey);
			nextToothKey = getNewToothKey(currentToothKey);
			if ( nextToothKey && !teethAvailableToShift[nextToothKey] ) {
				return toothKeysToShift;
			}

			currentToothKey = nextToothKey;

		} while (nextToothKey);

		return [];
	};

	_renderShiftNumberingButtons = () => {
		return (
			<div className={shiftNumberingCssClassName}>
				<div className={shiftNumberingButtonsCssClassName}>
					<div className={shiftNumberingButtonWrapperCssClassName}>
          				{this._renderShiftButton(SHIFT_DIRECTION__LEFT)}
					</div>
					<div className={shiftNumberingButtonWrapperCssClassName}>
						{this._renderShiftButton(SHIFT_DIRECTION__RIGHT)}
					</div>
				</div>
			</div>
		);
	};

	render () {
		return (
			<div className={baseCssClassName}>
				{this._renderShiftNumberingButtons()}

				<div className={topRowCssClassName}>
					{topTeethOrder.map((toothKey) => this._renderToothKey(toothKey))}
				</div>

				<svg width={'321px'} height={'93px'} viewBox={'0 0 321 91'} className={imageCssClassName}>
					{topTeethOrder.map((toothKey) => this._renderTooth(toothKey))}
					{bottomTeethOrder.map((toothKey) => this._renderTooth(toothKey))}
				</svg>

				<div className={bottomRowCssClassName}>
				  {bottomTeethOrder.map((toothKey) => this._renderToothKey(toothKey))}
				</div>

				{this._renderToothTooltip()}
			</div>
		);
	}
}
