import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import mainConfig from '../../../../configs/mainConfig';

import commonUtils from '../../../../appUtils/common';
import labelsUtils from '../../../../appUtils/labelsUtils';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { isSequentialModeEnabledForVisit } from '../../utils';

import { checkFindingsFilter, getFindingCategory } from '../../utils/findings-filter';

import editorActions from '../../../../actions/editorActions';
import { removeLabel } from '../../actions';

import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import editorSelectors from '../../../../selectors/editorSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';

import labelGetters from '../../../labels/selectors/labelGetters';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';

import { ResolverFindingViewer } from '../finding-viewer';


export default connect((state, props) => {
	const editorData = editorSelectors.selectEditor(state);
	const user = userSelectors.selectUserData(state);
	const notationType = user.notation_type;

	let findings = [];
	const currentCollectionId = editorData.currentCollectionHashName;
	const currentImageId = editorData.currentImageId;
	const currentImage = imagesSelectors.selectImageById(state, {
		id: currentImageId,
	});
	const findingsFilter = editorData.treatmentPlanFilters;
	const isSequentialMode = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
	const label = {
		...props.label,
	};
	const labelId = labelGetters.getLabelId(label);
	const currentCollection = collectionsSelectors.selectCollectionById(state, {
		id: editorData.currentCollectionHashName,
	});
	const collectionImages = currentCollection.images.reduce((result, image) => {
		result[image.hashname] = image;

		return result;
	}, {});
	const teeth = labelsSelectors.selectTeeth(state);

	let isTooth = false;
	let tooth = null;

	if ( labelsUtils.labelIsTooth(label) ) {
		isTooth = true;
		let toothKey = null;
		const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
		if ( tags && tags.length > 0 ) {
			toothKey = labelTagGetter.getTagKey(tags[0]);
			label.toothKey = toothKey;
			label.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey, notationType });
		}
		tooth = label;
	}
	else {
		const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		let parentLabelId = null;

		Object.keys(labelChildren)
			.forEach((_labelId) => {
				labelChildren[_labelId].forEach((childLabelId) => {
					if ( childLabelId === labelId ) {
						parentLabelId = _labelId;
					}
				});
			});

		if ( parentLabelId !== null ) {
			const parentLabel = {
				...labelsSelectors.selectLabelById(state, {
					labelId: parentLabelId,
				}),
			};

			if ( labelsUtils.labelIsTooth(parentLabel) ) {
				isTooth = true;
				let toothKey = null;
				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId: parentLabelId });
				if ( tags && tags.length > 0 ) {
					toothKey = labelTagGetter.getTagKey(tags[0]);
					parentLabel.toothKey = toothKey;
					parentLabel.localizedToothKey = teethUtils.getLocalizedToothKey({ toothKey, notationType });
				}
				tooth = parentLabel;
			}
		}
		else {
			// TODO: add conditions
			findings.push({
				[currentImage.hashname]: {
					findings: [ label ],
				},
			});
		}
	}

	if ( isTooth === true ) {
		const imageTeeth = {};

		Object.values(imagesSelectors.selectImages(state)).forEach((image) => {
			imagesLabelsSelectors.selectImageLabelsByImageId(state, { imageId: image.id }).forEach((labelId) => {
				const label = labelsSelectors.selectLabelById(state, { labelId });

				if ( labelsUtils.labelIsTooth(label) === false ) {
					return;
				}

				const tags = labelTagsSelectors.selectLabelTagsByLabelId(state, { labelId });
				if ( Array.isArray(tags) === false || tags.length === 0 ) {
					return;
				}

				const toothKey = labelTagGetter.getTagKey(tags[0]);

				const shape = lodashGet(labelsSelectors.selectLabelById(state, { labelId }), `shapes.[${image.hashname}]`, {});
				if ( toothKey !== tooth.toothKey || typeof shape.type !== 'string' ) {
					return;
				}

				if ( imageTeeth.hasOwnProperty(image.hashname) === false ) {
					imageTeeth[image.hashname] = {};
				}

				imageTeeth[image.hashname] = { label, shape, imageId: image.id };
			});
		});

		findings = Object.keys(imageTeeth).reduce((result, imageHashName) => {
			const label = imageTeeth[imageHashName].label;
			const isCurrentImage = currentImage.hashname === imageHashName;
			const data = {
				imageHashName,
				findings: [],
				order: isCurrentImage === true ? 1 : 0,
				isCurrentImage,
				imageType: collectionImages[imageHashName].image_type,
				imageUrl: collectionImages[imageHashName].image_url,
				imageToothShape: imageTeeth[imageHashName].shapes ? imageTeeth[imageHashName].shapes[imageHashName] : null,
				teethMeta: teethUtils.sortTeethMeta(
					teethUtils.getOrderedTopTeeth().concat(teethUtils.getOrderedBottomTeeth()),
					teeth[imageHashName]
				),
			};

			result.push(data);

			const children = labelChildrenSelectors.selectLabelChildrenByLabelId(state, { labelId: labelGetters.getLabelId(label) });
			if ( children.length > 0 ) {
				children.forEach((childLabelId) => {
					const childLabel = labelsSelectors.selectLabelById(state, {
						labelId: childLabelId,
					});
					const measureOfConfidence = labelGetters.getLabelMeasureOfConfidence(childLabel);
					const shape = childLabel.shapes[imageHashName] || {};
					const labelCategory = getFindingCategory({ label: childLabel });

					if (
						typeof shape.type === 'string' &&
						checkFindingsFilter({
							findingsFilter,
							label: childLabel,
							showBoneLossStages: editorData.showBoneLossStages,
							allowBoneLossLines: mainConfig.BONE_LOSS_LINES_VISIBLE,
						})
						&& (
							typeof measureOfConfidence !== 'number' ||
							editorData.filteredConfidencePercent <= measureOfConfidence
						) &&
						labelCategory !== 'tissue'
					) {
						data.findings.push({
							label: {
								...childLabel,
								localizedLabelName: labelsUtils.getLocalizedLabelName(childLabel),
								shape,
							},
							allowRemove: mainConfig.BONE_LOSS_LINES.includes(labelGetters.getLabelClassId(childLabel)) === false,
						});
					}
				});
			}

			if ( data.findings.length > 0 ) {
				data.findings.sort((a, b) => a.label.localizedLabelName.localeCompare(b.label.localizedLabelName));
			}

			return result;
		}, []);
	}

	findings.sort((a, b) => b.order - a.order);

	return {
		isTooth,
		tooth,
		findings,
		currentImage,
		notationType,
		areFindingsMasksEnabled: editorData.areFindingsMasksEnabled,
		getImageUrl: (imageHashName = currentImage.hashname, seed = null) => {
			const currentCollection = collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			});
			let imageUrl = null;
			currentCollection.images.forEach((image) => {
				if ( image.hashname === imageHashName ) {
					imageUrl = image.image_url;
					if ( seed !== null ) {
						imageUrl = commonUtils.addParamToUrl(imageUrl, 'seed', seed);
					}
				}
			});

			return imageUrl;
		},
		getImageLink: (imageHashName) => {
			return `/collections/${currentCollectionId}/image/${imageHashName}/treatment_plan`;
		},
		labelColorFilterFn: (label) => {
			const classId = labelGetters.getLabelClassId(label);
			return (isSequentialMode === false || labelsUtils.getLabelAvailabilityOptions(classId).findings_group !== 'pathological');
		},
	};
}, (dispatch) => ({
	onRemoveLabel: (data) => dispatch(removeLabel(data)),
	onClose: () => dispatch(editorActions.resetMode()),
}))(ResolverFindingViewer);
