import lodashDebounce from 'lodash/debounce';

import storeUtils from '../appUtils/storeUtils';
import helpers from '../appUtils/helpers';

import collectionApi from '../api/collectionApi';

import commonActions from './commonActions';
import apiActions from './apiActions';
import editorActions from './editorActions';
import collectionsActionTypes from './collectionsActionTypes';
import imagesCollectionsActionTypes from './imagesCollectionsActionTypes';

import collectionsSelectors from '../selectors/collectionsSelectors';
import imagesSelectors from '../selectors/imagesSelectors';
import currentCollectionSelectors from '../selectors/currentCollectionSelectors';
import userSelectors from '../selectors/userSelectors';
import editorSelectors from "../selectors/editorSelectors";


const saveCollectionMeta = ({ collection, data, dispatch }) => {
	debugger;
	dispatch(editCollection({
		hashname: collection.hashname,
		collection_meta: data,
	}));
};

const saveCollectionMetaQueue = lodashDebounce(saveCollectionMeta, 100);


function getList (params = {}) {
	return (dispatch, getState) => {
		return collectionApi.getList(params)
			.then((collectionsAnswer) => {
				const storeState = getState();
				let collections = collectionsAnswer.data;
				const meta = collectionsAnswer.meta;
				const prevCollections = collectionsSelectors.selectCollectionItems(storeState);
				const prevOrderedCollections = collectionsSelectors.selectOrderedCollections(storeState);
				const orderedCollections = prevOrderedCollections.slice();
				orderedCollections.length = meta.total_count;

				let index = meta.page_size * (meta.current_page - 1);
				collections = collections.reduce((result, collection) => {
					if ( prevCollections[collection.hashname] ) {
						result[collection.hashname] = {
							...prevCollections[collection.hashname],
							...collection,
						};
					}
					else {
						result[collection.hashname] = collection;
					}
					orderedCollections[index] = collection.hashname;
					++index;

					return result;
				}, prevCollections);
				dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__SET_COLLECTIONS, {
					collections,
					orderedCollections,
				}));

				return collectionsAnswer;
			});
	};
}

function getCollection ({ hashName, params }) {
	return (dispatch, getState) => {
		const storeState = getState();
		hashName = hashName || collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		}).hashname;
		const user = userSelectors.selectUserData(storeState);
		return collectionApi.getCollection({ hashName, params })
			.then((collection) => {
				// Add mesiodens and unknown teeth to the label attributes.
				if ( Array.isArray(collection.labels) === true ) {
					collection.labels = collection.labels.map((label) => {
						if ( label.class_id !== 'tooth' || Array.isArray(label.attributes) === false ) {
							return label;
						}

						return {
							...label,
							attributes: [
								...label.attributes,
								{ key: 'md', readable_name: 'md' },
								{ key: 'x', readable_name: 'x' },
							],
						};
					})
				}

				collection.images = collection.images.map((image) => {
					if (
						typeof image.enhanced_image_url === 'string' &&
						image.enhanced_image_url.length > 0 &&
						user.is_image_enhancement_enabled === false
					) {
						image.enhanced_image_url = null;
					}

					return image;
				});

				const storeState = getState();
				const collections = { ...collectionsSelectors.selectCollectionItems(storeState) };
				collections[hashName] = collection;
				const images = helpers.fetchImages({
					storeState,
					images: collection.images,
					isOwnImages: true,
					// isOwnImages: userSelectors.selectUserData(storeState).username === collection.username,
				});
				collection.images = Object.keys(images).reduce((result, key) => {
					result.push(images[key]);
					return result;
				}, []);

				const data = {
					images: {
						...imagesSelectors.selectImages(storeState),
						...images,
					},
					imagesCollections: helpers.fetchImagesCollections({
						storeState,
						images: Object.values(images),
						imageCollectionId: hashName,
					}),
				};

				dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__SET_COLLECTIONS, {
					collections,
					orderedCollections: collectionsSelectors.selectOrderedCollections(storeState),
				}));
				dispatch(apiActions.setData(data));
				dispatch(editorActions.updateData({
					data: {
						filteredClasses: helpers.getFilteredClasses({
							storeState,
							currentCollection: collection,
						}),
						currentCollectionHashName: hashName,
					},
				}));
				dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__MARK_AS_SYNCED, hashName));

				return collection;
			});
	};
}

function addCollection (options = {}) {
	return (dispatch, getState) => {
		return collectionApi.createCollection(options);
	};
}

function removeCollection (options = {}) {
	return (dispatch, getState) => {
		return collectionApi.removeCollection(options)
	};
}

function editCollection (options = {}) {
	const {
		hashname,
		...data
	} = options;

	return async (dispatch, getState) => {
		try {
			await collectionApi.saveCollection(hashname, data);
			const collections = { ...collectionsSelectors.selectCollectionItems(getState()) };
			const orderedCollections = collectionsSelectors.selectOrderedCollections(getState());
			collections[hashname] = {
				...collections[hashname],
				...data,
			};
			dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__SET_COLLECTIONS, {
				collections,
				orderedCollections,
			}));
		}
		catch (error) {
			throw error;
		}
	};
}

function setCollectionAsDefault (options) {
	return async (dispatch, getState) => {
		try {
			await collectionApi.saveCollection(options.hashname, {
				is_default: true,
			});
			dispatch(getList());
		}
		catch (error) {
			throw error;
		}
	};
}

/**
 * @param {Collection|string} collection
 * @return {(function(*): void)|*}
 */
function setCurrentCollection ({ collection }) {
	return (dispatch) => {
		dispatch(editorActions.updateData({
			data: {
				currentCollectionHashName: typeof collection === 'string'
					? collection
					: collection ? collection.hashname : null
				,
			},
		}));
	};
}

function addImageToCurrentCollection (options) {
	return (dispatch, getState) => {
		if ( typeof options.imageId !== 'string' ) {
			throw new Error(`addImageToCurrentCollection: Option "imageId" is required.`);
		}

		dispatch(storeUtils.makeAction(imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__SHIFT_IMAGE, {
			collectionId: editorSelectors.selectEditor(getState()).currentCollectionHashName,
			imageId: options.imageId,
		}));
	};
}

function setCollections ({ collections }) {
	return (dispatch, getState) => {
		dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__SET_COLLECTIONS, collections));
	};
}

function copyCollection (options = {}) {
	return (dispatch, getState) => {
		collectionApi.copyCollection(options.hashName, options.data)
			.then(() => {
				dispatch(getList());
			});
	}
}

function switchShareCollection (options) {
	return async (dispatch, getState) => {
		try {
			await collectionApi.switchShareCollection(options.hashname);
			dispatch(getList());
		}
		catch (error) {
			throw error;
		}
	};
}

function resetAnnotations (options) {
	return async (dispatch, getState) => {
		try {
			await collectionApi.resetAnnotations(options.hashname);
			dispatch(getList());
		}
		catch (error) {
			throw error;
		}
	};
}

/**
 * @param {Object} options
 * @param {string} options.collectionId
 * @param {number} options.examinationId
 * @param {boolean} options.value
 * @return {(function(function, function: StoreState): void)|*}
 */
function markReanalyzeRequired (options) {
	return async (dispatch, getState) => {
		const storeState = getState();
		const collection = collectionsSelectors.selectCollectionById(storeState, { id: options.collectionId });

		if ( options.value ) {
			await collectionApi.markReAnalyze(collection.hashname, options.examinationId);
		}
		else {
			await collectionApi.unmarkReAnalyze(collection.hashname, options.examinationId);
		}

		const nextCollection = {
			...collection,
			images: collection.images.map((image) => {
				if ( image.examination_id !== options.examinationId ) {
					return image;
				}

				return {
					...image,
					reanalyze_required: options.value,
				};
			}),
		}

		return dispatch(storeUtils.makeAction(collectionsActionTypes.ACTION_COLLECTIONS__UPDATE_COLLECTION, {
			collectionId: collection.hashname,
			collection: nextCollection,
		}));
	};
}

export default {
	getList,
	getCollection,
	addCollection,
	editCollection,
	removeCollection,
	setCollectionAsDefault,
	setCurrentCollection,
	addImageToCurrentCollection,
	setCollections,
	copyCollection,
	switchShareCollection,
	resetAnnotations,
	markReanalyzeRequired,
	saveCollectionMetaQueue,
};
