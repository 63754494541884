import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import teethUtils from '../../../../appUtils/teeth/teethUtils';
import labelsUtils from '../../../../appUtils/labelsUtils';
import { getLocalizedSurface, isSequentialModeEnabledForVisit } from '../../utils';
import { trackEvent } from '../../../../integrations/mixpanel';

import PopupDialog, { PopupDialogContentText } from '../../../../components/PopupDialog';
import Button from '../../../../components/Button';
import FormLabel from '../../../../components/FormLabel';
import FormSelect from '../../../../components/FormSelect';
import Alignment from '../../../../components/Alignment';
import ResolveAddLabelDate from './ResolveAddLabelDate';
import SurfaceEditor from '../surface-editor';

import { addLabel } from '../../actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import userSelectors from '../../../../selectors/userSelectors';
import imagesLabelsSelectors from '../../../labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../../labels/selectors/labelsSelectors';
import labelsTagsSelectors from '../../../label-tags/selectors/labelsTagsSelectors';
import labelTagGetter from '../../../label-tags/selectors/labelTagGetter';
import labelTagsSelectors from '../../../label-tags/selectors/labelTagsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';

import labelChildrenSelectors from '../../../labels/selectors/labelChildrenSelectors';
import labelGetters from '../../../labels/selectors/labelGetters';

import { message } from '../../../../services/popup';
import { getDictionary } from '../../../../appUtils/locale';

import './styles/ResolveAddLabel.css';
import { getFindingCategory } from '../../utils/findings-filter';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'resolve-add-label';
const settingCssClassName = `${baseCssClassName}__setting`;
const settingLabelCssClassName = `${settingCssClassName}-label`;
const dateCssClassName = `${baseCssClassName}__date`;
const surfacesCssClassName = `${baseCssClassName}__surfaces`;


class ResolveAddLabel extends Component {
	static propTypes = {
		teeth: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		emptyTeeth: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		allTeeth: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		availableLabels: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
		onAddDiagnosis: PropTypes.func,
		onClose: PropTypes.func,
	}
	
	constructor (props, context) {
		super(props, context);
		
		this._surfaceEl = null;
		
		this.state = {
			toothKey: '-1',
			classId:  this.props.availableLabels.length > 0 ? this.props.availableLabels[0].classId : '-1',
			params: '-1',
			surfaces: [],
			date: null,
			isSurfaceEditorOpened: false,
		};
	}
	
	_handleToothChange = (event) => {
		this.setState({
			toothKey: event.target.value,
		});
	};
	
	_handleLabelChange = (event) => {
		const classId = event.target.value;
		const nextState = {
			classId,
		};
		
		if ( !labelsUtils.isSurfaceRequiredForClass(classId) ) {
			nextState.surfaces = [];
		}
		
		this.setState(nextState);
	};
	
	_handleParamsChange = (event) => {
		this.setState({
			params: event.target.value,
		});
	};
	
	_handleDateChange = (date) => {
		this.setState({
			date,
		});
	};
	
	_handleAddLabel = () => {
		const data = {
			toothKey: this.state.toothKey !== '-1' ? this.state.toothKey : null,
			classId: this.state.classId,
			params: this.state.params !== '-1' ? [ this.state.params ] : null,
			surfaces: this.state.surfaces,
			date: this.state.date && this.state.date.length > 0 ? this.state.date : null,
			source: 'manual',
		};

		if ( !data.classId ) {
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: 'Please select a finding',
			});
			return;
		}

		if ( !data.toothKey && labelsUtils.isToothRequiredForClass(data.classId) ) {
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: 'Please select a tooth',
			});
			return;
		}

		if ( !data.date && labelsUtils.isDateRequiredForClass(data.classId) ) {
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: 'Please select a date',
			});
			return;
		}

		if ( !data.surfaces || data.surfaces.length === 0 && labelsUtils.isSurfaceRequiredForClass(data.classId) ) {
			message({
				title: i18nShared('error.title'),
				titleIcon: 'error',
				message: 'Please select a surface',
			});
			return;
		}

		trackEvent('Findings added');

		this.props.onAddDiagnosis(data);
		this.props.onClose();
	};

	_handleClose = () => {
		trackEvent('Add findings dialog closed');
		this.props.onClose();
	};
	
	_handleSurfaceChange = (surfaces) => {
		this.setState({
			surfaces,
		});
	};
	
	_handleSurfaceClose = () => {
		this.setState({
			isSurfaceEditorOpened: false,
		});
	};
	
	_renderSurfaces () {
		const surfaces = this.state.surfaces;
		return (
			<div
				className={surfacesCssClassName}
				onClick={(event) => {
					this._surfaceEl = event.currentTarget;
					this.setState({
						isSurfaceEditorOpened: true,
					});
				}}
			>
				{this.state.toothKey > 0 && surfaces && surfaces.length > 0
					? (
						<span style={{ textTransform: 'uppercase' }}>
							{surfaces.map((surface) => getLocalizedSurface(surface, this.state.toothKey)).join(', ')}
						</span>
					)
					: '-'}
			</div>
		);
	}
	
	_renderSurface () {
		if ( !this.state.isSurfaceEditorOpened || this.state.toothKey < 0) {
			return null;
		}

		return (
			<SurfaceEditor
				target={this._surfaceEl}
				toothKey={this.state.toothKey}
				values={this.state.surfaces}
				onChange={this._handleSurfaceChange}
				onClose={this._handleSurfaceClose}
			/>
		);
	}
	
	render () {
		const params = labelsUtils.getParamsByClassId(this.state.classId);

		return (
			<PopupDialog
				headerProps={{
					title: 'Add new findings',
				}}
				content={(
					<PopupDialogContentText>
						<div className={baseCssClassName}>
							<div className={settingCssClassName}>
								<FormLabel
									element={
										(
											<FormSelect
												value={this.state.classId}
												items={this.props.availableLabels.map((labelData) => ({
													value: labelData.classId,
													label: labelData.localizedClassName,
												}))}
												block
												onChange={this._handleLabelChange}
											/>
										)
									}
									text={'Findings'}
									textPosition={FormLabel.textPosition.TOP}
								/>
							</div>
							<div className={settingCssClassName}>
								<FormLabel
									element={
										(
											<FormSelect
												value={this.state.toothKey}
												items={
													[
														{
															value: '-1',
															label: '',
														},
													].concat(
														(this.state.classId === 'tooth'
															? this.props.emptyTeeth
															: this.state.classId === 'missing_tooth' ? this.props.allTeeth : this.props.teeth
														).map((tooth) => ({
															value: tooth.toothKey,
															label: tooth.localizedToothKey,
														}))
													)
												}
												block
												onChange={this._handleToothChange}
											/>
										)
									}
									text={'Tooth'}
									textPosition={FormLabel.textPosition.TOP}
								/>
							</div>
							{params && params.length > 0 && (
								<div className={settingCssClassName}>
									<FormLabel
										element={
											(
												<FormSelect
													value={String(this.state.params)}
													items={[
														{
															value: '-1',
															label: '',
														},
													].concat(params.map((param) => ({
														value: String(param.name),
														label: param.readable_name,
													})))}
													block
													onChange={this._handleParamsChange}
												/>
											)
										}
										text={'Parameters'}
										textPosition={FormLabel.textPosition.TOP}
									/>
								</div>
							)}
							{labelsUtils.isSurfaceRequiredForClass(this.state.classId) && (
								<div className={`${settingCssClassName} ${settingCssClassName}__m-surfaces`}>
									<div className={settingLabelCssClassName}>Surface</div>
									{this._renderSurfaces()}
								</div>
							)}
							{labelsUtils.isDateRequiredForClass(this.state.classId) && (
								<div className={settingCssClassName}>
									<FormLabel
										element={
											(
												<div className={dateCssClassName}>
													<ResolveAddLabelDate
														date={this.state.date}
														onChange={this._handleDateChange}
													/>
												</div>
											)
										}
										text={'Date'}
										textPosition={FormLabel.textPosition.TOP}
									/>
								</div>
							)}
						</div>
						{this._renderSurface()}
					</PopupDialogContentText>
				)}
				footerProps={{
					buttons: () => {
						return [
							(
								<Button
									key={'popup_add'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={this._handleAddLabel}
								>{'Add'}</Button>
							),
							(
								<Button
									key={'popup_close_button'}
									theme={Button.AVAILABLE_THEMES.SECONDARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={this._handleClose}
								>{i18nShared('buttons.cancel')}</Button>
							),
						];
					}
				}}
				popupProps={{
					alignmentProps: {
						horizontal: Alignment.horizontal.RIGHT,
						vertical: Alignment.vertical.BOTTOM,
					},
					overlay: {
						closeOnClick: false,
					},
					onClose: this.props.onClose,
				}}
				style={{
					marginRight: 171,
					marginBottom: 74,
				}}
			/>
		);
	}
}


export default withRouter(connect(
	(state, props) => {
		const currentImageId = editorSelectors.selectCurrentImageId(state);
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const labelChildren = labelChildrenSelectors.selectLabelChildren(state);
		const user = userSelectors.selectUserData(state);
		const notationType = user.notation_type;
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;

		const teeth = imagesLabelsSelectors.selectImageLabelsByImageId(state, {
			imageId: currentImageId,
		}).reduce((result, labelId) => {
			const label = labelsSelectors.selectLabelById(state, {
				labelId,
			});
			
			if ( labelsUtils.labelIsTooth(label) ) {
				const tags = labelsTagsSelectors.selectLabelTagsByLabelId(state, { labelId: labelId });
				if ( tags.length > 0 ) {
					const toothKey = labelTagGetter.getTagKey(labelTagsSelectors.selectLabelTagById(state, { tagId: tags[0] }));
					
					let isMissingTooth = false;
					
					if ( labelChildren[labelId] ) {
						labelChildren[labelId].forEach((childLabelId) => {
							const childLabel = labelsSelectors.selectLabelById(state, {
								labelId: childLabelId,
							});
							
							if ( labelGetters.getLabelClassId(childLabel) === 'missing_tooth' ) {
								isMissingTooth = true;
							}
						});
					}
					
					if ( !isMissingTooth ) {
						result.flat.push(toothKey);
						result.full.push({
							toothKey,
							localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
						});
					}
				}
			}
			
			return result;
		}, { flat: [], full: [] });
		
		
		teeth.full.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		const emptyTeeth = teethUtils.getTeeth().reduce((result, toothKey) => {
			if ( !teeth.flat.includes(toothKey) ) {
				result.push({
					toothKey,
					localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
				});
			}

			return result;
		}, []);

		emptyTeeth.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey));

		const ifTfvModeActive = (
			typeof lodashGet(props, 'match.params.visit') === 'string' &&
			typeof lodashGet(props, 'match.params.tooth') === 'string'
		);

		const labelsFilterFn = isSequentialModeEnabled === true
			? (labelData) => (labelsUtils.getLabelAvailabilityOptions(labelData.classId).findings_group !== 'pathological')
			: ifTfvModeActive
				? (labelData) => labelData.classId !== 'tooth'
				: () => true

		return {
			teeth: teeth.full,
			emptyTeeth,
			allTeeth: teethUtils.getTeeth().reduce((result, toothKey) => {
				result.push({
					toothKey,
					localizedToothKey: teethUtils.getLocalizedToothKey({ toothKey, notationType }),
				});

				return result;
			}, [])
				.sort((a, b) => teethUtils.sortTeeth(a.localizedToothKey, b.localizedToothKey)),
			availableLabels: labelsSelectors.selectAvailableLabelsByImageType(state, {
				type: currentImage.image_type,
			})
				.filter((labelData) => {
					const options = labelsUtils.getLabelAvailabilityOptions(labelData.classId);
					if ( options && options.findings_group === 'tissue' ) {
						return false;
					}
					return labelsFilterFn;
				})

				.map((labelData) => ({
					...labelData,
					localizedClassName: labelsUtils.getLocalizedClassByClassId(labelData.classId),
				})),
		};
	},
	(dispatch) => ({
		onAddDiagnosis: (data) => dispatch(addLabel(data)),
	})
)(ResolveAddLabel));

