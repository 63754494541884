import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import teethUtils from '../../../../appUtils/teeth/teethUtils';
import { getToothSvg } from './reportUtils';


const baseCssClassName = 'resolver-report-dental-chart';
const teethRowKeysCssClassName = `${baseCssClassName}__row-keys`;
const teethRowKeysKeyCssClassName = `${baseCssClassName}__row-keys-key`;
const teethRowCssClassName = `${baseCssClassName}__row`;
const toothCssClassName = `${baseCssClassName}__tooth`;


export default class ResolverReportDentalChart extends PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		teeth: PropTypes.object.isRequired,
		labelColorFilterFn: PropTypes.func.isRequired,
	};

	render () {
		const teeth = this.props.teeth;

		return (
			<div className={baseCssClassName}>
				<div className={`${teethRowKeysCssClassName} ${teethRowKeysCssClassName}__m-top`}>
					{teethUtils.getOrderedTopTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={teethRowKeysKeyCssClassName}
						>{teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type })}</div>
					))}
				</div>

				<div className={`${teethRowCssClassName} ${teethRowCssClassName}__m-top`}>
					{teethUtils.getOrderedTopTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={`${toothCssClassName} ${toothCssClassName}__m-key-${toothKey}`}
							dangerouslySetInnerHTML={{
								__html: getToothSvg({
									number: toothKey,
									toothData: teeth[toothKey],
									labelColorFilterFn: this.props.labelColorFilterFn,
								}),
							}}
						/>
					))}
				</div>
				<div className={`${teethRowCssClassName} ${teethRowCssClassName}__m-bottom`}>
					{teethUtils.getOrderedBottomTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={`${toothCssClassName} ${toothCssClassName}__m-key-${toothKey}`}
							dangerouslySetInnerHTML={{
								__html: getToothSvg({
									number: toothKey,
									toothData: teeth[toothKey],
									labelColorFilterFn: this.props.labelColorFilterFn,
								})
							}}
						/>
					))}
				</div>
				<div className={`${teethRowKeysCssClassName} ${teethRowKeysCssClassName}__m-bottom`}>
					{teethUtils.getOrderedBottomTeeth().map((toothKey) => (
						<div
							key={toothKey}
							className={teethRowKeysKeyCssClassName}
						>{teethUtils.getLocalizedToothKey({ toothKey, notationType: this.props.user.notation_type })}</div>
					))}
				</div>
			</div>
		);
	}
}
