import api from './instance';
import cookiesUtils from '../appUtils/cookiesUtils';
import apiUtils from '../appUtils/apiUtils';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';

let cachedToken = null;

function signIn (credentials) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth.signIn);

	return api[apiParams.method](apiParams.url, credentials);
}

function signOut () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth.signOut);

	return api[apiParams.method](apiParams.url);
}

function getProfile () {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth['profile/read']);
	return api[apiParams.method](apiParams.url);
}

function updateProfile (data = {}) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth['profile/write']);
	return api[apiParams.method](apiParams.url, data);
}

function setToken (token) {
	cookiesUtils.write(getRuntimeConfig().auth_token_cookie_name, token, Date.now() + ( 86400000 * 365 ), '/', getAuthTokenDomainName(), true, 'None');
	api.setAuthToken(token);
}

function getAuthToken () {
	const cookieToken = cookiesUtils.read(getRuntimeConfig().auth_token_cookie_name);
	if (cookieToken) {
		cachedToken = cookieToken;
		return cookieToken;
	}
	const paramsString = window.location.search || window.location.hash.split('?').pop();
	const urlParams = new URLSearchParams(paramsString);
	let external = urlParams.get('external_token');
	if (external) {
		cachedToken = external;
		return external
	}
	return cachedToken || null;
}

function removeToken () {
	cookiesUtils.write(getRuntimeConfig().auth_token_cookie_name, '', Date.now() - 86400000, '/', getAuthTokenDomainName(), true, 'None');
}

function getAuthTokenDomainName () {
	return ( getRuntimeConfig().auth_token_domain_name || null );
}

/**
 * @param {string} token JWT token.
 * @param {string} password New password.
 * @returns {Promise}
 */
function changePassword (token, password) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth['password/change']);

	return api[apiParams.method](apiParams.url, { token, password });
}

/**
 * @param {string} email JWT token.
 * @returns {Promise}
 */
function resetPassword (email) {
	const apiParams = apiUtils.getApiParams(getRuntimeConfig().api.urls.auth['password/reset']);

	return api[apiParams.method](apiParams.url, { email });
}

export default {
	signIn,
	signOut,
	getProfile,
	updateProfile,
	setToken,
	getAuthToken,
	removeToken,
	changePassword,
	resetPassword,
};
