import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mainConfig from '../../../../configs/mainConfig';

import { USER_PERMISSION__TISSUE_PREDICTION } from '../../../../constants/userPermissionsConstants';
import aclService from '../../../../services/acl';

import userSelectors from '../../../../selectors/userSelectors';

import { Toolbar } from '../../../../components/Toolbar';

import './styles/TfvToolbar.css';


const baseCssClassName = 'tfv-toolbar';
const toolbarCssClassName = `${baseCssClassName}__toolbar`;


class TfvToolbar extends PureComponent {
	static propTypes = {
		areFindingsMasksFromProfileEnabled: PropTypes.bool.isRequired,
		areHeatMapsFromProfileEnabled: PropTypes.bool.isRequired,
	};

	_toolbarItems = null;

	_getToolbarConfig () {
		if ( null !== this._toolbarItems ) {
			return this._toolbarItems;
		}

		this._toolbarItems = mainConfig.TOOLBAR_ORDER.filter((item) => {
			if (
				item === mainConfig.TOOLBAR_ITEM__FLIP_HORIZONTAL ||
				item === mainConfig.TOOLBAR_ITEM__FLIP_VERTICAL ||
				item === mainConfig.TOOLBAR_ITEM__ROTATE_LEFT ||
				item === mainConfig.TOOLBAR_ITEM__ROTATE_RIGHT
			) {
				return false;
			}

			if ( item === mainConfig.TOOLBAR_ITEM__SHOW_TISSUE_FINDINGS ) {
				return aclService.checkPermission(USER_PERMISSION__TISSUE_PREDICTION);
			}

			return (
				item !== mainConfig.TOOLBAR_ITEM__DRAW_MODE &&
				item !== mainConfig.TOOLBAR_ITEM__GRID &&
				item !== mainConfig.TOOLBAR_ITEM__BONE_LEVELS &&
				item !== mainConfig.TOOLBAR_ITEM__HIDE_FINDINGS &&
				item !== mainConfig.TOOLBAR_ITEM__MAGNIFYING_GLASS &&
				item !== mainConfig.TOOLBAR_ITEM__HEAT_MAP &&
				item !== mainConfig.TOOLBAR_ITEM__FINDINGS_MASKS
			);
		});

		return this._toolbarItems;
	}

	render () {
		return (
			<div className={baseCssClassName}>
				<div className={toolbarCssClassName}>
					<Toolbar items={this._getToolbarConfig()} />
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		const areHeatMapsFromProfileEnabled = userSelectors.selectAreHeatMapsEnabled(state);

		return {
			areFindingsMasksFromProfileEnabled: areHeatMapsFromProfileEnabled === false && userSelectors.selectAreFindingsMasksEnabled(state),
			areHeatMapsFromProfileEnabled,
		};
	},
	() => ({})
)(TfvToolbar);
