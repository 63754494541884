import labelGetters from '../../labels/selectors/labelGetters';
import { isBonelossLine, checkBoneLossLine } from '../../../appUtils/bonelossUtils';
import labelsUtils from '../../../appUtils/labelsUtils';


export function getFindingCategory ({ label }) {
	const classId = labelGetters.getLabelClassId(label);
	const source = labelGetters.getLabelSource(label);
	const availabilityOptions = labelsUtils.getLabelAvailabilityOptions(classId);

	if (
		(
			source === 'ai' ||
			source === 'manual'
		) &&
		availabilityOptions.findings_group === 'pathological'
	) {
		return 'pathological';
	}
	else if (
		(
			source === 'ai' ||
			source === 'manual'
		) &&
		(
			availabilityOptions.findings_group === 'pre-existing'
		)
	) {
		return 'treatment';
	}
	else if (
		(labelGetters.getLabelSource(label) !== 'ai' && labelGetters.getLabelSource(label) !== 'manual')
	) {
		return 'existing';
	}

	return availabilityOptions.findings_group;
}

export function checkFindingsFilter ({ findingsFilter, label, showBoneLossStages, allowBoneLossLines }) {
	const classId = labelGetters.getLabelClassId(label);
	const category = getFindingCategory({ label });

	if ( isBonelossLine(classId) === true ) {
		return checkBoneLossLine({ findingsFilter, label, showBoneLossStages, allowBoneLossLines });
	}
	else if ( category === 'pathological' ) {
		return findingsFilter['pathological'] === true;
	}
	else if ( category === 'treatment' ) {
		return findingsFilter['treatment'] === true;
	}
	else if ( category === 'existing' ) {
		return findingsFilter['existing'] === true;
	}
	else {
		return true;
	}
}

export function isExtraFinding ({ label }) {
	return (labelGetters.getLabelClassId(label) === 'no_bone_loss_line');
}
