import React, { Component } from 'react';
import PropTypes from 'prop-types';

import lodashGet from 'lodash/get';
import authApi from '../../api/authApi';

import { getStorageService } from '../../services/storage';


// http://localhost:3000/external_auth/3bf99ed256de5a285feb33348068cb04312fd2b1/http%3A%2F%2Flocalhost:3000%2Fcollections%2F0235756e-751d-442a-9a55-fa3a405dcb47%2Fimage%2F860-f2f-6e1%2Ffmx/?dtr_provider%3D1test%26dtr_user%3D2test%26dtr_operatory%3D3test

export default class ExternalAuth extends Component {
	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	}

	_setParams () {
		const parsedUrl = new window.URL(decodeURIComponent(window.location.href));
		if ( parsedUrl.search.length > 0 ) {
			const params = parsedUrl.search.substr(1).split('&');
			const apiParams = params.reduce((result, str) => {
				if ( str.indexOf('dtr') !== 0 ) {
					return result;
				}

				const [ name, value ] = str.split('=');
				result[name] = value;
				return result;
			}, {});

			if ( Object.keys(apiParams).length > 0 ) {
				getStorageService().set('user_api_params', apiParams);
				getStorageService().remove('mixpanel_dtr_params_event');
			}
		}
	}

	componentWillMount () {
		const token = lodashGet(this.context.router, 'route.match.params.token');
		const returnUrl = lodashGet(this.context.router, 'route.match.params.returnUrl');

		const currentToken = authApi.getAuthToken();

		if ( currentToken && currentToken === token ) {
			this._setParams();
			this._redirect(returnUrl);
			return;
		}

		if ( token ) {
			authApi.setToken(token);
		}

		this._setParams();

		if ( returnUrl ) {
			this._redirect(returnUrl);
		}
	}

	_redirect (returnUrl) {
		if ( returnUrl ) {
			window.location.href = decodeURIComponent(returnUrl);
		}
		else {
			window.location.href = '/';
		}
	}

	render () {
		return null;
	}
}
