import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Route, withRouter } from 'react-router-dom';

import aclService from '../../services/acl';
import { events } from '../../services/events';
import { trackEvent } from '../../integrations/mixpanel';

import editorActions from '../../actions/editorActions';
import editorSelectors from '../../selectors/editorSelectors';
import currentCollectionSelectors from '../../selectors/currentCollectionSelectors';

import Logo from '../Logo';
import ImageControls from '../ImageControls';
import UserInfo from '../UserInfo';
import RoundedButton from '../RoundedButton';
import Messenger from '../Messenger';

import Button from '../Button';

import EditCollection from '../EditCollection';
import CollectionControls from '../CollectionControls';
import { DrawSecondaryBoxMode } from '../DisableLayout';
import {
	USER_PERMISSION__ADD_COLLECTION,
	USER_PERMISSION__VIEW_OVERALL_STATS,
	USER_PERMISSION__VIEW_COLLECTION,
	USER_PERMISSION__LIST_COLLECTIONS,
} from '../../constants/userPermissionsConstants';
import TextField from '@material-ui/core/TextField';
import { getDictionary } from '../../appUtils/locale';

import './styles/Header.css';


const i18nStats = getDictionary('stats');
const i18nImageControls = getDictionary('image-controls');
const i18nCollections = getDictionary('collections');


const baseCssClassName = 'header';
const buttonCssClassName = `${baseCssClassName}__button`;
const imageControlsCssClassName = `${baseCssClassName}__image-controls`;
const collectionControlsCssClassName = `${baseCssClassName}__collection-controls`;
const statsControlsCssClassName = `${baseCssClassName}__stats-controls`;
const imageModeControlsCssClassName = `${baseCssClassName}__image-mode-controls`;
const helpCssClassName = `${baseCssClassName}__help`;
const userInfoCssClassName = `${baseCssClassName}__user-info`;
const versionCssClassName = `${baseCssClassName}__version`;
const collectionSearchCssClassName = `${baseCssClassName}-collection-search`;
const collectionSearchInputWrapperCssClassName = `${collectionSearchCssClassName}__input-wrapper`;
const collectionSearchInputCssClassName = `${collectionSearchCssClassName}__input`;
const collectionSearchButtonWrapperCssClassName = `${collectionSearchCssClassName}__button-wrapper`;
const collectionSearchButtonCssClassName = `${collectionSearchCssClassName}__button`;

const propTypes = {
	currentImageId: PropTypes.string,
};

const Header = ({
	currentCollectionId,
	currentImageId,
	collectionSearch,
	isCollectionsPage,
	isStatsPage,
	onSetCollectionSearch,
	onSearch,
}) => {
	const showCollectionControls = (
		isCollectionsPage &&
		(
			aclService.checkPermission(USER_PERMISSION__ADD_COLLECTION) ||
			aclService.checkPermission(USER_PERMISSION__VIEW_OVERALL_STATS)
		)
	);

	const showSearch = (isCollectionsPage && aclService.checkPermission(USER_PERMISSION__VIEW_COLLECTION));

	const handleStatsClick = React.useCallback(() => {
		trackEvent('Morning huddle button click');
	}, []);

	const handleCollectionsClick = React.useCallback(() => {
		trackEvent('Collections button click');
	}, []);

	return (
		<div className={baseCssClassName}>
			<div className='header__smth'>
				{aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
					<Link to={'/'} >
						<Logo />
					</Link>
				)}
				{!aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
					<Logo />
				)}
			</div>
			{
				(showCollectionControls || showSearch )&&
				<div className={collectionControlsCssClassName}>
					{showCollectionControls && (
						<div className='header__edit-collection'>
							<div className={buttonCssClassName}>
								{aclService.checkPermission(USER_PERMISSION__ADD_COLLECTION) && (<EditCollection />)}
							</div>
							{aclService.checkPermission(USER_PERMISSION__VIEW_OVERALL_STATS) && (
								<Link to={{ pathname: '/huddle' }} onClick={handleStatsClick}>
									<div className={buttonCssClassName}>
										<RoundedButton
											theme={RoundedButton.themes.secondary}
											type={'stats'}
											title={'Huddle'}
										/>
									</div>
								</Link>
							)}
						</div>
					)}
					{showSearch && (
						<div>
							<form className={collectionSearchCssClassName} onSubmit={onSearch}>
								<div className={collectionSearchInputWrapperCssClassName}>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											classes: {
												input: collectionSearchInputCssClassName,
											},
										}}
										name={'search'}
										value={collectionSearch}
										placeholder={i18nCollections('search.placeholder')}
										margin={'dense'}
										fullWidth
										onChange={onSetCollectionSearch}
									/>
								</div>
								<div className={collectionSearchButtonWrapperCssClassName}>
									<Button
										size={Button.AVAILABLE_SIZES.SMALL}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										className={collectionSearchButtonCssClassName}
										type={Button.AVAILABLE_TYPES.SUBMIT}
									>{i18nCollections('search.button')}</Button>
								</div>
							</form>
						</div>
					)}
				</div>
			}
			{
				isStatsPage && aclService.checkPermission(USER_PERMISSION__LIST_COLLECTIONS) && (
					<div className={statsControlsCssClassName}>
						<Link to={{ pathname: '/' }} onClick={handleCollectionsClick}>
							<div className={buttonCssClassName}>
								<RoundedButton
									theme={RoundedButton.themes.secondary}
									type={'collections'}
									title={i18nImageControls('buttons.collections')}
								/>
							</div>
						</Link>
					</div>
				)
			}
			{currentCollectionId &&
				<div className={collectionControlsCssClassName}>
					<Route path={`/collections/:id`} exact component={CollectionControls} />
				</div>
			}
			{currentImageId &&
				<React.Fragment>
					<div className={imageControlsCssClassName}>
						<Route path={`/collections/:id/image/:imageId`} component={ImageControls} />
					</div>
					<div className={imageModeControlsCssClassName} />
				</React.Fragment>
			}
			<div className={helpCssClassName}>
				<Messenger />
			</div>
			<div className={userInfoCssClassName}>
				<UserInfo />
			</div>
			<DrawSecondaryBoxMode />
		</div>
	);
}

Header.propTypes = propTypes;

export default withRouter(connect((state) => {
	const editorData = editorSelectors.selectEditor(state);
	return {
		currentImageId: editorSelectors.selectCurrentImageId(state),
		currentCollectionId: editorData.currentCollectionHashName,
		collectionSearch: editorData.collectionSearch,
	};
}, (dispatch) => ({
	onSetCollectionSearch: (event) => dispatch(editorActions.setCollectionSearch(event.currentTarget.value)),
	onSearch: (event) => {
		event.preventDefault();

		trackEvent('Collection search');

		events.emit('collection-button-click');
	},
}))(Header));
