import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getRuntimeConfig } from '../../appUtils/runtimeConfig';

import Button from '../Button';
import editorSelectors from '../../selectors/editorSelectors';

import { getDictionary } from '../../appUtils/locale';
import aclService from '../../services/acl';
import { get as getHelp } from '../../services/help';
import { tryToHandleApiError } from '../../appUtils/errorUtils';
import { events } from '../../services/events';
import { message } from '../../services/popup';
import { createController } from '../../services/popup';

import analyseActions from '../../actions/analyseActions';
import editorActions from '../../actions/editorActions';
import labelsActions from '../../modules/labels/actions/labelsActions';
import imagesActions from '../../actions/imagesActions';
import userActions from '../../actions/userActions';

import {
  USER_PERMISSION__USE_ANALYSIS,
  USER_PERMISSION__VIEW_REPORTS
} from '../../constants/userPermissionsConstants';

import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__XRAY,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__PERIAPICAL,
} from '../../constants/imageConstants';

import analyseSelectors from '../../selectors/analyseSelectors';
import userSelectors from '../../selectors/userSelectors';
import imagesSelectors from '../../selectors/imagesSelectors';
import imagesLabelsSelectors from '../../modules/labels/selectors/imagesLabelsSelectors';
import labelsSelectors from '../../modules/labels/selectors/labelsSelectors';
import labelGetters from '../../modules/labels/selectors/labelGetters';

import PopupDialogConfirm from '../PopupDialogConfirm';
import PathologiesFindingConfirm from './PathologiesFindingConfirm';

import './styles/Sidebar.css';


const i18nShared = getDictionary('shared');

const baseCssClassName = 'sidebar';
const footerCssClassName = `${baseCssClassName}__footer`;
const footerControlsCssClassName = `${baseCssClassName}__footer-controls`;
const footerControlsButtonCssClassName = `${baseCssClassName}__footer-controls-button`;

class SidebarFooter extends PureComponent {
	static propTypes = {
		isOwnImage: PropTypes.bool.isRequired,
		isImageInAnalysis: PropTypes.bool.isRequired,
		showReportButton: PropTypes.bool.isRequired,
		hasUnconfirmedFindings: PropTypes.bool.isRequired,
		isImageAnalyzed: PropTypes.bool.isRequired,
		hasUnsavedChanges: PropTypes.bool.isRequired,
		isFDAAnnotationEnabled: PropTypes.bool.isRequired,
		isAnalysisAvailable: PropTypes.bool.isRequired,
		analysisKeepManualLabels: PropTypes.bool.isRequired,
		isReportAvailable: PropTypes.bool.isRequired,
		isEnableUiPathologiesButtonAvailable: PropTypes.bool.isRequired,
		isAnalyzedOnce: PropTypes.bool.isRequired,
		shouldHighlightExportButton: PropTypes.bool.isRequired,
		canReanalyze: PropTypes.bool.isRequired,
		onConfirmAllLabels: PropTypes.func.isRequired,
		onSaveData: PropTypes.func.isRequired,
		onApplyImageAnalysis: PropTypes.func.isRequired,
		onAnalyzeImage: PropTypes.func.isRequired,
		onEnableAiPathologies: PropTypes.func.isRequired,
		onEnableAiNotification: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);
		
		this.state = {
			shouldShowConfirmLabelsDialog: false,
		};
	}
	
	componentWillUnmount () {
		this.state = null;
		
		events.removeListener('labels.saved', this._handleEventsSaved);
	}
	
	_handleReportButtonClick = async () => {
		try {
			if ( this.props.hasUnsavedChanges ) {
				await this.props.onSaveData();
			}
			
			if ( this.props.isOwnImage && this.props.hasUnconfirmedFindings ) {
				this.setState({
					shouldShowConfirmLabelsDialog: true,
				});
				return;
			}
			
			window.open(this.props.reportImageUrl, '_blank');
		}
		catch (error) {
			tryToHandleApiError(error);
		}
	};
	
	_handleAnalyzeClick = () => {
		if ( this.props.isImageAnalyzed ) {
			this.props.onApplyImageAnalysis();
		}
		else {
			const help = getHelp();
			if ( help !== null ) {
				help.sendAnalyzedEvent(window.location.href);
			}
			const args = {};
			if ( this.props.analysisKeepManualLabels === true ) {
				args.params = {
					keep_manual_labels: true,
				};
			}
			this.props.onAnalyzeImage(args)
				.catch(error => {
					tryToHandleApiError(error);
				});
		}
	};
	
	_handleConfirmFindings = async () => {
		try {
			events.on('labels.saved', this._handleEventsSaved);
			
			await this.props.onConfirmAllLabels();
		}
		catch (error) {
			// do nothing
		}
	};
	
	_handleConfirmFindingsClose = () => {
		this.setState({
			shouldShowConfirmLabelsDialog: false,
		});
		window.open(this.props.reportImageUrl, '_blank');
	};
	
	_handleEventsSaved = () => {
		events.removeListener('labels.saved', this._handleEventsSaved);
		this.setState({
			shouldShowConfirmLabelsDialog: false,
		});
		window.open(this.props.reportImageUrl, '_blank');
	};

	_handleSetAiPathologiesEnabled = () => {
		this.props.onEnableAiPathologies();
		this.props.onEnableAiNotification();
	};
	
	render() {
		if ( this.props.isAnalysisAvailable === false && this.props.isReportAvailable === false && this.props.isEnableUiPathologiesButtonAvailable === false ) {
			return null;
		}

		return (
			<div className={footerCssClassName}>
				<div className={footerControlsCssClassName}>
					{this.props.isEnableUiPathologiesButtonAvailable && (
						<div className={footerControlsButtonCssClassName}>
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								uppercase
								block
								disabled={this.props.isImageInAnalysis }
								title={'Show AI findings'}
								onClick={this._handleSetAiPathologiesEnabled}
							>
								Show AI findings
							</Button>
						</div>
					)}
					{this.props.isAnalysisAvailable && (
						<div className={footerControlsButtonCssClassName}>
							<Button
								theme={Button.AVAILABLE_THEMES.PRIMARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								uppercase
								block
								disabled={this.props.isImageInAnalysis }
								title={this.props.isAnalyzedOnce === true ? 'Re-analyze' : i18nShared('buttons.analyze')}
								isPulsing={this.props.shouldHighlightExportButton}
								onClick={this._handleAnalyzeClick}
							>
								{(this.props.isAnalyzedOnce === true || this.props.canReanalyze === true) ? 'Re-analyze' : i18nShared('buttons.analyze')}
							</Button>
						</div>
					)}
					{this.props.isReportAvailable && (
						<div className={footerControlsButtonCssClassName}>
							<Button
								theme={Button.AVAILABLE_THEMES.SECONDARY}
								size={Button.AVAILABLE_SIZES.LARGE}
								uppercase
								block
								title={i18nShared('buttons.report')}
								disabled={this.props.isImageInAnalysis || this.props.isImageAnalyzed || this.props.showReportButton === false}
								onClick={this._handleReportButtonClick}
							>
								{i18nShared('buttons.report')}
							</Button>
						</div>
					)}
				</div>
				{this.state.shouldShowConfirmLabelsDialog && (
					<PopupDialogConfirm
						title={'Findings review'}
						content={(<div>
							You have unreviewed findings.<br />Do you want to confirm all remaining?
						</div>)}
						onConfirm={this._handleConfirmFindings}
						onCancel={this._handleConfirmFindingsClose}
					/>
				)}
			</div>
		);
	}
}

export default connect((state, props) => {
	const imageId = editorSelectors.selectCurrentImageId(state);
	const autoAnalysis = getRuntimeConfig()['auto-image-analysis'];
	const editorData = editorSelectors.selectEditor(state);
	const currentImage = imagesSelectors.selectImageById(state, {
		id: imageId,
	});
	const isAuthenticated = userSelectors.selectIsAuthenticated(state);
	const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(state);
	const imageType = currentImage.image_type;
	const isCadUser = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
	let hasUnconfirmedFindings = false;
	
	imagesLabelsSelectors.selectImageLabelsByImageId(state, {
		imageId,
	}).forEach((labelId) => {
		const label = labelsSelectors.selectLabelById(state, { labelId, });
		
		if ( !labelGetters.getLabelIsConfirmed(label) ) {
			hasUnconfirmedFindings = true;
		}
	});
	
	return {
		hasUnsavedChanges: editorSelectors.selectEditor(state).hasUnsavedChanges,
		isImageInAnalysis: analyseSelectors.selectIsImageInAnalysis(state, { imageId }),
		isImageAnalyzed: autoAnalysis && analyseSelectors.selectIsImageAnalyzed(state, { imageId }),
		isFDAAnnotationEnabled,
		isAuthenticated,
		hasUnconfirmedFindings,
		isAnalysisAvailable: (
			(
				props.isOwnImage &&
				aclService.checkPermission(USER_PERMISSION__USE_ANALYSIS) &&
				isFDAAnnotationEnabled === false &&
				(
					isCadUser === false ||
					currentImage.is_sequential_viewed === true
				)
			) &&
			(
				imageType === IMAGE_TYPE__PAN ||
				imageType === IMAGE_TYPE__XRAY ||
				imageType === IMAGE_TYPE__BITEWING ||
				imageType === IMAGE_TYPE__PERIAPICAL
			)
		),
		analysisKeepManualLabels: userSelectors.selectUsesComputerAidedDeviceUi(state) === true,
		isReportAvailable: (
			(
				!isAuthenticated || (
					aclService.checkPermission(USER_PERMISSION__VIEW_REPORTS) &&
					userSelectors.selectUsesComputerAidedDeviceUi(state) !== true &&
					isFDAAnnotationEnabled === false
				)
			) &&
			(
				imageType === IMAGE_TYPE__PAN ||
				imageType === IMAGE_TYPE__XRAY ||
				imageType === IMAGE_TYPE__BITEWING ||
				imageType === IMAGE_TYPE__PERIAPICAL
			)
		),
		isAnalyzedOnce: (
			isCadUser === true &&
			currentImage.is_sequential_viewed === true
		),
		isEnableUiPathologiesButtonAvailable: (
			isCadUser === true &&
			currentImage.is_sequential_viewed === false
		),
		shouldHighlightExportButton: (
			editorData.notAnalyzedImages.length > 0 ||
			currentImage.reanalyze_required === true
		),
		canReanalyze: (
			true ||
			editorData.notAnalyzedImages.length > 0 ||
			currentImage.reanalyze_required === true ||
			currentImage.is_analyzed_once === true
		),
	};
}, (dispatch) => ({
	onAnalyzeImage: (params) => dispatch(editorActions.analyzeCurrentImage(params)),
	onApplyImageAnalysis: () => dispatch(analyseActions.applyImageAnalysis()),
	onSaveData: () => dispatch(labelsActions.saveLabels()),
	onConfirmAllLabels: () => dispatch(labelsActions.confirmAllLabels()),
	onEnableAiPathologies: () => {
		return dispatch((dispatch, getState) => {
			const state = getState();
			const user = userSelectors.selectUserData(state);
			const isCadUser = userSelectors.selectUsesComputerAidedDeviceUi(state) === true;
			const doAction = () => {
				dispatch(imagesActions.saveImageData({
					data: {
						is_sequential_viewed: true,
					},
				}))
					.then(() => {
						dispatch(editorActions.updateData({
							data: {
								shouldShowAiNotificationAfter: true,
							},
						}));
					})
					.catch(() => {
						message({
							title: i18nShared('error.title'),
							titleIcon: 'error',
							message: 'An error occurred while saving image data. Please try again',
						});
					});
			};

			const updateProfile = (value) => {
				dispatch(userActions.updateProfile({
					data: {
						cad_ui_show_ai_confirmation_popup: value,
					},
				}));
			};

			if ( isCadUser === true && false ) {
				if ( user.cad_ui_show_ai_confirmation_popup === true ) {
					const confirm = createController(PathologiesFindingConfirm, {
						autoOpen: true,
						props: {
							onCancel: () => {
								confirm.destroy();
							},
							onConfirm: (dontAskValue) => {
								if ( dontAskValue === true ) {
									updateProfile(false);
								}
								doAction();
								confirm.destroy();
							},
						},
					});
					return;
				}
			}

			doAction();
		});
	},
}))(SidebarFooter)

