import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mainConfig from '../../configs/mainConfig';

import { message } from '../../services/popup';

import { trackEvent } from '../../integrations/mixpanel';

import { UploadImageServicePropType } from '../../services/upload-image/types';

import userSelectors from '../../selectors/userSelectors';

import Popup from '../Popup';
import Button from '../Button';
import ImageUploadingForm from '../ImageUploadingForm';
import LinearDeterminate from '../LinearDeterminate/LinearDeterminate';

import { getDictionary } from '../../appUtils/locale';

import './styles/UploadImage.css';


const i18n = getDictionary('image-controls');
const i18nUploading = getDictionary('image-uploading-form');
const i18nShared = getDictionary('shared');


const CONTENT = {
	IDLE: 'idle',
	PROGRESS: 'progress',
	CONFIRM: 'confirm',
};

const baseCssClassName = 'upload-image';
const progressCssClassName = `${baseCssClassName}__progress`;


class UploadImage extends PureComponent {
	static propTypes = {
		UploadService: UploadImageServicePropType.isRequired,
		imagesLimit: PropTypes.number.isRequired,
	};

	state = {
		content: CONTENT.IDLE,
		image: null,
	};

	componentDidMount () {
		this.props.UploadService.getEvents().on('upload-file-requested', this._handleUploadFileRequested);
	}

	componentWillUnmount () {
		this.state = null;

		// this.props.UploadService.getEvents().removeEventListener('upload-file-requested', this._handleUploadFileRequested);
	}

	_handleUploadFileRequested = (uploadFilePromise) => {
		this.setState({
			content: CONTENT.PROGRESS,
		});

		uploadFilePromise
			.then((image) => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					content: CONTENT.CONFIRM,
					image,
				});
			})
			.catch((reason) => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					content: CONTENT.IDLE,
				});

				if ( reason === 'WRONG_EXTENSION' ) {
					message({
						title: i18nUploading('dialog.title'),
						titleIcon: 'error',
						message: (
							<span dangerouslySetInnerHTML={{
								__html: i18nUploading('wrong_extension.dialog.content').replace('{formats}', mainConfig.AVAILABLE_UPLOADING_IMAGE_EXTENSIONS.join(', ')),
							}} />
						),
					});
				}
				else if ( reason === 'LIMIT_EXCEEDED' )  {
					trackEvent('The photo upload limit has been reached');
					message({
						title: i18n('dialog.uploading_image_error.title'),
						titleIcon: 'error',
						message: (
							<span>
								You’ve reached out {this.props.imagesLimit} image upload limit. If you want to use an unlimited version of Denti.AI, please reach out to <a href={'mailto:sales@denti.ai'}>sales@denti.ai</a> for more information on pricing and integrations.
							</span>
						),
						buttons: ({ popup: { close } }, props) => {
							return [
								(
									<Button
										key={'close_button'}
										onClick={close}
										theme={Button.AVAILABLE_THEMES.SECONDARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										title={'Ok'}
										{...props}
									>Ok</Button>
								),
								(
									<Button
										key={'close_button'}
										href={'mailto:info@denti.ai'}
										onClick={close}
										theme={Button.AVAILABLE_THEMES.PRIMARY}
										size={Button.AVAILABLE_SIZES.LARGE}
										title={'Contact Denti.AI'}
										{...props}
									>Contact Denti.AI</Button>
								),
							];
						},
					});
				}
				else if ( reason === 'UPLOAD_ERROR' )  {
					message({
						title: i18n('dialog.uploading_image_error.title'),
						titleIcon: 'error',
						message: (
							<span
								dangerouslySetInnerHTML={{
									__html: i18n('dialog.uploading_image_error.text'),
								}}
							/>
						),
					});
				}
				else if ( reason === 'MAX_SIZE' ) {
					message({
						title: i18n('dialog.uploading_image_error.title'),
						titleIcon: 'error',
						message: (
							<span
								dangerouslySetInnerHTML={{
									__html: 'The maximum file size should not exceed 50Mb',
								}}
							/>
						),
					});
				}
				else if ( reason === 'IMAGE_IS_ALREADY_UPLOADED' ) {
					message({
						title: i18n('dialog.uploading_image_error.title'),
						titleIcon: 'error',
						message: (
							<span
								dangerouslySetInnerHTML={{
									__html: 'This image is already uploaded',
								}}
							/>
						),
					});
				}
				else if ( reason === 'MAX_SIZE' ) {
					message({
						title: i18n('dialog.uploading_image_error.title'),
						titleIcon: 'error',
						message: (
							<span
								dangerouslySetInnerHTML={{
									__html: 'The maximum file size should not exceed 50Mb',
								}}
							/>
						),
					});
				}
			});
	};

	_handleSaveImageData = (data) => {
		return this.props.UploadService.saveImageData({
			data,
			imageId: this.state.image.id,
			imageHashName: this.state.image.hashname,
		})
			.then(() => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					content: CONTENT.IDLE,
					imageId: null,
				});
			});
	};

	_handleRemoveImage = (data) => {
		return this.props.UploadService.removeImage(data)
			.then(() => {
				if ( this.state === null ) {
					return;
				}

				this.setState({
					content: CONTENT.IDLE,
					imageId: null,
				});
			});
	};

	_handleFlipImageHorizontal = (data) => {
		return this.props.UploadService.flipImageHorizontal(data);
	};

	_handleFlipImageVertical = (data) => {
		return this.props.UploadService.flipImageVertical(data);
	};

	_handleRotateImageLeft = (data) => {
		return this.props.UploadService.rotateImageLeft(data);
	};

	_handleRotateImageRight = (data) => {
		return this.props.UploadService.rotateImageRight(data);
	};

	_handleUploadingFormClose = () => {
		this.setState({
			content: CONTENT.IDLE,
			imageId: null,
		});
	};

	_renderProgress () {
		return (
			<Popup>
				<div className={baseCssClassName} >
					<div className={progressCssClassName}
						 dangerouslySetInnerHTML={{
							 __html: i18nShared('messages.image_wait'),
						 }}
					/>
					<LinearDeterminate completed={false} />
				</div>
			</Popup>
		);
	}

	render () {
		switch (this.state.content) {
			case CONTENT.PROGRESS:
				return this._renderProgress();

			case CONTENT.CONFIRM:
				return (
					<ImageUploadingForm
						imageId={this.state.image.id}
						image={this.state.image}
						onSaveImageData={this._handleSaveImageData}
						onRemoveImage={this._handleRemoveImage}
						onFlipImageHorizontal={this._handleFlipImageHorizontal}
						onFlipImageVertical={this._handleFlipImageVertical}
						onRotateImageLeft={this._handleRotateImageLeft}
						onRotateImageRight={this._handleRotateImageRight}
						onSaved={this._handleUploadingFormClose}
						onClose={this._handleUploadingFormClose}
					/>
				);

			default:
				return null;
		}
	}
}

export default connect(
	(state) => ({
		imagesLimit: userSelectors.selectUserData(state).max_images_per_user || 0,
	}),
	() => {},
)(UploadImage);
