import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStorageService, setStorageService, Storage } from '../../../../services/storage';
import { USER_PERMISSION__TISSUE_PREDICTION } from '../../../../constants/userPermissionsConstants';
import aclService from '../../../../services/acl';

import editorActions from '../../../../actions/editorActions';
import collectionsActions from '../../../../actions/collectionsActions';
import editorSelectors from '../../../../selectors/editorSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';


const ResolverStorage = (props) => {
	const [ imageBrightness, setImageBrightness ] = useState(props.imageBrightness);
	const [ imageContrast, setImageContrast ] = useState(props.imageContrast);
	const [ shouldShowTissueFindings, setShouldShowTissueFindings ] = useState(props.shouldShowTissueFindings);

	
	const saveCollectionMeta = useCallback((data) => {
		props.onSaveCollectionMeta({
			collection: props.collection,

			data: {
				...props.collection.collection_meta,
				...data,
			},
		});
	}, [ props.collection, props.onSaveCollectionMeta ]);

	useEffect(() => {
		const storage = getStorageService();
		if ( imageBrightness !== props.imageBrightness ) {
			// storage.set(`${props.collection.hashname}.imageBrightness`, props.imageBrightness);
			setImageBrightness(props.imageBrightness);
			saveCollectionMeta({
				brightness: props.imageBrightness * 100,
			});
		}

		if ( imageContrast !== props.imageContrast ) {
			// storage.set(`${props.collection.hashname}.imageContrast`, props.imageContrast);
			setImageContrast(props.imageContrast);
			saveCollectionMeta({
				contrast: props.imageContrast * 100,
			});
		}

		if ( shouldShowTissueFindings !== props.shouldShowTissueFindings ) {
			storage.set('shouldShowTissueFindings', props.shouldShowTissueFindings);
			setShouldShowTissueFindings(props.shouldShowTissueFindings);
		}

	}, [ props.imageBrightness, props.imageContrast, props.shouldShowTissueFindings ]);

	useEffect(() => {
		if ( getStorageService() === null ) {
			setStorageService(new Storage('dentiai__app'));
		}
	}, []);

	useEffect(() => {
		const storage = getStorageService();
		const imageBrightness = storage.get(`${props.collection.hashname}.imageBrightness`);
		const imageContrast = storage.get(`${props.collection.hashname}.imageContrast`);
		const shouldShowTissueFindings = storage.get('shouldShowTissueFindings');
		const data = {};

		// if ( typeof imageBrightness === 'number' ) {
		// 	data.imageBrightness = imageBrightness;
		// }
		// if ( typeof imageContrast === 'number' ) {
		// 	data.imageContrast = imageContrast;
		// }

		if ( props.canUseShowTissueFindings && typeof shouldShowTissueFindings === 'boolean' ) {
			data.shouldShowTissueFindings = shouldShowTissueFindings;
		}

		if ( Object.keys(data).length > 0 ) {
			props.onSetData(data);
		}
	}, [ props.currentImageId ]);

	return null;
};

ResolverStorage.propTypes = {
	imageBrightness: PropTypes.number.isRequired,
	imageContrast: PropTypes.number.isRequired,
	currentImageId: PropTypes.string.isRequired,
	collection: PropTypes.object.isRequired,
	canUseShowTissueFindings: PropTypes.bool.isRequired,
	shouldShowTissueFindings: PropTypes.bool.isRequired,
	onSetData: PropTypes.func.isRequired,
};


export default connect(
	(state) => {
		const editorData = editorSelectors.selectEditor(state);

		return {
			imageBrightness: editorData.imageBrightness,
			imageContrast: editorData.imageContrast,
			currentImageId: editorData.currentImageId,
			canUseShowTissueFindings: aclService.checkPermission(USER_PERMISSION__TISSUE_PREDICTION),
			shouldShowTissueFindings: editorData.shouldShowTissueFindings,
			collection: collectionsSelectors.selectCollectionById(state, {
				id: editorSelectors.selectEditor(state).currentCollectionHashName,
			}),
		};
	},
	(dispatch) => ({
		onSetData: (data) => dispatch(editorActions.updateData({
			data,
		})),
		onSaveCollectionMeta: (data) => collectionsActions.saveCollectionMetaQueue({
			dispatch,
			...data,
		}),
	}),
)(ResolverStorage);
