import when from 'when';

import lodashUniq from 'lodash/uniq';

import { getRuntimeConfig } from '../appUtils/runtimeConfig';
import mainConfig from '../configs/mainConfig';

import { events } from '../services/events';
import ImagesCache from '../services/images-cache';

import {
	IMAGE_TYPE__PAN,
	IMAGE_TYPE__XRAY,
	IMAGE_TYPE__PERIAPICAL,
	IMAGE_TYPE__BITEWING,
	IMAGE_TYPE__CEPHALOMETRIC,
	IMAGE_TYPE__PAN_CHILD,
	IMAGE_TYPE__UNKNOWN,
} from '../constants/imageConstants';

import helpers from '../appUtils/helpers';

import apiActions from './apiActions';

import storeUtils from '../appUtils/storeUtils';

import imagesApi from '../api/imageApi';
import editorActions from './editorActions';
import commonActions from './commonActions';
import labelsActions from '../modules/labels/actions/labelsActions';
import collectionsActions from './collectionsActions';

import imagesActionTypes from './imagesActionTypes';
import imagesCollectionsActionTypes from './imagesCollectionsActionTypes';

import imagesSelectors from '../selectors/imagesSelectors';
import imagesCollectionsSelectors from '../selectors/imagesCollectionsSelectors';
import analyseSelectors from '../selectors/analyseSelectors';

import currentCollectionSelectors from '../selectors/currentCollectionSelectors';
import editorSelectors from '../selectors/editorSelectors';
import analyseActions from './analyseActions';
import userSelectors from '../selectors/userSelectors';
import collectionsSelectors from '../selectors/collectionsSelectors';
import { message } from '../services/popup';
import {LABELS_STAGE_COMPLETED} from "../constants/labelsStageConstants";
import imagesHistoryActions from "./imagesHistoryActions";
import imagesHistoryActionTypes from "./imagesHistoryActionTypes";


/**
 * @param {Object} options
 * @param {string} options.imageHashName
 * @param {string} options.collectionHashName
 * @return {function(*, *): *}
 */
function loadImage (options) {
	return (dispatch, getState) => {
		const {
			collectionHashName,
			imageHashName,
			probeCache,
		} = options;
		const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(getState());

		let promise = when.all([
			imagesApi.getAnnotations(collectionHashName, imageHashName, isFDAAnnotationEnabled),
			imagesApi.getHistory(collectionHashName, imageHashName),
		]);

		if ( isFDAAnnotationEnabled === false && typeof probeCache === 'function' ) {
			if ( probeCache(getState) === true ) {
				const storeState = getState();
				const image = helpers.toApiStructure({
					storeState,
					imageId: editorSelectors.selectCurrentImageId(storeState),
				});
				const editorData = editorSelectors.selectEditor(storeState);
				const nextImage = imagesSelectors.selectImageByHashNameInCollection(getState(), {
					collectionHashName,
					imageHashName,
				});

				nextImage.labels = image.labels;
				nextImage.stage = editorData.labelsStage;
				nextImage.plan_format = editorData.imagePlanFormat;
				promise = when.all([ Promise.resolve(nextImage) ]);
			}
		}

		return promise
			.then(([ annotationsResult, historyResult ]) => {
				const image = imagesSelectors.selectImageByHashNameInCollection(getState(), {
					collectionHashName,
					imageHashName,
				});
				const imageId = image.id;

				let data = {};

				dispatch(apiActions.setData(helpers.clearLabels()));
				const storeState = getState();

				if ( annotationsResult && Array.isArray(annotationsResult.labels) && annotationsResult.labels.length > 0  ) {
					data = {
						...data,
						...helpers.toStateStructure({
							storeState,
							image,
							annotation: annotationsResult,
						}),
					};
				} else if (annotationsResult.stage != null) {
					data.labelsStage = annotationsResult.stage;
				}

				data.editor = {
					...editorSelectors.selectEditor(storeState),
					...data.editor,
					imagePlanFormat: annotationsResult.plan_format || null,
				};

				if ( false && Array.isArray(historyResult) && historyResult.length > 0 ) {
					data.imageHistory = helpers.fetchImageHistory({
						imageId,
						history: historyResult.length > 0
							? [
								{
									labels: [],
								},
								...historyResult.slice(1).reverse(),
							]
							: historyResult.slice(1).reverse(),
					});
				}

				if ( Object.keys(data).length > 0 ) {
					dispatch(apiActions.setData(data));
				}

				return image;
			});
	};
}

function loadSharedImage (options) {
	return (dispatch, getState) => {
		return imagesApi.getSharedImageByHashName(options.hashName)
			.then((collection) => {
				if ( !collection ) {
					return;
				}

				const storeState = getState();

				const isAuthenticated = userSelectors.selectIsAuthenticated(storeState);
				let isOwnImage = false;
				if ( isAuthenticated ) {
					isOwnImage = (userSelectors.selectUserData(storeState).username === collection.username);
				}
				const annotation = {
					labels: collection.images.labels,
				};

				const image = helpers.fetchImage({
					image: collection.images,
					isOwnImages: isOwnImage,
				});

				delete image.labels;

				const imagesList = [
					image,
				];

				const collectionId = `shared_${collection.username}`;

				const currentCollection = {
					hashname: collectionId,
					name: `Shared from ${collection.username}`,
					labels: collection.labels,
					label_relations: (collection.label_relations || []),
					hotkeys: {},
					owner: collection.username,
					username: collection.username,
				};

				const collections = { ...collectionsSelectors.selectCollectionItems(storeState) };
				collections[currentCollection.hashname] = currentCollection;
				
				dispatch(collectionsActions.setCollections({
					collections: {
						collections,
						orderedCollections: [],
					},
				}));
				dispatch(collectionsActions.setCurrentCollection({ collection: currentCollection }));
				
				dispatch(apiActions.setData({
					images: {
						...imagesSelectors.selectImages(storeState),
						[image.id]: image,
					},
					imagesCollections: helpers.fetchImagesCollections({
						storeState,
						images: imagesList,
						imageCollectionId: collectionId,
					}),
				}));
				
				const data = helpers.toStateStructure({
					storeState: getState(),
					image,
					annotation,
				});
				dispatch(apiActions.setData(data));
				dispatch(editorActions.setCurrentImage({ id: image.id }));
				return { image, collection };
			});
	}
}

function addImage (options) {
	return (dispatch) => {
		dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__ADD_IMAGE, {
			id: options.id,
			data: options.data,
		}));
	}
}

function removeImage (options) {
	return (dispatch, getState) => {
		const storeState = getState();
		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});
		return imagesApi.removeImage(collection.hashname, options.image.hashname)
			.then(() => {
				dispatch(storeUtils.makeAction(imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE, {
					collectionId: collection.hashname,
					imageId: options.image.id,
				}));
				dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__REMOVE_IMAGE, options.image.id));

				if ( options.image.id === editorSelectors.selectCurrentImageId(getState()) ) {
					dispatch(editorActions.unsetCurrentImage());
				}
			});
	}
}

function uploadImage (options) {
	return (dispatch, getState) => {
		const {
			image,
		} = options;

		if ( !image ) {
			// @todo add error_prefix
			throw new Error(`uploadImage: Option "image" is required.`);
		}
		const currentState = getState();

		const currentUser = userSelectors.selectUserData(currentState).username;
		const currentCollectionUser = collectionsSelectors.selectCollectionById(currentState, {
			id: editorSelectors.selectEditor(currentState).currentCollectionHashName,
		}).owner;
		const currentCollectionId = editorSelectors.selectEditor(currentState).currentCollectionHashName;
		const uploadData = {
			image,
			col_hash: currentCollectionId,
		};

		if (currentCollectionUser !== currentUser) {
			uploadData.user = currentUser;
		}

		return imagesApi.uploadImage(uploadData)
			.then((newImageData) => {
				let imageType = newImageData.image_type;
				const predictedType = (newImageData.predicted_type || '').toLocaleLowerCase();

				if (
					predictedType === IMAGE_TYPE__XRAY ||
					predictedType === IMAGE_TYPE__PAN ||
					predictedType === IMAGE_TYPE__PERIAPICAL ||
					predictedType === IMAGE_TYPE__BITEWING ||
					predictedType === IMAGE_TYPE__CEPHALOMETRIC ||
					predictedType === IMAGE_TYPE__PAN_CHILD ||
					predictedType === IMAGE_TYPE__UNKNOWN
				) {
					imageType = predictedType;
				}

				const newImage = helpers.fetchImage({
					image: newImageData,
					isOwnImages: true,
				});

				newImage.image_type = imageType;

				dispatch(addImage({
					id: newImage.id,
					data: newImage,
				}));

				return dispatch(collectionsActions.getList())
					.then(() => newImage);
			});
	};
}

function saveImageData (options = {}) {
	return (dispatch, getState) => {
		const storeState = getState();
		const imageId = options.imageId;
		const collectionId = options.collectionId;

		const image = imagesSelectors.selectImageById(storeState, {
			id: imageId || editorSelectors.selectCurrentImageId(storeState),
		});

		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: collectionId || editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});

		const isOwn = image.isOwn;

		const newImageData = {
			...image,
			...options.data,
		};

		delete newImageData.isOwn;

		return imagesApi.updateImageData({
			imageHashName: image.hashname,
			collectionHashName: collection.hashname,
		}, newImageData)
			.then(() => {
				dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__UPDATE_IMAGE, {
					id: image.id,
					data:  {
						...newImageData,
						isOwn,
					},
				}));
			});
	};
}

function touchImage (options) {
	return (dispatch, getState) => {
		const storeState = getState();

		const imageId = options.id;
		const {
			currentCollection,
			currentImage,
		} = editorSelectors.selectCurrentCollectionAndImage(storeState, { imageId });

		const newImageData = {
			...currentImage,
			last_opened_at: ( Date.now() / 1000 ),
		};
		
		if ( !currentImage.hashname ) {
			return when.resolve()
		}

		return imagesApi.touchImage({
			imageHashName: currentImage.hashname,
			collectionHashName: currentCollection.hashname,
		})
			.then(() => {
				dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__UPDATE_IMAGE, {
					id: imageId,
					data: newImageData,
				}));
			})
			.catch(() => {
				// skip error
			});
	}
}


function completeFDAStage (options) {
	return (dispatch, getState) => {
		const storeState = getState();

		const imageId = options.id;
		const {
			currentCollection,
			currentImage,
		} = editorSelectors.selectCurrentCollectionAndImage(storeState, { imageId });

		return imagesApi.completeFDAStage({
			imageHashName: currentImage.hashname,
			collectionHashName: currentCollection.hashname,
		}).then(async () => {
			await dispatch(apiActions.setData({
				labelsStage: LABELS_STAGE_COMPLETED,
			}));
			await dispatch(storeUtils.makeAction(imagesHistoryActionTypes.ACTION_IMAGES_HISTORY__SET_HISTORY, {
				imageId: imageId,
				data: [],
			}));
		})
	}
}


function analyzeImage (options) {
	return async (dispatch, getState) => {
		const storeState = getState();
		dispatch(editorActions.selectLabel({ labelId: null }));

		const imageId = options.id;
		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});
		const image = imagesSelectors.selectImageById(storeState, { id: imageId });
		const isAnalysedByUser = options.isAnalysedByUser;

		dispatch(analyseActions.startImageAnalysis({ imageId }));

		try {
			const imageData = await imagesApi.analyzeImage({
					imageHashName: image.hashname,
					collectionHashName: collection.hashname,
					isFDAAnnotationEnabled: userSelectors.selectIsFDAAnnotationEnabled(getState()),
					params: options.params,
				},
			);

			dispatch(analyseActions.finishImageAnalysis({ imageId }));

			const storeState = getState();
			// @todo add check currentImageId === imageId
			//const currentImageId = editorSelectors.selectCurrentImageId(storeState);

			const isFDAAnnotationEnabled = userSelectors.selectIsFDAAnnotationEnabled(storeState);

			if (
				options.skipResult === true ||
				!imageData ||
				!imageData.labels ||
				// Allow empty labels in FDA mode
				(imageData.labels.length === 0 && !isFDAAnnotationEnabled) ||
				editorSelectors.selectEditor(storeState).editorMode !== mainConfig.EDITOR_MODE__SELECT_MODE
			) {
				return null;
			}

			const data = await dispatch(editorActions.putInImageHistory(async () => {
				dispatch(apiActions.setData(helpers.clearLabels()));

				const data = helpers.toStateStructure({
					storeState: getState(),
					image,
					annotation: imageData,
					isAnalysedByUser,
				});

				dispatch(apiActions.setData(data));
				dispatch(editorActions.updateData({
					data: {
						teethToShift: [],
						teethConflicts: [],
					},
				}));
				if ( isAnalysedByUser === false ) {
					dispatch(analyseActions.setImageAnalyzed({ imageId }));
				}
				// workaround: add timer to save findings
				await dispatch(labelsActions.saveLabels({ skipSyncWithReanalyse: true }));

				return data;
			}));

			// clean all history after analyze
			if (isFDAAnnotationEnabled) {
				dispatch(storeUtils.makeAction(imagesHistoryActionTypes.ACTION_IMAGES_HISTORY__SET_HISTORY, {
					imageId: imageId,
					data: [],
				}));
			}

			return data;
		}
		catch (error) {
			dispatch(analyseActions.finishImageAnalysis({ imageId }));

			throw new Error(error);
		}
	}
}

function removeImages (options) {
	return (dispatch, getState) => {
		const storeState = getState();
		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});

		const image = imagesSelectors.selectImageById(storeState, { id: options.imageIds[0] }); // take first now
		return imagesApi.removeImage(collection.hashname, image.hashname)
			.then(() => {
				dispatch(storeUtils.makeAction(imagesCollectionsActionTypes.ACTION_IMAGES_COLLECTIONS__REMOVE_IMAGE, {
					collectionId: collection.hashname,
					imageId: options.image.id,
				}));
				dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__REMOVE_IMAGE, options.image.id));

				if ( options.image.id === editorSelectors.selectCurrentImageId(getState()) ) {
					dispatch(editorActions.unsetCurrentImage());
				}
			});
	};
}

function shareImage (options) {
	return (dispatch, getState) => {
		const storeState = getState();
		const collectionId = options.collectionId ||
			editorSelectors.selectEditor(storeState).currentCollectionHashName;
		return imagesApi.shareImage(collectionId, options.hashName);
	};
}

/**
 * @param {Object} options
 * @param {string} [options.imageId]
 * @param {string} options.method
 * @param {boolean} [options.skipAnnotationLoading]
 * @param {Object} [options.params]
 * @return {(function(*, *): Promise<void>)|*}
 */
function flip (options) {
	return async (dispatch, getState) => {
		const method = options.method;
		const storeState = getState();

		const imageId = options.imageId || editorSelectors.selectCurrentImageId(storeState);
		const collection = collectionsSelectors.selectCollectionById(storeState, {
			id: editorSelectors.selectEditor(storeState).currentCollectionHashName,
		});
		const image = imagesSelectors.selectImageById(storeState, { id: imageId });

		try {
			const isImageAnalyzed = analyseSelectors.selectIsImageAnalyzed(storeState, { imageId: imageId });
			dispatch(editorActions.isImageFlipping({ id: imageId }));

			events.emit('image.flip.in-progress', image);
			const data = await imagesApi.flip(collection.hashname, image.hashname, method, options.params);
			const currentImageData = imagesSelectors.selectImageById(getState(), {
				id: imageId,
			});
			const newImageData = {
				...currentImageData,
				...data,
				reverse_auto_rotation: null,
			};

			dispatch(storeUtils.makeAction(imagesActionTypes.ACTION_IMAGES__UPDATE_IMAGE, {
				id: imageId,
				data: newImageData,
			}));

			if ( options.skipAnnotationLoading !== false ) {
				await dispatch(loadImage({
					imageHashName: image.hashname,
					collectionHashName: collection.hashname,
				}));
			}

			ImagesCache.generateCache(image.id);
			dispatch(editorActions.isImageFlipping({ id: null }));
			events.emit('image.flip.finished', image);

			if ( getRuntimeConfig()['auto-image-analysis'] && isImageAnalyzed ) {
				dispatch(labelsActions.removeAllLabels({ imageId }));
				dispatch(analyseActions.unsetImageAnalyzed({ imageId }));
			}
		}
		catch (error) {
			dispatch(editorActions.isImageFlipping({ id: null }));
			events.emit('image.flip.error', image);
			throw new Error(error);
		}
	};
}

function flipHorizontal (options) {
	return (dispatch) => dispatch(flip({
		...options,
		method: 'horizontal',
	}));
}

function flipVertical (options) {
	return (dispatch) => dispatch(flip({
		...options,
		method: 'vertical',
	}));
}

function rotateLeft (options) {
	return (dispatch) => dispatch(flip({
		...options,
		method: 'left',
	}));
}

function rotateRight (options) {
	return (dispatch) => dispatch(flip({
		...options,
		method: 'right',
	}));
}

function reverseAutoRotation (options = {}) {
	return (dispatch, getState) => {
		const storeState = getState();
		const imageId = options.imageId || editorSelectors.selectCurrentImageId(storeState);
		const image = imagesSelectors.selectImageById(storeState, { id: imageId });

		return dispatch(flip({
			...options,
			method: image.reverse_auto_rotation,
		}));
	};
}


/**
 * @param {Object} options
 * @param {string} options.imageId
 * @return {(function(*, *): void)|*}
 */
const markImageAsNotAnalyzed = (options) => {
	return (dispatch, getState) => {
		const nextImages = [
			...editorSelectors.selectEditor(getState()).notAnalyzedImages,
		];

		if ( nextImages.includes(options.imageId) ) {
			return;
		}

		nextImages.push(options.imageId);

		dispatch(editorActions.updateData({
			data: {
				notAnalyzedImages: nextImages,
			},
		}));
	};
}

/**
 * @param {Object} options
 * @param {string} options.imageId
 * @return {(function(*, *): void)|*}
 */
const unmarkImageAsNotAnalyzed = (options) => {
	return (dispatch, getState) => {
		dispatch(editorActions.updateData({
			data: {
				notAnalyzedImages: editorSelectors.selectEditor(getState()).notAnalyzedImages.filter((_imageId) => _imageId !== options.imageId),
			},
		}));
	};
}

function reloadAnnotations () {
	return (dispatch, getState) => {
		const { currentCollection, currentImage } = editorSelectors.selectCurrentCollectionAndImage(getState());
		return dispatch(loadImage({
			collectionHashName: currentCollection.hashname,
			imageHashName: currentImage.hashname,
		}));
	};
}

export default {
	loadImage,
	loadSharedImage,
	addImage,
	removeImage,
	uploadImage,
	saveImageData,
	touchImage,
	analyzeImage,
	completeFDAStage,
	removeImages,
	shareImage,
	flipHorizontal,
	flipVertical,
	rotateLeft,
	rotateRight,
	reverseAutoRotation,
	markImageAsNotAnalyzed,
	unmarkImageAsNotAnalyzed,
	reloadAnnotations,
};
